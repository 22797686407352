import React, { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import LoginLogo from "../../assets/img/LoginLogo.svg";
import Bridal from "../../assets/img/BridalImg.jpg";
import {
  loginUser,
  forgetPassword,
  toggleForgetPassword,
} from "../../features/user/userSlice";
export default function Forgot() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isForgetPassword, isEmailSend, error } = useSelector(
    (store) => store.user
  );
  useEffect(() => {
    if (user) {
      navigate("/start-order");
    }
  }, [user]);

  const validation = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("The email you entered is invalid!")
        .required("This Field is required!"),

      password: Yup.string().required("This Field is required"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser({ email: values.email, password: values.password }));
    },
  });
  const ForgetPassvalidation = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("The email you entered is invalid!")
        .required("This Field is required!"),
    }),
    onSubmit: (values) => {
      dispatch(forgetPassword({ type: 2,email: values.email }));
      ForgetPassvalidation.values.email = "";
    },
  });
  return (
    <div>
      <div className="row gx-0">
        <div
          className="col-lg-6 col-md-12 col-sm-12 col-xs-12 login-bg"
          // style={{ backgroundImage: `url(${Bridal})` }}
        >
          <img src={Bridal} alt="bride" className="bride-img" />
        </div>
        <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 mt-lg-5 pt-5  mb-5 container style-screen">
          <div className="ms-lg-5">
            <img src={LoginLogo} alt="logo" className="img-size" />

            {!isForgetPassword ? (
              <>
                <p className="desc-semiBold mb-0 pt-lg-5 mt-4 ">
                  Welcome back to the Store Portal!
                </p>
                <p className="desc">Please login to your account.</p>
              </>
            ) : (
              <>
                <p className="desc-semiBold mb-0 pt-lg-5 ">
                  Forgotten Password
                </p>
                <p className="desc pb-lg-4">
                  To reset your password, submit your email address below. If we
                  can find you in the database, an email will be sent to your
                  email address, with instructions how to get access again.
                </p>
              </>
            )}
            {!isForgetPassword && (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                }}
              >
                <Form.Group className="mb-3 " controlId="formBasicEmail">
                  <Form.Control
                    className="input-lineBorder mb-4 ps-1 input-style margin-top-input "
                    type="email"
                    name="email"
                    placeholder="Email address"
                    value={validation.values.email}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <p className="error d-flex flex-row-reverse ">
                      {validation.errors.email}
                    </p>
                  ) : null}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Control
                    className="input-lineBorder mb-4 ps-1 input-style margin-top-input"
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={validation.values.password}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <p className="error d-flex flex-row-reverse">
                      {validation.errors.password}
                    </p>
                  ) : null}
                </Form.Group>
                <div className="error">{error}</div>
                <div className="d-flex justify-content-between  align-items-baseline input-style">
                  <Form.Group
                    className="mb-2 d-flex ps-0 "
                    controlId="formBasicCheckbox"
                  >
                    <Form.Check type="checkbox" />
                    <label className="remember-text ms-2 mt-1">
                      Remember me
                    </label>
                  </Form.Group>
                  <button
                    type="button"
                    onClick={() => dispatch(toggleForgetPassword())}
                    className="link"
                  >
                    Forgot Password
                  </button>
                </div>
                <div className="row input-style d-flex flex-row-reverse me-lg-1">
                  <Button
                    className="btn-main"
                    style={{ width: "30%" }}
                    type="submit"
                  >
                    {isLoading ? "loading..." : "Login"}
                  </Button>
                </div>
              </Form>
            )}

            {isForgetPassword && (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  ForgetPassvalidation.handleSubmit();
                }}
              >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    className="input-lineBorder mb-lg-4 ps-1"
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={ForgetPassvalidation.values.email}
                    onChange={ForgetPassvalidation.handleChange}
                    onBlur={ForgetPassvalidation.handleBlur}
                  />
                  {ForgetPassvalidation.touched.email &&
                  ForgetPassvalidation.errors.email ? (
                    <p className="error d-flex flex-row-reverse ">
                      {ForgetPassvalidation.errors.email}
                    </p>
                  ) : null}
                </Form.Group>

                <div className="d-flex justify-content-end align-items-baseline">
                  <button
                    type="button"
                    onClick={() => dispatch(toggleForgetPassword())}
                    className="link"
                  >
                    Back to login page
                  </button>
                </div>

                <Button
                  className="btn-main mt-lg-3"
                  variant="primary"
                  type="submit"
                >
                  {isLoading ? "loading..." : "Send"}
                </Button>
                {isEmailSend && navigate("/recet-password")}
              </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
