import React from "react";
import { Container, Form, Button } from "react-bootstrap";
import StaxInput from "../../../../shared/StaxInput";
import Loading from "../../../../shared/Loading";
import { appendScript } from "../../../../../helpers/helpers";
import DropDown from "../../../../shared/Dropdown";

class BankAccount extends React.Component {
  staxJs = null;
  state = {
    tokenizedPaymentMethod: null,
    completedTransaction: null,
    isPayButtonDisabled: false,
    formValues: {
      firstname: "",
      lastname: "",
      person_name: "",
      address_1: "",
      address_city: "",
      address_state: "",
      address_zip: "",
      address_country: "",
      bank_type: "",
      bank_holder_type: "",
      bank_account: "",
      bank_routing: "",
    },
    selected_country: "",
    selected_state: "",
    selected_bank_holder_type: "",
    selected_bank_type: "",
    errorMessage: "",
    loading: false,
  };
  componentDidMount() {
    appendScript("https://staxjs.staxpayments.com/stax.js");
    const StaxJs = window.StaxJs;
    const staxJs = new StaxJs("JENNA-IN-WHITE-LLC-ff428ad3038c", {});

    // staxJs.on("card_form_complete", (message) => {
    //   console.log("card_form_complete", message);
    //   // activate pay button
    //   this.setState({ isPayButtonDisabled: false });
    // });

    // staxJs.on("card_form_incomplete", (message) => {
    //   console.log("card_form_incomplete", message);
    //   // deactivate pay button
    //   this.setState({ isPayButtonDisabled: true });
    // });

    this.staxJs = staxJs;
  }

  renderMessage = () => {
    const {
      completedTransaction,
      tokenizedPaymentMethod,
      errorMessage,
    } = this.state;

    if (errorMessage) {
      return <div className="failure">{errorMessage}</div>;
    }

    return (
      <div className="success">
        {completedTransaction
          ? `Successfully paid $1! The ID for this transaction is ${completedTransaction.id}`
          : ""}
        {tokenizedPaymentMethod
          ? `Successfully tokenized the card! The ID for the payment method is ${tokenizedPaymentMethod.id}`
          : ""}
      </div>
    );
  };

  handleFieldChange = (event) => {
    const { formValues } = this.state;
    const { name, value } = event.target;
    this.setState({ formValues: { ...formValues, [name]: value } });
    console.log("handleFieldChange", formValues);
  };

  handleDropdownStateFieldChange = (event) => {
    const { formValues } = this.state;
    this.setState({
      formValues: { ...formValues, address_state: event.value },
      selected_state: event,
    });
  };
  handleDropdownCountryFieldChange = (event) => {
    const { formValues } = this.state;
    this.setState({
      formValues: { ...formValues, address_country: event.value },
      selected_country: event,
    });
  };
  handleDropdownBankTypeFieldChange = (event) => {
    const { formValues } = this.state;
    this.setState({
      formValues: { ...formValues, bank_type: event.value },
      selected_bank_type: event,
    });
  };

  handleDropdownHolderTypeFieldChange = (event) => {
    const { formValues } = this.state;
    this.setState({
      formValues: { ...formValues, bank_holder_type: event.value },
      selected_bank_holder_type: event,
    });
  };

  handleTokenize = () => {
    this.setState({
      tokenizedPaymentMethod: null,
      completedTransaction: null,
      isPayButtonDisabled: true,
      loading: true,
      errorMessage: "",
    });

    const { formValues } = this.state;
    const extraDetails = {
      ...formValues,
      // person_name: formValues.firstname + " " + formValues.lastname,

      total: 1,
      customer_id: this.props.stax_customer_id,
      url: "https://app.staxpayments.com/#/bill/",
      method: "bank",
      // validate is optional and can be true or false.
      // determines whether or not stax.js does client-side validation.
      // the validation follows the sames rules as the api.
      // check the api documentation for more info:
      // https://staxpayments.com/api-documentation/
      validate: true,
    };
    console.log("extra details ", extraDetails);
    this.staxJs
      .tokenize(extraDetails)
      .then((tokenizedPaymentMethod) => {
        console.log(
          "successfully tokenized! here's the payment method: ",
          tokenizedPaymentMethod
        );
        this.setState({
          tokenizedPaymentMethod,
          isPayButtonDisabled: false,
          loading: false,
          errorMessage: "",
        });
        this.props.setModalOpen(false);
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          isPayButtonDisabled: false,
          loading: false,
          errorMessage: e.errors ? e.errors[0] : e[0],
        });
      });
  };

  // if email is supplied, a reciept will be sent to customer
  handlePay = () => {
    this.setState({
      tokenizedPaymentMethod: null,
      completedTransaction: null,
      isPayButtonDisabled: true,
      loading: true,
      errorMessage: "",
    });

    const { formValues } = this.state;
    const extraDetails = {
      ...formValues,
      total: 1,
      url: "https://app.staxpayments.com/#/bill/",
      method: "bank",
      validate: true,
      // meta is optional and each field within the POJO is optional also
      meta: {
        reference: "invoice-reference-num", // optional - will show up in emailed receipts
        memo: "notes about this transaction", // optional - will show up in emailed receipts
        otherField1: "other-value-1", // optional - we don't care
        otherField2: "other-value-2", // optional - we don't care
        subtotal: 1, // optional - will show up in emailed receipts
        tax: 0, // optional - will show up in emailed receipts
        lineItems: [
          // optional - will show up in emailed receipts
          {
            id: this.props.dress.style_number,
            item: this.props.dress.name,
            details: "add dress this is a regular, demo item",
            quantity: 1,
            price: this.props.dress.formatted_wholesale_price,
          },
        ],
      },
    };

    this.staxJs
      .pay(extraDetails)
      .then((completedTransaction) => {
        console.log(
          "successfully paid. here's the transaction: ",
          completedTransaction
        );
        this.setState({
          completedTransaction,
          isPayButtonDisabled: false,
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e.errors[0]);
        this.setState({
          isPayButtonDisabled: false,
          loading: false,
          errorMessage: e.errors[0],
        });
      });
  };
  render() {
    const {
      firstname,
      lastname,
      person_name,
      address_zip,
      address_city,
      address_1,
      bank_account,
      bank_routing,
    } = this.state.formValues;
    const {
      selected_country,
      selected_state,
      selected_bank_type,
      selected_bank_holder_type,
    } = this.state;

    return (
      <Container className="py-4">
        <Form onSubmit={(e) => e.preventDefault()} className="row g-3">
          <div className="col-md-6">
            <StaxInput
              name="firstname"
              labelText="FIRST NAME *"
              placeholder="Enter Your First Name "
              onChange={this.handleFieldChange}
              value={firstname || ""}
            />
          </div>
          <div className="col-md-6 row-header">
            <StaxInput
              name="lastname"
              labelText="LAST NAME *"
              placeholder="Enter Your Last Name "
              onChange={this.handleFieldChange}
              value={lastname || ""}
            />
          </div>
          <div className="col-md-12">
            <StaxInput
              name="person_name"
              labelText="ENTER BANK ACCOUNT HOLDER NAME *"
              placeholder="Enter account holder name"
              onChange={this.handleFieldChange}
              value={person_name || ""}
            />
          </div>

          <div className="col-md-6">
            <StaxInput
              name="bank_account"
              labelText="BANK ACCOUNT NUMBER *"
              placeholder="Enter Bank Account Number"
              onChange={this.handleFieldChange}
              value={bank_account || ""}
            />
          </div>

          <div className="col-md-6">
            <StaxInput
              name="bank_routing"
              labelText="BANK ROUTING NUMBER *"
              placeholder="Enter Routing Number"
              onChange={this.handleFieldChange}
              value={bank_routing || ""}
            />
          </div>

          <div className="col-md-6">
            <DropDown
              name="bank_type"
              labelText="BANK TYPE *"
              placeholder="Select a Bank Type"
              options={[
                { value: "checking", label: "Checking" },
                { value: "savings", label: "Savings" },
              ]}
              onChange={this.handleDropdownBankTypeFieldChange}
              value={selected_bank_type || ""}
            />
          </div>
          <div className="col-md-6">
            <DropDown
              name="bank_holder_type"
              labelText="BANK HOLDER TYPE *"
              placeholder="Select a type"
              options={[
                { value: "personal", label: "Personal" },
                { value: "business", label: "Business" },
              ]}
              onChange={this.handleDropdownHolderTypeFieldChange}
              value={selected_bank_holder_type || ""}
            />
          </div>
          <div className="col-md-6">
            <StaxInput
              name="address_1"
              labelText="STREET *"
              placeholder="Enter Street Name"
              onChange={this.handleFieldChange}
              value={address_1 || ""}
            />
          </div>

          <div className="col-md-6">
            <StaxInput
              name="address_city"
              labelText="CITY *"
              placeholder="Enter City Name"
              onChange={this.handleFieldChange}
              value={address_city || ""}
            />
          </div>

          <div className="col-md-6">
            <DropDown
              name="address_state"
              labelText="STATE *"
              placeholder="Enter State Name"
              options={this.props.stateList}
              onChange={this.handleDropdownStateFieldChange}
              value={selected_state || ""}
            />
          </div>
          <div className="col-md-6">
            <DropDown
              name="address_country"
              labelText="COUNTRY *"
              placeholder="Enter State Name"
              options={this.props.countryList}
              onChange={this.handleDropdownCountryFieldChange}
              value={selected_country || ""}
            />
          </div>

          <div className="col-md-6">
            <StaxInput
              name="address_zip"
              labelText="ZIP CODE *"
              placeholder="Enter ZIP Code"
              onChange={this.handleFieldChange}
              value={address_zip || ""}
            />
          </div>

          <div className="d-flex flex-row-reverse mt-5">
            <Button
              onClick={
                this.handleTokenize
                // , this.handlePay
              }
              disabled={this.state.isPayButtonDisabled}
              className="btn-secondary me-md-2"
              type="submit"
            >
              save bank account information
            </Button>
          </div>
          <div className="outcome">
            {this.renderMessage()}
            {this.loading && <Loading />}
          </div>
        </Form>
      </Container>
    );
  }
}

export default BankAccount;
