import React from "react";
import { Outlet } from "react-router";
import Sidebar from "../shared/Sidebar";
import logo from "../../assets/img/LoginLogo.svg";
import { useDispatch, useSelector } from "react-redux";

import {
  setSalesOrder,
  setInvoice,
  setAddress,
  setTransaction,
  setFixed,
  setInvoiceSummary,
} from "../../features/store/storeSlice";
export default function SidebarLayout() {
  const {
    fixed,
    salesOrder,
    invoice,
    address,
    transaction,
    invoiceSummary,
  } = useSelector((store) => store.store);
  const dispatch = useDispatch();

  return (
    <div className="row width-export ">
      <nav className="navbar navbar-expand-sm navbar-expand-md col-lg-3 col-md-4 col-sm-3 sidebar-bg pe-0 navbar-light ">
        <div className={`/*${fixed === true && "fixPosition"}*/`}>
          <ul className="navbar-nav ms-xs-auto ">
            <div className="logoFont-small px-3 py-4 pe-5 ">
              <div className="show " id="toggleMobileMenu">
                <object
                  type="image/svg+xml"
                  data={logo}
                  className="pe-4"
                  aria-label="logo"
                />
              </div>
            </div>

            <div className="widthOrd displayVisible scrol">
              <div className="d-flex justify-content-end pt-4 ">
                <button
                  className="navbar-toggler d-flex flex-column justify-content-around no-border me-2 collapsed shadow-none topMargin"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#toggleMobileMenu"
                  aria-controls="toggleMobileMenu"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => {
                    dispatch(setAddress(!address));
                    dispatch(setSalesOrder(!salesOrder));
                    dispatch(setInvoice(!invoice));
                    dispatch(setTransaction(!transaction));
                    dispatch(setInvoiceSummary(!invoiceSummary));
                    dispatch(setFixed(!fixed));
                    window.scrollTo(0, 0);
                  }}
                >
                  <span className="toggler-icon top-bar"></span>
                  <span className="toggler-icon middle-bar"></span>
                  <span className="toggler-icon bottom-bar"></span>
                </button>
              </div>
            </div>

            <div
              className="collapse navbar-collapse hideSide"
              id="toggleMobileMenu"
            >
              <Sidebar />
            </div>
          </ul>
        </div>
      </nav>

      <div className="col-lg-9 col-md-9 col-sm-9 px-0 widthTab">
        <Outlet />
      </div>
    </div>
  );
}
