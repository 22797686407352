import React, { useState, useEffect } from "react";
import Header from "../shared/Header";
import { Link } from "react-router-dom";
import { Container, Form } from "react-bootstrap";
import arrow from "../../assets/img/arrow.svg";
import FormInput from "../shared/FormInput";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../shared/Loading";
import moment from "moment";
import { formatFullMonthDate } from "../../helpers/helpers";
import {
  getCustomItem,
  clearCustomItemValues,
  saveSingleOrder,
} from "../../features/customItem/customItemSlice";

export default function ViewCustomOrder() {
  const {
    isLoading,
    image,
    name,
    price,
    estimated_months,
    estimated_days,
  } = useSelector((store) => store.customItem);
  const [estimatedDate, setEstimatedDate] = useState("");

  let today = new Date();
  const dispatch = useDispatch();

  const { Id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (Id) {
      dispatch(clearCustomItemValues);
      dispatch(getCustomItem(Id));
    }
  }, []);

  useEffect(() => {
    if ( estimated_months) {
      setEstimatedDate(
        moment(today).add({ days: estimated_days, months: estimated_months })._d
      );
    }
  }, [estimated_days, estimated_months]);
  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  let currentDate = `${year}-${month}-${day}`;
  //const [datePicked, setDatePicked] = useState(currentDate);
  const [datePicked, setDatePicked] = useState();
  // this should not be added since this is a bug 
  // the estimated date should be by current date and not selected date
  /*useEffect(() => {
    if (datePicked) {
      const dateObject = new Date(datePicked);
      setEstimatedDate(
        moment(today).add({ days: dateObject.getDate(), months: dateObject.getMonth()})._d
      )
    }
  },[datePicked]);*/
  return (
    <>
      <Header
        title="View Item"
        subtitle="Enter wear date, and choose color and size to proceed to checkout"
      />
      {isLoading && <Loading />}
      {!isLoading && (
        <Container className="py-4">
          <Link to="/start-order" className="arrow">
            <img src={arrow} alt="arrow" />
            <span className="back-to-list px-2">back to list</span>
          </Link>
          <div className="container py-4 px-0">
            <div className="row">
              <div className="col-md-3">
                <img className="order-imageCustom" src={image} alt="bride" />
              </div>

              <div className="col-md-9">
                <Container>
                  <Form>
                    <p className="vieworder-name mt-2">{name}</p>
                    <p className="vieworder-cost">{price}</p>
                    <div className="row">
                      <div className="col-lg-7">
                        <FormInput
                          id="date"
                          type="date"
                          name="date"
                          labelText="Wear date"
                          className="form-control date-inputOrder me-0"
                          value={datePicked}
                          onChange={(e) => {
                            setDatePicked(e.target.value);
                            document
                              .getElementById("date")
                              .addEventListener("change", (e) => {
                                e.target.blur();
                              });
                          }}
                          // onBlur={validation.handleBlur}
                          min={new Date().toLocaleDateString("en-ca")}
                        />
                        {/* {validation.touched.date && validation.errors.date ? (
                          <p className="error d-flex flex-row-reverse pt-1">
                            {validation.errors.date}
                          </p>
                        ) : null} */}
                      </div>
                    </div>
                    { estimated_months ? (
                      <div className="estimated_ship_date_container ms-0 d-flex">
                        <div className="estimated_ship_date">
                          ESTIMATED SHIP DATE
                        </div>
                        <div className="ship_date_value">
                          {formatFullMonthDate(estimatedDate)}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {datePicked && moment(estimatedDate).isAfter(datePicked) ? (
                      <>
                        <div className="estimated_ship_date_container ms-0 subTitle-modal ">
                          The estimated ship date is past the wear date, please
                          call us to discuss this order.
                        </div>
                        <div className="row py-0">
                          <div className="col-lg-7 d-flex flex-row-reverse mt-5 ">
                            <button
                              className="btn-secondary "
                              disabled={true}
                              type="submit"
                            >
                              PROCEED TO CHECKOUT
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="row py-0">
                        <div className="col-lg-7 d-flex flex-row-reverse mt-5 ">
                          <button
                            className="btn-secondary "
                            type="submit"
                            disabled={!datePicked}
                            onClick={() => {
                              dispatch(
                                saveSingleOrder({
                                  date: datePicked,
                                  order_id: Id,
                                })
                              );
                              navigate(`/custom-checkout/${Id}`);
                            }}
                          >
                            PROCEED TO CHECKOUT
                          </button>
                        </div>
                      </div>
                    )}
                  </Form>
                </Container>
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  );
}
