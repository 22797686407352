import React from "react";

export default function HeaderModal({ title, subtitle }) {
  return (
    <div className="header-bg-modal ">
      <p className="title-modal">{title}</p>
      <p className="subTitle-modal mb-0">{subtitle}</p>
    </div>
  );
}
