import React, { useState } from "react";
import passwordIcon from "../../../assets/img/password-icon.svg";
// import close from '../../assets/img/close.svg'
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const Password = ({
  showModal,
  setShowModal,
  setEditPaymentModalShow,
  setDeleteModal,
  child,
  handlePassword,
  type,
  color,
}) => {
  const [pass, setPass] = useState();
  const [passwordType, setPasswordType] = useState("password");
  const dispatch = useDispatch();
  const { privateaccess } = useSelector((store) => store.stores);
  const handleOpen = () => {
    dispatch(handlePassword({ pass, type }));
  };

  useEffect(() => {
    // if (privateaccess === "edit") {
    //   setShowModal(false);
    //   setEditPaymentModalShow(true);
    // }
    if (privateaccess === "delete") {
      setShowModal(false);
      setDeleteModal(true);
    }
  }, [privateaccess]);

  const handleClose = () => {
    setShowModal(false);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="yesNoModal"
      show={showModal}
      onHide={handleClose}
    >
      <Modal.Header closeButton className="yesNoModal-header"></Modal.Header>
      <Modal.Body className="yesNoModal-body">
        <div className="d-flex pb-3 align-items-start">
          <div>
            <img src={passwordIcon} alt="icon" />
          </div>
          <div className="ps-3">
            <div className="white20Text text-capitalize pb-2">
              enter password to take action
            </div>
            <div className="blue10Text">password</div>
            <div className="grayBorder mt-2 d-flex">
              <input
                type={passwordType}
                className=" model-input2 no-outline no-border"
                placeholder="Enter Password"
                onChange={(e) => setPass(e.target.value)}
              />
              <button className="button-eye" onClick={togglePassword}>
                {passwordType === "password" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#A4A2DC"
                    className="bi bi-eye-slash me-2 "
                    viewBox="0 0 16 16"
                  >
                    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                    <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                  </svg>
                ) : (
                  <>
                    <svg
                      id="view"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="12"
                      viewBox="0 0 12.771 8.127"
                      className="me-2"
                    >
                      <path
                        id="Path_9797"
                        data-name="Path 9797"
                        d="M7.386,5A7.46,7.46,0,0,0,1,9.064a7.46,7.46,0,0,0,6.386,4.064,7.46,7.46,0,0,0,6.386-4.064A7.46,7.46,0,0,0,7.386,5Zm0,6.966a6.1,6.1,0,0,1-5.006-2.9,6.1,6.1,0,0,1,5.006-2.9,6.094,6.094,0,0,1,5.006,2.9,6.094,6.094,0,0,1-5.006,2.9Z"
                        transform="translate(-1 -5)"
                        fill="#fff"
                      />
                      <circle
                        id="Ellipse_176"
                        data-name="Ellipse 176"
                        cx="1.963"
                        cy="1.963"
                        r="1.963"
                        transform="translate(4.459 2.137)"
                        fill="#fff"
                      />
                    </svg>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="yesNoModal-footer">
        <button
          type="reset"
          className="round-gray-border-btn purple-text margin-right-2 model-btn"
          onClick={handleClose}
        >
          cancel
        </button>
        <button
          type="button"
          className={`${color}-round-btn  white10Text model-btn px-4`}
          onClick={handleOpen}
        >
          Take Action
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default Password;
