 // adding the colors array as reference
const colorArray = [
    { id: 1, color: "Ivory-Ivory" },
    { id: 2, color: "Ivory-Champagne" },
    { id: 3, color: "Ivory-Nude" },
    { id: 4, color: "Ivory" },
    { id: 13, color: "All Ivory" },
    // ... add more as needed
];

const sizeArray = [
  {id:1, size: "28"},
  {id:2, size: "26"},
  {id:3, size: "24"},
  {id:4, size: "22"},
  {id:5, size: "20"},
  {id:6, size: "18"},
  {id:7, size: "16"},
  {id:8, size: "14"},
  {id:9, size: "12"},
  {id:10, size: "10"},
  {id:11, size: "8"},
  {id:12, size: "6"},
  {id:13, size: "4"},
  {id:14, size: "2"},
  {id:15, size: "0"},
]

// adding a function to fetch a color
export function getColorById(id) {
  const item = colorArray.find(obj => obj.id === id);
  return item ? item.color : null; // returns the color if found, else null
}

export function getSizeById(id) {
  const item = sizeArray.find(obj => obj.id === id);
  return item ? item.size : null; // returns the size if found, else null
}