import React, { useEffect, useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import FormInput from "../../../../shared/FormInput";
import Select from "react-select";
import { updatePayment } from "../../../../../features/payment/paymentSlice";
function Credit({ stateList, countryList, setModalOpen, selectedId }) {
  const dispatch = useDispatch();

  const { payment } = useSelector((store) => store.payment);
  const [selected_country, set_selected_country] = useState();
  const [selected_state, set_selected_state] = useState();

  useEffect(() => {
    if (selected_country)
      validation.setFieldValue("address_country", selected_country.value);
  }, [selected_country]);

  useEffect(() => {
    if (selected_state)
      validation.setFieldValue("address_state", selected_state.value);
  }, [selected_state]);

  useEffect(() => {
    if (payment) {
      if (payment.card_exp) {
        validation.setFieldValue("month", payment.card_exp.slice(0, 2));
        validation.setFieldValue("year", payment.card_exp.slice(2));
      }
      validation.setFieldValue("person_name", payment.person_name);
      validation.setFieldValue("address_1", payment.address_1);
      validation.setFieldValue("address_city", payment.address_city);
      validation.setFieldValue("address_zip", payment.address_zip);
      validation.setFieldValue("card_last_four", payment.card_last_four);
      set_selected_country(payment.address_country);
      set_selected_state(payment.address_state);
    }
  }, [payment]);

  useEffect(() => {
    validation.setFieldValue(
      "address_country",
      countryList.find((values) => {
        return values.value === payment.address_country;
      })
    );
    validation.setFieldValue(
      "address_state",
      stateList.find((values) => {
        return values.value === selected_state;
      })
    );
  }, [selected_country, selected_state]);

  const validation = useFormik({
    initialValues: {
      month: "",
      year: "",
      person_name: "",
      address_1: "",
      address_city: "",
      address_state: "",
      address_country: "",
      address_zip: "",
      card_last_four: "",
    },
    validationSchema: Yup.object({
      month: Yup.number()
        .typeError("This field must be a number")
        .required("This Field is required!"),
      year: Yup.number()
        .typeError("This field must be a number")
        .required("This Field is required!"),
      person_name: Yup.string().required("This Field is required!"),
      address_1: Yup.string().required("This Field is required!"),
      address_city: Yup.string().required("This Field is required!"),
      address_zip: Yup.number()
        .typeError("This field must be a number")
        .required("This Field is required!"),
    }),
    onSubmit: (values) => {
      let updated_payment = {
        _method: "PUT",
        person_name: values.person_name,
        address_1: values.address_1,
        address_city: values.address_city,
        address_state: values.address_state.value,
        address_country: values.address_country.value,
        address_zip: values.address_zip,
        month: values.month,
        year: Number(values.year),
      };

      // console.log(updated_payment);
      dispatch(updatePayment({ id: selectedId, payment: updated_payment }));
      setModalOpen(false);
    },
  });

  return (
    <Container className="py-4">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
        }}
        className="row g-3"
      >
        <div className="col-md-6">
          <div className="col-md-6">
            <label htmlFor="card-number" className="label-text ms-0 pt-2 mb-0">
              CREDIT CARD NUMBER *
            </label>
            <input
              className="payment_input_box"
              type="text"
              value={`**** **** **** ${validation.values.card_last_four}`}
              disabled={true}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="row">
            <div className="col-md-6">
              <FormInput
                type="text"
                name="month"
                maxLength="2"
                labelText=" EXP MONTH *"
                placeholder="MM"
                value={validation.values.month}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
              />
              {validation.touched.month && validation.errors.month ? (
                <p className="error d-flex flex-row-reverse pt-1">
                  {validation.errors.month}
                </p>
              ) : null}
            </div>

            <div className="col-md-6">
              <FormInput
                type="text"
                name="year"
                maxLength="4"
                placeholder="YYYY"
                value={validation.values.year}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
              />
              {validation.touched.year && validation.errors.year ? (
                <p className="error d-flex flex-row-reverse pt-1">
                  {validation.errors.year}
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="card-cvv" className="label-text ms-0 pt-2 mb-0">
                CVV *
              </label>
              <input
                className="payment_input_box"
                type="text"
                value={`***`}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <FormInput
            type="text"
            name="person_name"
            labelText="FULL NAME ON CARD *"
            placeholder="Enter Full Name On Card"
            value={validation.values.person_name}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
          {validation.touched.person_name && validation.errors.person_name ? (
            <p className="error d-flex flex-row-reverse pt-1">
              {validation.errors.person_name}
            </p>
          ) : null}
        </div>

        <div className="col-md-6">
          <FormInput
            type="text"
            name="address_1"
            labelText="STREET *"
            placeholder="Enter Street Name"
            value={validation.values.address_1}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
          {validation.touched.address_1 && validation.errors.address_1 ? (
            <p className="error d-flex flex-row-reverse pt-1">
              {validation.errors.address_1}
            </p>
          ) : null}
        </div>

        <div className="col-md-6">
          <FormInput
            type="text"
            name="address_city"
            labelText="CITY *"
            placeholder="Enter City Name"
            value={validation.values.address_city}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
          {validation.touched.address_city && validation.errors.address_city ? (
            <p className="error d-flex flex-row-reverse pt-1">
              {validation.errors.address_city}
            </p>
          ) : null}
        </div>

        <div className="col-md-6">
          <label htmlFor="address_state" className="label-text ms-0 pt-2 mb-0">
            STATE *
          </label>
          <Select
            name="address_state"
            value={validation.values.address_state}
            options={stateList}
            placeholder="Select a State Name"
            onChange={(selectedOption) => {
              validation.setFieldValue("address_state", selectedOption);
            }}
            className="select-colors"
          />
        </div>

        <div className="col-md-6">
          <label
            htmlFor="address_country"
            className="label-text ms-0 pt-2 mb-0"
          >
            COUNTRY *
          </label>
          <Select
            name="address_country"
            value={validation.values.address_country}
            options={countryList}
            placeholder="Select a Country Name"
            onChange={(selectedOption) => {
              validation.setFieldValue("address_country", selectedOption);
            }}
            className="select-colors"
          />
        </div>

        <div className="col-md-6">
          <FormInput
            type="text"
            name="address_zip"
            labelText="ZIP CODE *"
            placeholder="Enter ZIP Code"
            value={validation.values.address_zip}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
          {validation.touched.address_zip && validation.errors.address_zip ? (
            <p className="error d-flex flex-row-reverse pt-1">
              {validation.errors.address_zip}
            </p>
          ) : null}
        </div>

        <div className="d-flex flex-row-reverse mt-5">
          <Button
            // disabled={this.state.isPayButtonDisabled}
            className="btn-secondary me-md-2"
            type="submit"
          >
            save card information
          </Button>
        </div>
      </Form>
    </Container>
  );
}

export default Credit;
