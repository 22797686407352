import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";
import fileDownload from "js-file-download";

export const exportInvoiceThunk = async (url, order, thunkAPI) => {
  console.log("url", url);
  try {
    const resp = await customFetch.get(url, order);
    fileDownload(resp.data, "orders.xlsx");
    return "Orders exported successfully";
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const getStoreInvoiceThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.get(url);
    return resp.data.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const getStoreTransactionThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.get(url);
    return resp.data.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const getInvoiceThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.get(url);
    return resp.data.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const payThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.post(url);
    return resp;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
