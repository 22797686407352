import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../shared/Header";
import { Container, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getOrder, downloadOrder } from "../../features/order/orderSlice";
import { useParams } from "react-router-dom";
import moment from "moment";
import ViewImage from "../shared/ViewImage";
import Loading from "../shared/Loading";
import { setFixed } from "../../features/store/storeSlice";
import { formatPhoneNumber } from "../../helpers/helpers";

export default function SalesOrderSummary() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [state, setState] = useState("");
  const { fixed } = useSelector((store) => store.store);

  useEffect(() => {
    dispatch(
      getOrder({ id: id, from: from, to: to, sort: sort, orderby: orderby })
    );
  }, []);
  const {
    image,
    isLoading,
    style_number,
    sort,
    orderby,
    name,
    wear_date,
    colorLabel,
    sizeLabel,
    estimated_months,
    estimated_days,
    from,
    to,
    orderId,
    purchase_order_number,
    type,
    address,
    formatted_price,
    sales_order_number,
    description,
    notess,
    custom_size_detailss,
    estimated_ship_date,
    bust,
    hollow,
    hips,
    waist,
    wholesalePriceDress,
  } = useSelector((store) => store.order);

  const row = [
    {
      Id: orderId,
      Image: image,
      StyleNum: style_number,
      DressName: name,
      WearDate: wear_date,
      estimatedDays: estimated_days,
      estimatedMonths: estimated_months,
      color: colorLabel,
      size: sizeLabel,
      description: description,
      estimated_ship_date: estimated_ship_date,
    },
  ];
  useEffect(() => {
    if (address) {
      var addressState = Object.values(address.state);
      setState(addressState);
    }
  }, [address]);

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var newDD = +dd + +estimated_days;
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var newMM = +mm + +estimated_months;
  var yyyy = today.getFullYear();
  today = newMM + "/" + newDD + "/" + yyyy;

  useEffect(() => {
    dispatch(setFixed(false));
    console.log("ff", fixed);
  }, [fixed]);

  return (
    <div className="scrollX">
      <Header
        title={`Sales Order #${sales_order_number}`}
        subtitle="Please check the sales order and download it as pdf"
      />

      <Container className="mb-4 scrollX">
        <div className="back-arrow">
          <Link to="/sales-order" className="back-to-list">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19.7"
              height="9.542"
              viewBox="0 0 19.7 9.542"
            >
              <g
                id="Group_439"
                data-name="Group 439"
                transform="translate(-9.045 -14.126)"
              >
                <g id="right-arrow" transform="translate(9.045 14.126)">
                  <g
                    id="Group_257"
                    data-name="Group 257"
                    transform="translate(0 0)"
                  >
                    <path
                      id="Path_84"
                      data-name="Path 84"
                      d="M.226,136.226h0l4.021-4a.77.77,0,0,1,1.086,1.091L2.633,136h16.3a.77.77,0,1,1,0,1.539H2.633l2.7,2.687a.77.77,0,0,1-1.086,1.091l-4.021-4h0A.77.77,0,0,1,.226,136.226Z"
                      transform="translate(0 -132)"
                      fill="#b8998d"
                    />
                  </g>
                </g>
              </g>
            </svg>
            <span className="back-to-list px-2">back to list</span>
          </Link>
        </div>

        <Container className="border row width90 display-block maxWidthTablet">
          <h5 className="summary-header">Sales Order Summary</h5>
          <hr className="mx-6 ms-2" />

          <div className="col-9 widthTablet">
            <div className="row grid-header mt-4 p-0 width90 widthTitlesSummaryTab">
              <div className="col-lg-2 ms-4 width-label widthTitleTab">
                Image
              </div>
              {style_number ? (
                <div className="col-lg-1 col-md-1 col-12 width-label widthTitleTab">
                  Style #
                </div>
              ) : (
                ""
              )}
              {colorLabel ? (
                <div className="col-lg-2 col-md-2 col-12 width-label widthTitleTab">
                  Dress name
                </div>
              ) : (
                <div className="col-lg-2 col-md-2 col-12 width-label widthTitleTab">
                  Dress name
                </div>
              )}
              <div className="col-lg-2 col-md-2 width-label widthTitleTab">
                Wear date
              </div>
              <div className="col-lg-2 col-md-2 text-center width-label widthTitleTab">
                Estimated ship date
              </div>
              {colorLabel ? (
                <div className="col-lg-1 col-md-1 width-label widthTitleTab">
                  Color
                </div>
              ) : (
                <div className="col-lg-2 col-md-2 width-label widthTitleTab">
                  Description
                </div>
              )}
              {sizeLabel ? (
                <div className="col-lg-1 col-md-1 width-label widthTitleTab">
                  Size
                </div>
              ) : (
                <div className="col-lg-1 col-md-1 width-label widthTitleTab"></div>
              )}
            </div>

            {row.map((row) => {
              const {
                Id,
                Image,
                StyleNum,
                DressName,
                WearDate,
                color,
                size,
              } = row;
              let modalID = `#myModal${Id}`;
              let modalTarget = `myModal${Id}`;
              return (
                <div
                  key={Id}
                  className="grid-row-border widthSummary boxWidthTab"
                >
                  <div className="row align-items-center">
                    <div className="d-flex margin-top-sum">
                      <div className="pb-2 show-label grid-header ms-4">
                        IMAGE
                      </div>
                      <div className="show-label grid-header ms-lg-5 styleSum">
                        STYLE NUM
                      </div>
                      <div className="show-label grid-header ms-lg-5 titleSalesOrder">
                        DRESS NAME
                      </div>
                    </div>

                    <div className="d-flex widthSt">
                      {StyleNum ? (
                        <div className="col-lg-1 col-md-1 show-label">
                          {StyleNum}
                        </div>
                      ) : (
                        ""
                      )}
                      {StyleNum ? (
                        <div className="col-lg-1 col-md-1 show-label ms-5 ps-3 paddLeft">
                          {DressName}
                        </div>
                      ) : (
                        <div className="col-lg-2 col-md-2  show-label ms-5 ps-3">
                          {DressName}
                        </div>
                      )}
                    </div>

                    {/*  */}
                    <div className="d-flex widthSt">
                      <div className="col-lg-2 col-md-2 show-label mt-3 grid-header">
                        Wear date
                      </div>
                      <div className="col-lg-2 col-md-2 show-label mt-3 grid-header ms-5">
                        Estimated ship date
                      </div>
                    </div>
                    {/*  */}

                    <div className="d-flex widthSt">
                      <div className="col-lg-2 col-md-2 show-label ">
                        {moment(WearDate).format("MM/DD/YYYY")}
                      </div>

                      <div className="col-lg-2 col-md-2 show-label ms-lg-4 paddLeft titleOrd">
                        {moment(estimated_ship_date).format("MM/DD/YYYY")}
                      </div>
                    </div>
                    {/*  */}
                    <div className="d-flex widthSt">
                      {sizeLabel ? (
                        <div className="col-lg-1 col-md-1 show-label mt-4 grid-header">
                          Size
                        </div>
                      ) : (
                        <div className="col-lg-1 col-md-1 show-label"></div>
                      )}
                    </div>
                    <div className="col-lg-1 col-md-1 show-label widthSt">
                      {size}
                    </div>

                    <div className="col-lg-2 col-md-2 ms-4">
                      <img
                        className="grid-image grid-image-checkout"
                        src={Image}
                        alt={DressName}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        className="grid-eye ms-1 grid-image-checkout"
                        data-bs-toggle="modal"
                        data-bs-target={modalID}
                      >
                        <g
                          id="Group_706"
                          data-name="Group 706"
                          transform="translate(-515.8 -489.8)"
                        >
                          <circle
                            id="Ellipse_177"
                            data-name="Ellipse 177"
                            cx="10"
                            cy="10"
                            r="10"
                            transform="translate(515.8 489.8)"
                            fill="#b8998d"
                          />
                          <g id="view" transform="translate(519.428 495.885)">
                            <path
                              id="Path_9797"
                              data-name="Path 9797"
                              d="M7.386,5A7.46,7.46,0,0,0,1,9.064a7.46,7.46,0,0,0,6.386,4.064,7.46,7.46,0,0,0,6.386-4.064A7.46,7.46,0,0,0,7.386,5Zm0,6.966a6.1,6.1,0,0,1-5.006-2.9,6.1,6.1,0,0,1,5.006-2.9,6.094,6.094,0,0,1,5.006,2.9,6.094,6.094,0,0,1-5.006,2.9Z"
                              transform="translate(-1 -5)"
                              fill="#fff"
                            />
                            <circle
                              id="Ellipse_176"
                              data-name="Ellipse 176"
                              cx="1.963"
                              cy="1.963"
                              r="1.963"
                              transform="translate(4.459 2.137)"
                              fill="#fff"
                            />
                          </g>
                        </g>
                      </svg>
                      <ViewImage
                        header={DressName}
                        src={Image}
                        modalTarget={modalTarget}
                        labels={["Item Name", "Style Number"]}
                        body={[DressName, StyleNum]}
                      />
                    </div>
                    {StyleNum ? (
                      <div className="col-lg-1 col-md-1 col-12 width-label  widthSalesOrderOnTab">
                        {StyleNum}
                      </div>
                    ) : (
                      ""
                    )}
                    {StyleNum ? (
                      <div className="col-lg-2 col-md-2 col-12 width-label  widthTitleTab">
                        {DressName}
                      </div>
                    ) : (
                      <div className="col-lg-2 col-md-2 col-12 width-label widthSalesOrderOnTab">
                        {DressName}
                      </div>
                    )}

                    <div className="col-lg-2 col-md-2 width-label widthSalesOrderOnTab">
                      {moment(WearDate).format("MM/DD/YYYY")}
                    </div>

                    <div className="col-lg-2 col-md-2 width-label widthSalesOrderOnTab text-center paddingToLeftTab">
                      {moment(estimated_ship_date).format("MM/DD/YYYY")}
                    </div>
                    {color ? (
                      <div className="col-lg-1 col-md-1 width-label widthSalesOrderOnTab paddingToLeftTab">
                        {color}
                      </div>
                    ) : (
                      <div className="col-lg-3 col-md-2 width-label widthSalesOrderOnTab">
                        {description}
                      </div>
                    )}

                    <div className="col-lg-1 col-md-1 width-label paddingToLeft">
                      {size}
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="border mt-2 lh-1 widthSummary">
              {address ? (
                <>
                  <p className="second-title px-4 mb-0 pt-4">
                    {address.full_name}
                  </p>
                  <p className="paragraph px-4 mb-2">
                    {address.street}, {address.city}, {state} {""} {address.zip}
                  </p>
                  <p className="paragraph px-4 pb-3">
                    {formatPhoneNumber(address.phone)}
                  </p>
                </>
              ) : (
                ""
              )}
            </div>

            <div className="border mt-2 widthSummary">
              <p className="px-4 details-header">purchase order number</p>
              <p className="px-4 ">{purchase_order_number}</p>
              <p className="px-4 details-header mt-0">type of order</p>
              <p className="px-4">{type}</p>
            </div>
            <div className="border mt-4 widthSummary mb-4">
              {bust ? (
                <>
                  <div className="ms-4">
                    <p className="mb-0 details-header">bust</p>
                    <p>{bust}</p>
                  </div>
                </>
              ) : (
                ""
              )}
              {waist ? (
                <>
                  <div className="ms-4">
                    <p className="mb-0 details-header">waist</p>
                    <p>{waist}</p>
                  </div>
                </>
              ) : (
                ""
              )}
              {hips ? (
                <>
                  <div className="ms-4">
                    <p className="mb-0 details-header">hips</p>
                    <p>{hips}</p>
                  </div>
                </>
              ) : (
                ""
              )}
              {hollow ? (
                <>
                  <div className="ms-4">
                    <p className="mb-0 details-header">hollow</p>
                    <p>{hollow}</p>
                  </div>
                </>
              ) : (
                ""
              )}
              {custom_size_detailss === "No Custom Sizing" ? (
                <p></p>
              ) : (
                <>
                  <p className="px-4 details-header">Other</p>
                  <p className="px-4 ">{custom_size_detailss}</p>
                </>
              )}

              <p className="px-4 details-header mt-0">notes</p>
              <p className="px-4">{notess}</p>
            </div>
          </div>

          <div className="col-3 width-subtitle-payment pe-0 widthTabletSalesOrderSummary">
            <Container className="bg-light head-container">
              <p className="summary-title ps-2">summary</p>
              <hr className="mx-0" />
              <p className="ps-2">Dress Cost</p>
              <p className="sales-summary-cost ps-2">{wholesalePriceDress}</p>
              {sales_order_number === "" ? (
                <>
                  <p className="summary-text ps-2">
                    This is an estimated cost, which is subject to change in the
                    sales order if additional charges (for example for larger
                    size wedding dresses, or custom measurements, or a rush fee
                    on case by case basis) are applied
                  </p>

                  {bust ||
                  waist ||
                  hips ||
                  hollow ||
                  custom_size_detailss !== "No Custom Sizing" ? (
                    <>
                      <p className="ps-2">Custom Measurement</p>
                      <p className="sales-summary-cost ps-2">$95.00</p>
                      <p className="ps-2">Total</p>
                      <p className="sales-summary-cost ps-2">
                        {formatted_price}
                      </p>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </Container>

            <Button
              className="btn-secondary summary-btn"
              onClick={() => {
                console.log(orderId);
                dispatch(downloadOrder(orderId));
              }}
              disabled={isLoading}
            >
              download pdf
            </Button>
            {isLoading && <Loading />}
          </div>
        </Container>
      </Container>
    </div>
  );
}
