import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Header from "../shared/Header";
import AddPaymentModal from "./addStorePaymentMethodModal";
import * as Yup from "yup";
import { useFormik } from "formik";
import FormInput from "../shared/FormInput";
import { useDispatch, useSelector } from "react-redux";
import { getStore, updateStore } from "../../features/retailers/retailersSlice";
import { useNavigate } from "react-router-dom";
import { getCountries } from "../../features/country/countrySlice";
import { getStates } from "../../features/state/stateSlice";
import { useParams } from "react-router-dom";
import { formatPhoneNumber } from "../../helpers/helpers";

const EditStore = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { CurrentStore } = useSelector((store) => store.stores);

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getStates());
  }, []);
  useEffect(() => {
    dispatch(getStore(id));
  }, []);

  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    if (CurrentStore) {
      validation.setFieldValue("first_name", CurrentStore.first_name);
      validation.setFieldValue("last_name", CurrentStore.last_name);
      validation.setFieldValue("phone", formatPhoneNumber(CurrentStore.phone));
      validation.setFieldValue("email", CurrentStore.email);
      validation.setFieldValue("link", CurrentStore.website);
      validation.setFieldValue("name", CurrentStore.name);
      // validation.setFieldValue("selected_country", CurrentStore.);
      // validation.setFieldValue("city", CurrentStore.);
      // validation.setFieldValue("selected_state", CurrentStore.);
      validation.setFieldValue("address", CurrentStore.address);
      // validation.setFieldValue("zip", CurrentStore.);
      // validation.setFieldValue("street", CurrentStore.);
    }
  }, [CurrentStore]);

  const validation = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      link: "",
      name: "",
      address: "",
      //   selected_country: "",
      //   city: "",
      //   selected_state: "",
      //   zip: "",
      //   street: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("This Field is required!"),
      last_name: Yup.string().required("This Field is required!"),
      phone: Yup.number()
        .typeError("This field must be a number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .required("This Field is required!"),
      email: Yup.string()
        .email("The email you entered is invalid!")
        .required("This Field is required!"),
      link: Yup.string().required("This Field is required!"),
      name: Yup.string().required("This Field is required!"),
      address: Yup.string().required("This Field is required!"),
      //   selected_country: Yup.object().required("This Field is required!"),
      //   city: Yup.string().required("This Field is required!"),
      //   selected_state: Yup.object().required("This Field is required!"),
      //   zip: Yup.number().required("This Field is required!"),
      //   street: Yup.string().required("This Field is required!"),
    }),
    onSubmit: (values) => {
      dispatch(
        updateStore({
          storeId: id,
          store: {
            _method: "PUT",
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            phone: values.phone.replace(/\D/g, ""),
            website: values.link,
            name: values.name,
            address: values.address,
            //   country: values.selected_country.value,
            //   city: values.city,
            //   state: values.selected_state.value,
            //   street: values.street,
            //   zip: values.zip,
          },
        })
      ).then(() => {
        window.location.reload(false);
      });
      navigate("/list-of-retailers");
    },
  });

  return (
    <div className="container m-0 p-0">
      <Header
        title="Edit Store"
        subtitle="Please enter the below information of the new store"
        arrowname="BACK TO LIST OF RETAILERS"
        arrowroute={"/list-retailers"}
        arrowtrue={true}
        hrtrue={true}
      />
      <div className="position-absolute">
        {modalOpen && <AddPaymentModal setModalOpen={setModalOpen} />}
      </div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
        }}
      >
        <div className="container wrapper">
          <div className="tableWrapper p-4">
            <p className="info-txt mb-4">Store Details</p>
            <div className="row order-title my-3">
              <div className="col-md-6 ">
                <FormInput
                  type="text"
                  name="first_name"
                  labelText="Retailer's First Name"
                  placeholder="Please Enter Retailers First Name"
                  className="form-cotrol input-credit "
                  value={validation.values.first_name}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.touched.first_name &&
                validation.errors.first_name ? (
                  <p className="error d-flex flex-row-reverse pt-1">
                    {validation.errors.first_name}
                  </p>
                ) : null}
              </div>
              <div className="col-md-6">
                <FormInput
                  type="text"
                  name="last_name"
                  labelText="Retailer's Last Name"
                  placeholder="Please Enter Retailers Last Name"
                  className="form-cotrol input-credit"
                  value={validation.values.last_name}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.touched.last_name && validation.errors.last_name ? (
                  <p className="error d-flex flex-row-reverse pt-1">
                    {validation.errors.last_name}
                  </p>
                ) : null}
              </div>
            </div>

            <div className="row order-title my-3">
              <div className="col-md-6 ">
                <FormInput
                  type="text"
                  name="phone"
                  labelText="Phone Number"
                  placeholder="Please Enter Phone Number"
                  className="form-cotrol input-credit "
                  value={validation.values.phone}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.touched.phone && validation.errors.phone ? (
                  <p className="error d-flex flex-row-reverse pt-1">
                    {validation.errors.phone}
                  </p>
                ) : null}
              </div>
              <div className="col-md-6">
                <FormInput
                  type="text"
                  name="email"
                  labelText=" Email Address"
                  placeholder="Please Enter Email Address"
                  className="form-cotrol input-credit "
                  value={validation.values.email}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.touched.email && validation.errors.email ? (
                  <p className="error d-flex flex-row-reverse pt-1">
                    {validation.errors.email}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="row order-title my-3">
              <div className="col-md-6">
                <FormInput
                  type="text"
                  name="name"
                  labelText="Store Name"
                  placeholder="Please Enter Store Name"
                  className="form-cotrol input-credit "
                  value={validation.values.name}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.touched.name && validation.errors.name ? (
                  <p className="error d-flex flex-row-reverse pt-1">
                    {validation.errors.name}
                  </p>
                ) : null}
              </div>

              <div className="col-md-6">
                <FormInput
                  type="text"
                  name="address"
                  labelText="Store Address"
                  placeholder="Please Enter Store Address"
                  className="form-cotrol input-credit "
                  value={validation.values.address}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.touched.address && validation.errors.address ? (
                  <p className="error d-flex flex-row-reverse pt-1">
                    {validation.errors.address}
                  </p>
                ) : null}
              </div>
            </div>
            <hr className="gray-hr" />

            <div className="row order-title my-3">
              <div className="col-md-6 ">
                <FormInput
                  type="text"
                  name="link"
                  labelText="Website URL Link"
                  placeholder="Please Enter URL Link"
                  className="form-cotrol input-credit "
                  value={validation.values.link}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.touched.link && validation.errors.link ? (
                  <p className="error d-flex flex-row-reverse pt-1">
                    {validation.errors.link}
                  </p>
                ) : null}
              </div>
            </div>
            <hr className="gray-hr" />

            <div className="d-flex justify-content-end">
              <button
                className="btn-add-store px-5 header-btn-height py-2"
                type="submit"
              >
                SAVE CHANGES
              </button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default EditStore;
