import React from "react";
import styled from "styled-components";

const ViewImage = ({ header, src, modalTarget, labels, body }) => {
  return (
    <Wrapper className="modal" id={modalTarget}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{header}</h4>
            <button
              type="button"
              className="btn-secondary height-30"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>

          <div className="modal-body">
            <div className="d-flex">
              <div>
                <img src={src} alt="" className="modal-img ms-5" />
              </div>
              <div className="modalDressDetails">
                <table>
                  {body.map((val, i) => (
                    <tbody key={i}>
                      <tr>
                        <td>
                          <label>{labels[i]}</label>
                        </td>
                        <td>{val}</td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .modal-header {
    color: #fff;
    border-bottom: 1px solid #80808c;
  }

  .modal-content {
    background-color: #ffffff;
  }

  td {
    border: 1px solid #80808c;
    font-size: 14px;
    padding: 20px;
  }

  .modal.show {
    max-width: fit-content !important;
  }

  label {
    color: #b8998d;
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: none;
      max-width: fit-content;
      margin: 1.75rem auto;
    }
  }

  @media (min-width: 376px) and (max-width: 435px) {
    .modal {
      max-width: 90vw;
      max-height: 50%;
    }
    .modal-img {
      width: 80% !important;
      height: auto !important;
    }
    table {
      margin-top: 2rem;
      margin-left: 3.2rem;
    }
  }
  @media only screen and (width: 360px) {
    table {
      margin-top: 2rem !important;
      margin-left: 3rem !important;
    }
  }
  @media only screen and (width: 810px) {
    table {
      margin-top: 2rem !important;
      margin-left: 3rem !important;
    }
  }
`;

export default ViewImage;
