import React, { useState } from "react";
import DatepickerComponent from "./DatepickerComponent";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";

const DateFilter = ({ handleFilter }) => {
  const dispatch = useDispatch();
  const date = new Date();

  let day = String(date.getDate()).padStart(2, "0");
  let month = String(date.getMonth() + 1).padStart(2, "0");
  let year = date.getFullYear();
  let fromyear = date.getFullYear() - 1;

  let currentDate = `${year}-${month}-${day}`;
  let currentFromDate = `${fromyear}-${month}-${day}`;
  const [selectedDate, setSelectedDate] = useState({ currentDate });

  return (
    <div className="container mt-5 mb-4 pe-5 d-flex ms-4 width-dateFilter ">
      <Form>
        <Form.Group controlId="date" className="d-flex align-items-center">
          <DatepickerComponent
            id="Date"
            label="From"
            labelClass="mb-0 d-flex align-items-center"
            name="date-from"
            selectedValue={
              selectedDate.from ? selectedDate.from : currentFromDate
            }
            propsClass="mx-3 d-flex align-items-center date-input height37 "
            handleChange={(e) => {
              setSelectedDate({ from: e.target.value });

              document
                .getElementById("Date")
                .addEventListener("change", function(e) {
                  e.target.blur();
                });
            }}
          />

          <DatepickerComponent
            id="To"
            label="To"
            labelClass="mb-0 d-flex align-items-center"
            max={new Date().toLocaleDateString("en-ca")}
            name="date-to"
            selectedValue={
              selectedDate.to ? selectedDate.to : selectedDate.currentDate
            }
            propsClass="mx-3 d-flex align-items-center date-input height37 "
            handleChange={(e) => {
              setSelectedDate({ ...selectedDate, to: e.target.value });
              document
                .getElementById("To")
                .addEventListener("change", function(e) {
                  e.target.blur();
                });
            }}
          />
          <button
            className="btn-secondary height37 mx-4"
            onClick={(e) => {
              e.preventDefault();
              dispatch(handleFilter(selectedDate));
            }}
          >
            Filter
          </button>
        </Form.Group>
      </Form>
    </div>
  );
};

export default DateFilter;
