import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getDressesThunk,
  getDressThunk,
  createDressThunk,
  updateDressThunk,
  removeDressThunk,
  exportDressesThunk,
} from "./dressThunk";

const initialFiltersState = {
  search: "",
  searchStatus: "all",
  searchType: "all",
  sortOptions: ["latest", "oldest", "a-z", "z-a"],
  sort: "asc",
  orderby: "name",
};
const singleDress = {
  size: "",
  color: "",
  style_number: "",
  dress_name: "",
  dress_colors: [],
  dress_sizes: [],
  dress_price: "",
  dress_image: "",
  selected_color: "",
  selected_size: {},
  dress_id: "",
  estimated_months: "",
  estimated_days: "",
};
const initialState = {
  isLoading: false,
  payeme: null,
  dresses: null,
  links: null,
  link: null,
  meta: null,
  endsession: false,
  name: "",
  date: "",
  factory_price: "",
  colors: [],
  sizes: [],
  ...singleDress,
  ...initialFiltersState,
  custom_items_ids: "",
  custom_size_details: '',
  others: '',
  bust: '',
  waist: '',
  hollow: '',
  hips: '',
  wholesale_price : '',
  co:'',
  selected_colorLabel: '',
  colorIdNew: '',
  selected_sizeLabel: '',
  sizeIdNew: '',
note:'',
allDresses:[],
};

export const getDressesFilter = createAsyncThunk(
  "dress/getDressesFilter",
  async (dress, thunkAPI) => {
    return getDressesThunk(
      `/api/dresses/published?per_page=-1`,
      thunkAPI
    );
  }
);

export const getDresses = createAsyncThunk(
  "dress/getDresses",
  async (dress, thunkAPI) => {
    if (dress.search) {
      return getDressesThunk(
        `/api/dresses/published?order_dir=${dress.sort}&order_by=${dress.orderby}&search=${dress.search}`,
        thunkAPI
      );
    }
    return getDressesThunk(
      `/api/dresses/published?order_dir=${dress.sort}&order_by=${dress.orderby}`,
      thunkAPI
    );
  }
);

export const exportDresses = createAsyncThunk(
  "dress/exportDresses",
  async (dress, thunkAPI) => {
    return exportDressesThunk(
      `/api/dresses/export?search=${dress.search}&order_dir=${dress.sort}&order_by=${dress.orderby}`,
      {
        responseType: "blob",
      },
      thunkAPI
    );
  }
);

export const getDressesPaginate = createAsyncThunk(
  "dress/getDressesPaginate",
  async (link, thunkAPI) => {
    return getDressesThunk(link + "&order_dir=asc&order_by=name", thunkAPI);
  }
);

export const getDress = createAsyncThunk(
  "dress/getDress",
  async (dress, thunkAPI) => {
    return getDressThunk(`/api/dresses/${dress.id}`, thunkAPI);
  }
);

export const createDress = createAsyncThunk(
  "dress/createDress",
  createDressThunk
);

export const updateDress = createAsyncThunk(
  "dress/updateDress",
  updateDressThunk
);

export const removeDress = createAsyncThunk(
  "dress/removeDress",
  async (dress, thunkAPI) => {
    return removeDressThunk("/api/dresses/" + dress.id, thunkAPI);
  }
);

const DressSlice = createSlice({
  name: "dresses",
  initialState,
  reducers: {
    handleChange: (state, { payload: { name, value } }) => {
      state[name] = value;
    },

    handleSort: (state, { payload }) => {
      state.orderby = payload.value;
      if (payload.sort === "desc") {
        state.sort = "asc";
      }
      if (payload.sort === "asc") {
        state.sort = "desc";
      }
    },
    handleUploadImage: (state, { payload }) => {
      state.dress_image = payload;
    },

    handleSearch: (state, { payload }) => {
      //state.search = payload.search;
      console.log("paypload", payload.search);
      console.log("state dresses",state.dresses);
    },
    clearValues: () => {
      return {
        ...initialState,
      };
    },
    clearDressValues: () => {
      return {
        ...singleDress,
      };
    },
    saveSingleDress: (state, { payload }) => {
      state.dress_name = payload.dress_name;
      state.date = payload.date;
      state.dress_image = payload.dress_image;
      state.selected_size = payload.selected_size;
      state.selected_color = payload.selected_color;
      state.selected_colorLabel = payload.colorLabel;
      state.colorIdNew = payload.colorId;
      state.dress_price = payload.dress_price;
      state.style_number = payload.style_number;
      state.dress_id = payload.dress_id;
      state.color = payload.color;
      state.size = payload.size;
      state.hips = payload.hips;
      state.note = payload.note;
      state.hollow = payload.hollow;
      state.bust = payload.bust;
      state.waist = payload.waist;
      state.sizeIdNew = payload.sizeId;
      state.selected_sizeLabel = payload.sizeLabel;
      state.custom_size_details = payload.custom_size_details;
    },
  },
  extraReducers: {
    [getDressesFilter.pending]: (state) => {
      state.isLoading = true;
      state.dresses = null;

    },
    [getDressesFilter.fulfilled]: (state, { payload }) => {
      const { dresses, links, meta } = payload;
      state.isLoading = false;
      state.allDresses = dresses;
      state.links = links;
      state.meta = meta;

    },
    [getDressesFilter.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },
    [getDresses.pending]: (state) => {
      state.isLoading = true;
    },
    [getDresses.fulfilled]: (state, { payload }) => {
      const { dresses, links, meta } = payload;
      state.isLoading = false;
      state.dresses = dresses;
      state.links = links;
      state.meta = meta;
    },
    [getDresses.rejected]: (state, payload) => {
      state.isLoading = false;
      state.endsession = true;
    },

    [getDressesPaginate.pending]: (state) => {
      state.isLoading = true;
    },

    [exportDresses.pending]: (state) => {
      state.isLoading = true;
    },
    [exportDresses.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [exportDresses.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },

    [getDressesPaginate.fulfilled]: (state, { payload }) => {
      const { dresses, links, meta } = payload;
      state.isLoading = false;
      state.dresses = dresses;
      state.links = links;
      state.meta = meta;
    },
    [getDressesPaginate.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },

    [getDress.pending]: (state) => {
      state.isLoading = true;
    },
    [getDress.fulfilled]: (state, { payload }) => {
      state.dress = payload;
      state.style_number = payload.style_number;
      state.dress_colors = payload.colors;
      state.dress_name = payload.name;
      state.dress_sizes = payload.sizes;
      state.wholesale_price
 = payload.wholesale_price
;
      state.dress_price = payload.formatted_wholesale_price;
      state.dress_image = payload.image;
      state.factory_price = payload.formatted_factory_price;
      state.estimated_days = payload.estimated_days;
      state.estimated_months = payload.estimated_months;
      state.isLoading = false;
      state.custom_items_ids = payload.custom_items_ids;
    },
    [getDress.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [createDress.pending]: (state) => {
      state.isLoading = true;
    },
    [createDress.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [createDress.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },

    [updateDress.pending]: (state) => {
      state.isLoading = true;
    },
    [updateDress.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [updateDress.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },

    [removeDress.pending]: (state) => {
      state.isLoading = true;
    },
    [removeDress.fulfilled]: (state, { payload }) => {
      const { dresses, links, meta } = payload;
      state.isLoading = false;
      state.dresses = dresses;
      state.links = links;
      state.meta = meta;
    },
    [removeDress.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },
  },
});

export const {
  handleChange,
  clearValues,
  handleEdit,
  setEditDress,
  isEditing,
  handleSave,
  handleSearch,
  handleSort,
  handleUploadImage,
  handleViewImage,
  clearDressValues,
  saveSingleDress,
} = DressSlice.actions;
export default DressSlice.reducer;
