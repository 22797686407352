import React, { useState } from "react";
import { Button } from "react-bootstrap";
import copy from "../../assets/img/copy.svg";
import plus from "../../assets/img/brown-plus.svg";
import close from "../../assets/img/close.svg";
import visa from "../../assets/img/visa.png";
import masterCard from "../../assets/img/master-card.png";
import bank from "../../assets/img/building.svg";
import AddPaymentModal from "../startOrder/checkout/modals/paymentModal/addPaymentMethodModal";
import EditPaymentModal from "../startOrder/checkout/modals/EditpaymentModal/EditPaymentMethodModal";
import error from "../../assets/img/error.svg";
import YesNo from "../shared/modals/YesNo";
import Loading from "../shared/Loading";
import Header from "../shared/Header";
import { setFixed } from "../../features/store/storeSlice";

import {
  getPaymentMethods,
  removePaymentMethod,
  handleDelete,
  getPayment,
  clearPaymentValues,
  handleIds,
} from "../../features/payment/paymentSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getStore, getStoreId } from "../../features/store/storeSlice";
import { formatStaxDate } from "../../helpers/helpers";
import { refreshPage } from "../../features/payment/paymentSlice";

export default function Payments() {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [deletModal, setDeletModal] = useState(false);
  const [allRows, setAllRows] = useState([]);
  const [deleteId, setDeleteID] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const { storeNew } = useSelector((store) => store.store);
  const { store_id } = useSelector((store) => store.store);
  const { paymentMethods, deletedPaymentId, rerender, isLoading } = useSelector(
    (store) => store.payment
  );
  const [RefreshPage, setRefreshPage] = useState(false);
  useEffect(() => {
    dispatch(getStoreId());
  }, []);

  const handleEdit = (id, method) => {
    if (id) {
      dispatch(getPayment(id));
    }
    dispatch(clearPaymentValues);
    setSelectedId(id);
    setPaymentMethod(method);
    setEditModal(true);
  };
  useEffect(() => {
    dispatch(refreshPage(RefreshPage));
  }, [RefreshPage]);

  useEffect(() => {
    if (store_id) {
      dispatch(getStore({ id: store_id }));
    }
  }, [store_id]);

  useEffect(() => {
    if (store_id) {
      dispatch(getPaymentMethods(store_id));
    }
  }, [store_id, RefreshPage, rerender, storeNew]);

  useEffect(() => {
    setDeleteID(deleteId);
  }, [deletedPaymentId]);

  useEffect(() => {
    if (paymentMethods.data) {
      let paymentMethodsArray = [];
      paymentMethods.data.map((paymentMethod, count) => {
        if (count === 0) {
          dispatch(handleIds(paymentMethod.id));
        }
        return paymentMethodsArray.push({
          count: count,
          id: paymentMethod.id,
          method: paymentMethod.method,
          logo:
            paymentMethod.method === "card"
              ? paymentMethod.card_type === "visa"
                ? visa
                : masterCard
              : bank,
          number: `**** **** **** ${paymentMethod.card_last_four}`,
          expireDate:
            paymentMethod.method === "card"
              ? `Expires on ${formatStaxDate(paymentMethod.card_exp_datetime)}`
              : "",
        });
      });
      setAllRows(paymentMethodsArray);
    }
  }, [paymentMethods, RefreshPage]);
  useEffect(() => {
    dispatch(setFixed(false));
  });
  return (
    <>
      <div className="scrollX">
        <Header title="Payments" subtitle="Manage all Payments" />
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            <div className="mt-5">
              {modalOpen && (
                <AddPaymentModal
                  setModalOpen={setModalOpen}
                  setRefreshPage={setRefreshPage}
                />
              )}
            </div>
            {editModal && (
              <EditPaymentModal
                setModalOpen={setEditModal}
                setRefreshPage={setRefreshPage}
                paymentMethod={paymentMethod}
                selectedId={selectedId}
              />
            )}

            {deletModal && (
              <YesNo
                show={deletModal}
                setShow={setDeletModal}
                title="Delete this payment method"
                msg="Are you sure you want to delete this payment method?"
                // color='red'
                icon={error}
                child={false}
                handleAction={removePaymentMethod}
                currentId={deletedPaymentId}
              />
            )}
            {storeNew.payment_method_type_id !== "2" ? (
              <div className="mt-2 scrollXOnTablet">
                {allRows.map((payment) => {
                  const { id, logo, number, expireDate, method } = payment;
                  return (
                    <div
                      key={id}
                      className="grid-row-border  ps-3 py-3 me-5 ms-4 paymentRowNew widthPaymentOnTablet"
                    >
                      <div className="row align-items-center row-Pay">
                        <div className="col payment-check form-check widthPayVisa ">
                          <img src={logo} alt="logo" />
                        </div>

                        <div className="col-lg-4 payment-check widthPay">
                          {number}
                        </div>
                        <div className="col-lg-3 col-md-3 col-12  grid-cell-body me-lg-4 payment-check widthPay">
                          {expireDate}
                        </div>
                        <div className="col-lg-2 col-md-2 col-12 py-1 grid-cell-body ms-lg-5 payment-check">
                          <div className="col-2 d-flex gap-2">
                            <Button
                              onClick={() => handleEdit(id, method)}
                              className="bg-transparent no-border p-0"
                            >
                              <img className="homepage" src={copy} alt="" />
                            </Button>
                            <Button
                              onClick={() => {
                                setDeletModal(true);
                                dispatch(handleDelete(id));
                              }}
                              className="bg-transparent no-border p-0 "
                            >
                              <img src={close} alt="" />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="color-secondary payment-button mt-2 d-flex justify-content-center button-payment ms-4 PaymentBtn button-smaller me-5">
                  <Button
                    type="button"
                    onClick={() => {
                      setModalOpen(true);
                    }}
                    className="bg-transparent no-border brownText"
                  >
                    <img className=" py-2 me-2" src={plus} alt="" />
                    Add new payment method
                  </Button>
                </div>
              </div>
            ) : (
              <div className="color-secondary payment-button mt-2 d-flex justify-content-center button-payment ms-4 PaymentBtn button-smaller me-5">
                <Button
                  type="button"
                  className="bg-transparent no-border brownText"
                >
                  CREDIT ON DELIVERY
                </Button>
              </div>
            )}
            <div className="justify-content-between align-paymentMethod-end d-flex ">
              <div></div>
              <div className="me-0 "></div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
