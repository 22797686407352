import React from "react";
import Header from "../../shared/Header";
import { Link,useLocation } from "react-router-dom";
import { useAccordionButton, Button, Accordion } from "react-bootstrap";
import SelectedInvoicesPay from "./SelectedInvoicesPay";
import OrderSummary from "./OrderSummary";


export default function MakePayment() {
  const location = useLocation()
  const  {              
    id,
    number,
    date,
    price,
    shipping,
    status,
    Id,
    address,
    shippingCost}  = location.state
    const data={id:id,number:number,date:date,price:price,shipping:shipping,status:status,Id:Id,address:address,shippingCost:shippingCost}
  return (
    <>
      <div>
        <Header title="Make a payment" subtitle="Please add shipping address and payment Method" />
      </div>

      <div className="container my-4 mt-4">
        <Link to="/invoice-summary" className="arrow">
          <svg xmlns="http://www.w3.org/2000/svg" width="19.7" height="9.542" viewBox="0 0 19.7 9.542">
            <g id="Group_439" data-name="Group 439" transform="translate(-9.045 -14.126)">
              <g id="right-arrow" transform="translate(9.045 14.126)">
                <g id="Group_257" data-name="Group 257" transform="translate(0 0)">
                  <path
                    id="Path_84"
                    data-name="Path 84"
                    d="M.226,136.226h0l4.021-4a.77.77,0,0,1,1.086,1.091L2.633,136h16.3a.77.77,0,1,1,0,1.539H2.633l2.7,2.687a.77.77,0,0,1-1.086,1.091l-4.021-4h0A.77.77,0,0,1,.226,136.226Z"
                    transform="translate(0 -132)"
                    fill="#b8998d"
                  />
                </g>
              </g>
            </g>
          </svg>
          <span className=" back-to-list px-2">back to list</span>
        </Link>
      </div>

      <Accordion className="me-md-5 pe-md-4" defaultActiveKey='0'>
        <SelectedInvoicesPay data={data} CustomToggle={CustomToggle} ToggleNext={ToggleNext} />
        <OrderSummary data={data}  CustomToggle={CustomToggle} />
      </Accordion>
    </>
  );
}

function CustomToggle({ title, subtitle, eventKey }) {
  const handleOnClick = useAccordionButton(eventKey, () => {});
  const number = parseInt(eventKey) + 1;
  return (
    <div onClick={handleOnClick} className="d-flex">
      <div className="card-number me-4">
        <p className="mt-1">{number}</p>
      </div>
      <div>
        <div className="accordion-title">{title}</div>
        <div className="accordion-subtitle">{subtitle}</div>
      </div>
    </div>
  );
}

function ToggleNext({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => {});
  return (
    <Button onClick={decoratedOnClick} className="btn-secondary px-5 my-3 next-btn" type="button">
      {children}
    </Button>
  );
}
