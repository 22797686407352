import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import copy from "../../assets/img/copy.svg";
import plus from "../../assets/img/brown-plus.svg";
import close from "../../assets/img/close.svg";
import { useDispatch, useSelector } from "react-redux";
import { formatPhoneNumber } from "../../helpers/helpers";

import {
  getStoreAddresses,
  removeAddress,
  handleDelete,
  getAddress,
  handleSelectedAddress,
} from "../../features/address/addressSlice";
import {
  getStoreId,
  setAddress,
  setFixed,
} from "../../features/store/storeSlice";
import error from "../../assets/img/error.svg";
import YesNo from "../shared/modals/YesNo";
import Loading from "../shared/Loading";
import EditAddressModal from "../startOrder/checkout/modals/EditAddressModal";
import AddressModal from "../startOrder/checkout/modals/addNewAddressModal";
import Header from "../shared/Header";

function Address() {
  const dispatch = useDispatch();
  const [allRows, setAllRows] = useState([]);
  const [deletModal, setDeletModal] = useState(false);
  const [deleteId, setDeleteID] = useState();
  const [EditModal, setEditModal] = useState(false);
  const [modal, setModal] = useState(false);

  const {
    addresses,
    isLoading,
    deletedAddressId,
    isAddressDeleted,
    showAll,
  } = useSelector((store) => store.address);
  const { store_id, address } = useSelector((store) => store.store);

  useEffect(() => {
    dispatch(getStoreId());
  }, []);

  useEffect(() => {
    setDeleteID(deletedAddressId);
  }, [deletedAddressId]);

  useEffect(() => {
    if (store_id) {
      dispatch(getStoreAddresses({ id: store_id }));
    }
  }, [isAddressDeleted, store_id, showAll]);

  useEffect(() => {
    if (addresses) {
      let addressesArray = [];
      addresses.map((item, count) => {
        let phoneFormat = formatPhoneNumber(item.phone);
        if (count === 0) {
          dispatch(handleSelectedAddress(item.id));
        }
        return addressesArray.push({
          count: count,
          Id: item.id,
          street: item.street,
          addresState: item.state ? item.state[Object.keys(item.state)[0]] : "",
          city: item.city,
          zip: item.zip,
          country: item.country
            ? item.country[Object.keys(item.country)[0]]
            : "",

          full_name: item.full_name,
          phone: phoneFormat,
        });
      });
      setAllRows(addressesArray);
    }
  }, [addresses, showAll]);

  useEffect(() => {
    dispatch(setAddress(true));
    dispatch(setFixed(true));
  }, []);

  return (
    <div className="widthAddres">
      <div className={`${address === true && "mrgnTp"}`}>
        <div className="addresRowHeader ms-2">
          <Header title="Shipping Address" subtitle="Manage All Addresses" />
        </div>
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            {deletModal && (
              <YesNo
                show={deletModal}
                setShow={setDeletModal}
                title="Delete this address"
                msg="Are you sure you want to delete this address?"
                // color='red'
                icon={error}
                child={false}
                handleAction={removeAddress}
                currentId={deleteId}
              />
            )}
            {modal && <AddressModal setModal={setModal} />}

            <div className="row scrollXOnTablet">
              <div className="col-lg-11 col-sm-12 col-xs-12 ">
                <hr className="widthHrControl ms-sm-4 ms-md-4 ms-lg-4" />
                <div className="row">
                  {allRows.map((address) => {
                    const {
                      Id,
                      full_name,
                      street,
                      addresState,
                      city,
                      zip,
                      phone,
                    } = address;
                    return (
                      <div
                        key={Id}
                        className="grid-row-border ps-3 py-3 me-5 mt-4 ms-5 addresRow "
                      >
                        <div className="row align-items-center">
                          <div className="col addressWidth">{full_name}</div>
                          <div className="col addressWidth">{street}</div>
                          <div className="col addressWidth">{addresState}</div>
                          <div className="col addressWidth">{city}</div>
                          <div className="col addressWidth">{zip}</div>
                          <div className="col addressWidth">{phone}</div>
                          <div className="col-lg-2 col-md-2 col-12 py-1 grid-cell-body ms-lg-5 addressWidth">
                            <div className="col-2 d-flex gap-2">
                              <Button
                                className="bg-transparent no-border p-0 margin-right-2"
                                onClick={() => {
                                  setEditModal(true);
                                  dispatch(getAddress({ id: Id }));
                                }}
                              >
                                <img src={copy} alt="" />
                              </Button>
                              {EditModal && (
                                <EditAddressModal setModal={setEditModal} />
                              )}
                              <Button
                                onClick={() => {
                                  setDeletModal(true);
                                  dispatch(handleDelete(parseInt(Id)));
                                }}
                                className="bg-transparent no-border p-0"
                              >
                                <img src={close} alt="" />
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <Button
                  type="button"
                  onClick={() => {
                    setModal(true);
                  }}
                  className="bg-transparent no-border btn-add-address address-container mt-3 "
                >
                  <img className=" me-2" src={plus} alt="" />
                  Add new address
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Address;
