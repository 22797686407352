import React from "react";
import left from "../../assets/img/arrow_left.svg";
import right from "../../assets/img/arrow_right.svg";
var _ = require("lodash");
const Pagination = (props) => {
  const {
    active,
    size,
    step,
    previous,
    next,
    dispatch,
    handlePage,
    path,
  } = props;

  const activeHandler = (clickedActive) => {};
  const showingNumbers = step * 2 + 1;
  let startNumber = 2;
  let startArrayNumber = props.step;

  let needStartDots = false;
  let needEndDots = false;

  if (active > step) {
    startArrayNumber = active - step;

    needStartDots = active > step + startNumber ? true : false;
  }

  if (size > showingNumbers) {
    {
      needEndDots = size > active + step + 1 ? true : false;

      if (size < active + step + 1) {
        startArrayNumber = size - showingNumbers;
      }
    }
  }

  let contentNumber;

  return (
    <div className="container d-flex justify-content-center my-5 ">
      <div className="numbering-container py-2">
        <nav>
          <ul className="pagination m-0">
            {active > 1 ? (
              <li className="bg-transparent no-border moveUp">
                <a
                  className="mx-3"
                  aria-label="Previous"
                  onClick={() => {
                    activeHandler(active - 1);
                    dispatch(handlePage(previous));
                  }}
                >
                  <img src={left} alt="left" />
                </a>
              </li>
            ) : (
              <li className="bg-transparent no-border moveUp disabled">
                <a
                  className="mx-3"
                  aria-label="Previous"
                  onClick={() => {
                    activeHandler(active - 1);
                    dispatch(handlePage(previous));
                  }}
                >
                  <img src={left} alt="left" />
                </a>
              </li>
            )}
            {size > showingNumbers + startNumber ? (
              <React.Fragment>
                <li
                  onClick={(e) => {
                    activeHandler(e.currentTarget.textContent);
                    dispatch(
                      handlePage(`${path}?page=${e.currentTarget.textContent}`)
                    );
                  }}
                  className={`page-item d-flex align-items-center marginAut ${active ===
                    1 && "active"}`}
                >
                  <a
                    className={`mx-1 pagination_num link ${active === 1 &&
                      "active"}`}
                  >
                    1
                  </a>
                </li>

                {needStartDots && <span>...</span>}
                {_.times(showingNumbers, (i) => (
                  <li
                    key={i++}
                    {...(contentNumber = needStartDots
                      ? startArrayNumber
                      : startNumber)}
                    {...startNumber++}
                    {...startArrayNumber++}
                    className={`page-item d-flex align-items-center ${active ===
                      contentNumber && "active"}`}
                    onClick={(e) => {
                      activeHandler(e.currentTarget.textContent);
                      dispatch(
                        handlePage(
                          `${path}?page=${e.currentTarget.textContent}`
                        )
                      );
                    }}
                  >
                    <a
                      className={`mx-1 pagination_num link ${active ===
                        contentNumber && "active"}`}
                    >
                      {contentNumber}
                    </a>
                  </li>
                ))}
                {needEndDots && <span>...</span>}
                <li
                  className={`page-item d-flex align-items-center marginAut ${active ===
                    size && "active"}`}
                  onClick={(e) => {
                    activeHandler(e.currentTarget.textContent);
                    dispatch(
                      handlePage(`${path}?page=${e.currentTarget.textContent}`)
                    );
                  }}
                >
                  <a
                    className={`mx-1 pagination_num link ${active === size &&
                      "active"}`}
                  >
                    {size}
                  </a>
                </li>
              </React.Fragment>
            ) : (
              ((startArrayNumber = 1),
              _.times(size, (i) => (
                <li
                  key={i++}
                  className={`page-item d-flex align-items-center marginAut ${active ===
                    startArrayNumber && "active"}`}
                  onClick={(e) => {
                    activeHandler(e.currentTarget.textContent);
                    dispatch(
                      handlePage(`${path}?page=${e.currentTarget.textContent}`)
                    );
                  }}
                >
                  <a
                    className={`mx-1 pagination_num link ${active ===
                      startArrayNumber && "active"}`}
                  >
                    {startArrayNumber++}
                  </a>
                </li>
              )))
            )}
            {active < size ? (
              <li className="bg-transparent moveUp marginAut">
                <a
                  className="mx-3 "
                  aria-label="Next"
                  onClick={() => {
                    activeHandler(active + 1);
                    dispatch(handlePage(next));
                  }}
                >
                  <img src={right} alt="right" />
                </a>
              </li>
            ) : (
              <li className="bg-transparent moveUp disabled marginAut">
                <a
                  className="mx-3 "
                  aria-label="Next"
                  onClick={() => {
                    dispatch(handlePage(next));
                    activeHandler(active + 1);
                  }}
                >
                  <img src={right} alt="right" />
                </a>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
};
export default Pagination;
