import React from "react";
import { Container } from "react-bootstrap";

const PlaceOrder = ({ setModalOpenSuccess }) => {

  return (
   <>

   
<Container className="py-4 absolute-pos ">
      <div className="center paymentModal">
       <div className=" modal-dialog modal-dialog-centered">
         <div className="modal-content">
           <div className="modal-header">
             <button
               type="button"
               className="btn-close"
               data-bs-dismiss="modal"
               aria-label="Close"
               onClick={() => {
                setModalOpenSuccess(false);
               }}></button>
           </div>
           <div className="modal-body">
             <svg
               className="error-icon"
               xmlns="http://www.w3.org/2000/svg"
               width="27"
               height="27"
               viewBox="0 0 27 27">
               <defs>
                 <clipPath id="clip-path">
                   <rect width="27" height="27" fill="none" />
                 </clipPath>
               </defs>
               <g id="error" clip-path="url(#clip-path)">
                 <g id="error-2" data-name="error" transform="translate(1.344 1)">
                   <circle
                     id="Ellipse_183"
                     data-name="Ellipse 183"
                     cx="12.5"
                     cy="12.5"
                     r="12.5"
                     transform="translate(-0.344)"
                     fill="rgba(0,0,0,0)"
                     stroke="#43425d"
                     stroke-miterlimit="10"
                     strokeWidth="2"
                   />
                   <line
                     id="Line_24"
                     data-name="Line 24"
                     y2="10.556"
                     transform="translate(12.5 3.799)"
                     fill="none"
                     stroke="#43425d"
                     stroke-miterlimit="10"
                     strokeWidth="2"
                   />
                   <line
                     id="Line_25"
                     data-name="Line 25"
                     y2="2.701"
                     transform="translate(12.5 18.5)"
                     fill="none"
                     stroke="#43425d"
                     stroke-miterlimit="10"
                     strokeWidth="2"
                   />
                 </g>
               </g>
             </svg>

             <div className="error-message">
                  <p className="error-item">
                 
                  </p>
                </div>

             <div className="succes-text">
               <p>
               Your order has been placed !
                 <br />
                
               </p>
             </div>
           </div>
         </div>
       </div>
     </div>
  </Container>
 </>
  )
}

export default PlaceOrder