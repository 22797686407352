import React from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";

const YesNo = ({
  icon,
  title,
  msg,
  show,
  setShow,
  handleAction,
  currentId,
  msg2,
}) => {
  const dispatch = useDispatch();

  const handleYes = () => {
    dispatch(handleAction(currentId)).then(() => {
      window.location.reload(false);
    });
    setShow(false);
  };

  const handleNo = () => {
    setShow(false);
  };
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="yesNoModal"
      show={show}
      onHide={handleNo}
    >
      <Modal.Header closeButton className="yesNoModal-header"></Modal.Header>
      <Modal.Body className="yesNoModal-body">
        <div className="d-flex pb-3 ">
          <img src={icon} alt="logo" />
          <div className="ps-3">
            <div className="model-title"> {title}</div>
            <div className="gray10text mt-3">{msg}</div>
            <div className="gray10text mt-2">{msg2}</div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="yesNoModal-footer">
        <button
          type="reset"
          className="btn-secondary height-30"
          onClick={handleNo}
        >
          NO
        </button>
        <button
          type="button"
          // className={`${color}-round-btn  white10Text model-btn`}
          className="btn-secondary height-30"
          onClick={handleYes}
        >
          YES
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default YesNo;
