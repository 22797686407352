import React, { useState, useEffect } from "react";
import Header from "../shared/Header";
import AddUserAccount from "./modals/AddUserAccount";
import { Button, Container } from "react-bootstrap";
import plus from "../../assets/img/plus.svg";
import user from "../../assets/img/user.svg";
import { getUsers } from "../../features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { getStoreId } from "../../features/store/storeSlice";
import UserAccounts from "./UserAccounts";
import Loading from "../shared/Loading";
import { setFixed } from "../../features/store/storeSlice";

export default function UserAccountsList() {
  const dispatch = useDispatch();

  const { users, links, meta, isLoading, showAll } = useSelector(
    (store) => store.user
  );
  const { store_id } = useSelector((store) => store.store);
  const [allRows, setAllRows] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    dispatch(getStoreId());
  }, []);

  useEffect(() => {
    dispatch(getUsers(store_id));
  }, [store_id, showAll]);

  useEffect(() => {
    if (users) {
      setAllRows([]);
      let usersArray = [];
      users.map((user) => {
        return usersArray.push({
          Id: user.id,
          UserName: user.name,
          FirstName: user.first_name,
          LastName: user.last_name,
          Role: user.role,
        });
      });
      setAllRows(usersArray);
    }
  }, [isLoading, links, meta, users]);
  useEffect(() => {
    dispatch(setFixed(false));
  });
  return (
    <div className="scrollX scrolY">
      <div className="row-styleWidSet">
        <Header title="Settings" subtitle="Manage accounts" />
      </div>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          {users ? (
            <UserAccounts
              allRows={allRows}
              setModalOpen={setModalOpen}
              modalOpen={modalOpen}
            />
          ) : (
            <NoUser setModalOpen={setModalOpen} modalOpen={modalOpen} />
          )}
        </>
      )}
    </div>
  );
}

function NoUser({ setModalOpen, modalOpen }) {
  return (
    <Container>
      <div className="my-4 d-flex justify-content-between width-account ">
        <div className="section-title ps-lg-4">User Accounts</div>

        <Button
          className={`${
            modalOpen ? "d-none" : "btn-setting align-items-center"
          }`}
          onClick={() => {
            setModalOpen(true);
          }}
        >
          <img className="margin-right-new" src={plus} alt="+" /> Add user
          accounts
        </Button>
      </div>

      <div className="form-index">
        <div className="modal-index px-3 ">
          {modalOpen && <AddUserAccount setOpenModal={setModalOpen} />}
        </div>
        <div className="py-4 px-4 d-flex user-txt">
          <div className="user-grp">
            <img src={user} alt="user" />
          </div>
          <div>
            <p className="user-msg width-account">
              No additional users have been added to access this portal
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
}
