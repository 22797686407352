import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import Image from "../../../assets/img/invoice-summary.png";
import { useDispatch, useSelector } from "react-redux";
import { getOrder } from "../../../features/order/orderSlice";
import { useParams } from "react-router-dom";
import moment from "moment";
export default function SalesOrderPDF() {
  const { orderId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getOrder({
        id: orderId,
        from: from,
        to: to,
        sort: sort,
        orderby: orderby,
      })
    );
  }, []);

  const {
    style_number,
    sort,
    orderby,
    name,
    wear_date,
    colorLabel,
    sizeLabel,
    estimated_shipping_date,
    from,
    to,
    purchase_order_number,
    type,
    formatted_price,
    sales_order_number,
    created_at,
    msrp,
  } = useSelector((store) => store.order);
  return (
    <div className="summary-container">
      <img className="Homeimage" src={Image} alt="backgroundImage" />

      <div className="row px-4 py-4 header-summary ">
        <div className="col-lg ">
          <div className="logoFont-small d-flex ">
            <div className="circle-small circle-summary  me-3"></div>
            <p className="txt-summary py-3">Elissar Bridal</p>
          </div>
        </div>
        <div className="col-lg py-4">
          <p className="sales-order">Sales Order</p>
        </div>
      </div>

      <hr className="hr-summary " />

      <div className="row px-4 py-4 info-summary ">
        <div className="col-lg ">
          <div className="info-summary-name">
            Elissar Bridal
            <br />
            2140 E Winstoh Rd. <br /> Anaheim CA 92896 <br /> 725-582-5888{" "}
            <br />
            www.elissarbridal.com
          </div>
        </div>
        <div className="col-lg pb-4">
          <div className="row ">
            <div className="col-lg-4">
              <div className="info-summary-sales">
                Date <br />
                Sales Order #<br /> Store Name
                <br /> PO # <br />
                Type of Order
                <br /> Approximate Ship Date
              </div>
            </div>
            <div className="col-lg">
              <div className="info-summary-nbr">
                {moment(created_at).format("MM/DD/YYYY")}
                <br /> {sales_order_number}
                <br /> Written Name here
                <br />
                {purchase_order_number}
                <br />
                {type}
                <br /> {estimated_shipping_date}
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="hr-summary "></hr>

      <div className="row px-4 py-4 ship-summary ">
        <div className="col-lg-3 ">
          <span className="bill-summary"> Bill to</span>

          <div className="bill-info">
            Jenna in White 2685 Coolidge Hwy <br />
            Berkley MI 127563
          </div>
        </div>

        <div className="col-lg ">
          <span className="bill-summary"> Ship to</span>

          <div className="bill-info">
            enna in White 4260 Pemberton
            <br /> Circle Brighton MI 48118
          </div>
        </div>

        <Container className="px-0 border-summary-margin">
          <div className="border border-grid-summary  py-4  ">
            <div className="row    align-items-center ps-5 py-3  ">
              <div className="col-lg   summary-txt   ">STYLE NUM.</div>
              <div className="col-lg    summary-txt  "> DRESS NAME </div>
              <div className="col-lg  summary-txt   ">COLOR</div>
              <div className="col-lg   summary-txt  ">SIZE </div>

              <div className="col-lg   summary-txt ">MSRP</div>
              <div className="col-lg  summary-txt  ">WEAR DATE</div>
              <div className="col-lg summary-txt   ">AMOUNT</div>
            </div>
            <hr className="hr-summary-border" />

            <div className="row  align-items-center ps-5 py-3 ">
              <div className="col-lg  summary-sol">{style_number}</div>
              <div className="col-lg  summary-sol">{name}</div>
              <div className="col-lg   summary-sol">{colorLabel}</div>
              <div className="col-lg   summary-sol">{sizeLabel}</div>

              <div className="col-lg  summary-sol  ">{msrp}</div>
              <div className="col-lg   summary-sol  ">
                {moment(wear_date).format("MM/DD/YYYY")}
              </div>
              <div className="col-lg  summary-sol  ">{formatted_price}</div>
            </div>
            <hr className="hr-summary-border " />

            <div className="modal-total-summary-sales">
              <div className="border-total  ">
                <div className="  py-5  pe-5 ps-2 d-flex justify-content-between ">
                  <div className=" col total-txt  ps-2">TOTAL</div>
                  <div className="col total-nbr">{formatted_price}</div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
