import React from "react";
import { Form } from "react-bootstrap";

const FormInput = ({
  type,
  name,
  value,
  labelText,
  placeholder,
  onChange,
  onBlur,
  className,
  min,
  maxLength,
  disabled,
  id,
}) => {
  return (
    <>
      <Form.Label htmlFor={name} className="label-text ms-0 pt-2 mb-0">
        {labelText || name}
      </Form.Label>

      <Form.Control
        id={id}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        className={className ? className : "form-control"}
        placeholder={placeholder}
        onBlur={onBlur}
        min={min}
        maxLength={maxLength}
        disabled={disabled || false}
      />
    </>
  );
};
export default FormInput;
