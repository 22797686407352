import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getStoreFromLocalStorage,
  getStoreIdFromLocalStorage,
  getStaxCustomerIdFromLocalStorage,
  addStaxCustomerIdToLocalStorage,
} from "../../utils/localStorage";

import { getStoreThunk } from "./storeThunk";

const initialState = {
  isLoading: false,
  isSidebarOpen: false,
  store_id: getStoreIdFromLocalStorage(),
  store: getStoreFromLocalStorage(),
  endsession: false,
  stax_customer_id: "",
  salesOrder: false,
  invoice: false,
  invoiceSummary: false,
  address: false,
  fixed: false,
 transaction: false,
 storeNew: '',
 storeName:'',
};

export const getStore = createAsyncThunk(
  "user/getStore",
  async (store, thunkAPI) => {
    return getStoreThunk("/api/stores/" + store.id, thunkAPI);
  }
);

const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    reducerexample: (state) => {
      //state.isSidebarOpen = !state.isSidebarOpen;
    },
    getStoreId: (state, action) => {
      state.store_id = getStoreIdFromLocalStorage();
    },
    setSalesOrder: (state, action) => {
      state.salesOrder = action.payload;
    },
    setInvoice: (state, action) => {
      state.invoice = action.payload;
    },
    setInvoiceSummary: (state, action) => {
      state.invoiceSummary = action.payload;
    },
  setAddress: (state, action) => {
      state.address = action.payload;
    },
    setTransaction: (state, action) => {
      state.transaction = action.payload;
    },
    setFixed: (state, action) => {
      state.fixed = action.payload;
    },
    
    getStaxCustomerId: (state, action) => {
      state.stax_customer_id = getStaxCustomerIdFromLocalStorage();
    },
   
    handleChange: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    handleSort: (state, { payload }) => {
      state.orderby = payload.value;
      if (payload.sort === "desc") {
        state.sort = "asc";
      }
      if (payload.sort === "asc") {
        state.sort = "desc";
      }
    },
  },
  extraReducers: {
    [getStore.pending]: (state) => {
      state.isLoading = true;
    },
    [getStore.fulfilled]: (state, { payload }) => {
      state.storeName= payload.name;
      state.isLoading = false;
      state.storeNew = payload;
      addStaxCustomerIdToLocalStorage(payload.stax_customer_id);
      state.stax_customer_id = getStaxCustomerIdFromLocalStorage();
    },
    [getStore.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },
  },
});

export const {
  handleSelectedStores,
  clearStoresValues,
  handleSearch,
  handleSort,
  handleChange,
  getStoreId,
  getStaxCustomerId,
  setSalesOrder,
  setInvoice,
  setAddress,
   setTransaction,
   setFixed,
   setInvoiceSummary
} = storeSlice.actions;
export default storeSlice.reducer;
