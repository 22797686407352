import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getPaymentFactoryThunk,
  getPaymentThunk,
  createPaymentThunk,
  updatePaymentThunk,
  removePaymentMethodThunk,
  exportPaymentFactoryThunk,
  getPaymentMethodsThunk,
} from "./paymentThunk";

const initialFiltersState = {
  search: "",
  searchStatus: "all",
  searchType: "all",
  sortOptions: ["latest", "oldest", "a-z", "z-a"],
  endsession: false,
  from: "",
  to: "",
};

const initialState = {
  isLoading: false,
  payments: null,
  payment: null,
  links: null,
  link: null,
  meta: null,
  sort: "desc",
  orderby: "id",
  currentId: null,
  date: null,
  amount: null,
  paymentMethods: [],
  paymentM: null,
  deletedPaymentId: "",
  rerender: false,
  ...initialFiltersState,
  selectedPayment: '',
  PaymentChosen: false,
  PaymentCod: false
};

export const getPaymentMethods = createAsyncThunk(
  "payment/getPaymentMethods",
  async (store_id, thunkAPI) => {
    return getPaymentMethodsThunk(
      `/api/stores/${store_id}/payment-methods`,
      thunkAPI
    );
  }
);

export const getFactoryPayments = createAsyncThunk(
  "payment/getFactoryPayments",
  async (payment, thunkAPI) => {
    return getPaymentFactoryThunk(
      `/api/factories/${payment.id}/payments?order_dir=${payment.sort}&order_by=${payment.orderby}&from=${payment.from}&to=${payment.to}`,
      thunkAPI
    );
  }
);

export const getFactoryPaymentsPaginate = createAsyncThunk(
  "payment/getFactoryPaymentsPaginate",
  async (link, thunkAPI) => {
    return getPaymentFactoryThunk(link, thunkAPI);
  }
);

export const getPayment = createAsyncThunk(
  "payment/getPayment",
  async (id, thunkAPI) => {
    return getPaymentThunk("/api/payment-methods/" + id, thunkAPI);
  }
);

export const createPayment = createAsyncThunk(
  "payment/createPayment",
  createPaymentThunk
);

export const updatePayment = createAsyncThunk(
  "payment/updatePayment",
  updatePaymentThunk
);

export const removePaymentMethod = createAsyncThunk(
  "payment/removePaymentMethod",
  async (id, thunkAPI) => {
    return removePaymentMethodThunk("/api/payment-methods/" + id, thunkAPI);
  }
);

export const exportFactoryPayment = createAsyncThunk(
  "payment/exportFactoryPayment",
  async (payment, thunkAPI) => {
    return exportPaymentFactoryThunk(
      `/api/factories/${payment.id}/payments/export`,
      {
        responseType: "blob",
      },
      thunkAPI
    );
  }
);

const paymentSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    handleSelectedPayment: (state, payload) => {
      state.selectedPayment = payload.payload;
    },
    handlePaymentEdit: (state, { payload }) => {
      state.currentId = payload;
    },
    handleChange: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    handleExport: (state, { payload }) => {
      const link = document.createElement("a");
      link.href = "...";
      document.body.appendChild(link);
      link.click();
      // link.parentNode?.removeChild(link);

    },
 
    handleSort: (state, { payload }) => {
      state.orderby = payload.value;
      if (payload.sort === "desc") {
        state.sort = "asc";
      }
      if (payload.sort === "asc") {
        state.sort = "desc";
      }
    },
    setPayment: (state, { payload }) => {
      state.PaymentChosen = payload;
    },
    setpaymentCOD: (state, { payload }) => {
      state.PaymentCod = payload;
    },
    
    handleFilter: (state, { payload }) => {
      if (payload.from) {
        state.from = payload.from;
      }
      if (payload.to) {
        state.to = payload.to;
      }
    },
 
    clearPaymentValues: (state) => {
      state.payment = [];
    },

    handleDelete: (state, { payload }) => {
      state.deletedPaymentId = payload;
      
    },
    refreshPage: (state, { payload }) => {
      state.rerender = payload;
    },
    handleIds: (state, { payload }) => {
      state.paymentM = payload;
    },
  },
  extraReducers: {
    [getFactoryPayments.pending]: (state) => {
      state.isLoading = true;
    },
    [getFactoryPayments.fulfilled]: (state, { payload }) => {
      const { payments, links, meta } = payload;
      state.isLoading = false;
      state.payments = payments;
      state.links = links;
      state.meta = meta;
    },
    [getFactoryPayments.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },
    [getPaymentMethods.pending]: (state) => {
      state.isLoading = true;
    },
    [getPaymentMethods.fulfilled]: (state, { payload }) => {
      console.log('l',payload);
      state.isLoading = false;
      state.paymentMethods = payload.data;
      
    },
    [getPaymentMethods.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [getFactoryPaymentsPaginate.pending]: (state) => {
      state.isLoading = true;
    },
    [getFactoryPaymentsPaginate.fulfilled]: (state, { payload }) => {
      const { payments, links, meta } = payload;
      state.isLoading = false;

      state.payments = payments;
      state.links = links;
      state.meta = meta;
    },
    [getFactoryPaymentsPaginate.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [getPayment.pending]: (state) => {
      state.isLoading = true;
    },
    [getPayment.fulfilled]: (state, { payload }) => {
      const { data } = payload;
      state.isLoading = false;
      state.payment = data.data;
    },
    [getPayment.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [createPayment.pending]: (state) => {
      state.isLoading = true;
    },
    [createPayment.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isLoading = false;
      state.endsession = true;
      
    },
    [createPayment.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [exportFactoryPayment.pending]: (state) => {
      state.isLoading = true;
    },
    [exportFactoryPayment.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [exportFactoryPayment.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [updatePayment.pending]: (state) => {
      state.rerender = false;
      state.isLoading = true;
    },
    [updatePayment.fulfilled]: (state, payload) => {
      state.isLoading = false;
      state.rerender = true;
    },
    [updatePayment.rejected]: (state, payload) => {
      state.isLoading = false;
    },

    [removePaymentMethod.pending]: (state) => {
      state.isLoading = true;
      state.rerender = false;
    },
    [removePaymentMethod.fulfilled]: (state, { payload }) => {

      console.log('remove payment method')
      state.isLoading = false;
      state.rerender = true;

    },
    [removePaymentMethod.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
  },
});

export const {
  handleChange,
  clearPaymentValues,
  handlePaymentEdit,
  setEditPayment,
  isEditing,
  handleSave,
  handleSearch,
  handleExport,
  handleSort,
  handleFilter,
  handleDelete,
  refreshPage,
  handleIds,
  setPayment,
  setpaymentCOD
} = paymentSlice.actions;
export default paymentSlice.reducer;
