import React from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { pay } from "../../../features/invoice/invoiceSlice";
import { useNavigate } from "react-router-dom";

export default function OrderSummary({ data, CustomToggle }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <div className="container Content-box">
        <Card className="card mb-2 pe-5 ">
          <Card.Header className="bg-white">
            <CustomToggle
              eventKey="1"
              title="Order Summary"
              subtitle="You are one step away, Please review order"
            ></CustomToggle>
          </Card.Header>

          <Accordion.Collapse eventKey="1">
            <Card.Body className="d-flex row ms-5 g-0">
              <div className="col-lg-11">
                <hr />
              </div>

              <div className="col-lg-11 col-md-4 col-11 address-container pb-4 mt-3">
                <div className="d-flex justify-content-between pt-2">
                  <div className=" row col-12 m-4 mb-0">
                    <div className="col">
                      <div className="invoices-titles">invoice #</div>
                      <div className="paragraph">Invoice #{data.id}</div>
                    </div>

                    <div className="col">
                      <div className="invoices-titles">deposits subtotal</div>
                      <div className="paragraph">-</div>
                    </div>
                    <div className="col">
                      <div className=" invoices-titles">credits subtotal</div>
                      <div className="paragraph">-</div>
                    </div>
                    <div className="col">
                      <div className=" invoices-titles"> payment total</div>
                      <div className="order-summary-cost">{data.price}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-11 d-flex flex-row-reverse">
                <Button
                  className="btn-secondary px-5  my-3 "
                  onClick={() => {
                    dispatch(pay(data.id));
                    navigate("/invoices");
                  }}
                >
                  Place Order
                </Button>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </div>
    </>
  );
}
