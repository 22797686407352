import customFetch, {
  checkForUnauthorizedResponse,
} from "../../utils/axios";
import fileDownload from "js-file-download";

export const getPaymentMethodsThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.get(url);
    return resp;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

export const getPaymentFactoryThunk = async (url, payment, thunkAPI) => {
  try {
    const resp = await customFetch.get(url, payment);
    return resp;
  } catch (error) {
    console.log(error.message);
    return thunkAPI.rejectWithValue(error.message);
  }
};

export const exportPaymentFactoryThunk = async (url, payment, thunkAPI) => {
  try {
    const resp = await customFetch.get(url, payment);
    fileDownload(resp.data, "payments.xlsx");
    return "Payments exported successfully";
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

export const getPaymentThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.get(url);

    return resp;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const createPaymentThunk = async (payment, thunkAPI) => {
  
  try {
    const resp = await customFetch.post("/api/factory-payments", payment);
    //thunkAPI.dispatch(clearValues());
    console.log('resp.data.msg',resp.data.msg)
    return resp.data.msg;
  } catch (error) {
    console.log(error.message);
    return error.message;
  }
};

export const updatePaymentThunk = async ({ id, payment }, thunkAPI) => {
  try {console.log('payment',payment)
    const resp = await customFetch.put(`/api/payment-methods/${id}`, payment);
    return resp;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const removePaymentMethodThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.delete(url);
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
