import React from "react";
import Select from "react-select";
const DropDown = ({
  name,
  value,
  labelText,
  placeholder,
  onChange,
  options,
}) => {
  return (
    <>
      <label htmlFor={name} className="label-text ms-0 pt-2 mb-0">
        {labelText || name}
      </label>
      <Select
        name={name}
        options={options}
        placeholder={placeholder}
        onChange={onChange}
        value={value || ""}
      />
    </>
  );
};
export default DropDown;
