import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


import {
  getStoreInvoiceThunk,
  getInvoiceThunk,
  exportInvoiceThunk,
  getStoreTransactionThunk,
  payThunk
} from './invoiceThunk';
const initialFiltersState = {
  search: '',
  from: '',
  to: '',
  sort: 'desc',
  orderby: 'id',
  factory_status: '',
  from_h:'',
  to_h:'',
  orderby_h:'id'
};

const initialState = {
  links_h:null,
  meta_h:null,
  transactions:null,
  orderId: null,
  isLoading: false,
  invoices: null,
  links: null,
  meta: null,
  status: null,
  endsession: false,
  cancelOrderId: null,
invoiceNumber: null,
  ...initialFiltersState,
  price: '',
  packageId: '',
  address: '',
  shippingCost:'',
};

export const getStoreInvoice = createAsyncThunk(
  'invoice/getStoreInvoice',
  async (invoices, thunkAPI) => {
    console.log('invoices.factory_status',invoices.factory_status)
    if (invoices.factory_status) {
      return getStoreInvoiceThunk(
        `api/stores/${invoices.id}/invoices?order_dir=${invoices.sort}&order_by=${invoices.orderby}&from=${invoices.from}&to=${invoices.to}&status[]=${invoices.factory_status}`,
        thunkAPI
      );
    }
  
    return getStoreInvoiceThunk(
      `api/stores/${invoices.id}/invoices?order_dir=${invoices.sort}&order_by=${invoices.orderby}&from=${invoices.from}&to=${invoices.to}`,
      thunkAPI
    );
  }
);

export const getStoreTransaction = createAsyncThunk(
  'invoice/getStoreTransaction',
  async (invoices, thunkAPI) => {
    if (invoices.factory_status) {
      return getStoreTransactionThunk(
        `/api/stores/${invoices.id}/transactions?order_dir=${invoices.sort}&order_by=${invoices.orderby_h}&from=${invoices.from}&to=${invoices.to}`,
        thunkAPI
      );
    }
  
    return getStoreTransactionThunk(
      `/api/stores/${invoices.id}/transactions?order_dir=${invoices.sort}&order_by=${invoices.orderby_h}&from=${invoices.from}&to=${invoices.to}`,
      thunkAPI
    );
  }
);

export const getInvoice = createAsyncThunk(
  'invoice/getInvoice',
  async (invoices, thunkAPI) => {
    return getInvoiceThunk('api/stores/' + invoices.id, thunkAPI);
  }
);
export const pay = createAsyncThunk(
  'invoice/pay',
  async (invoices, thunkAPI) => {
    console.log(invoices)
    return payThunk(`/api/invoices/${invoices}/pay`, thunkAPI);
  }
);

export const exportInvoice = createAsyncThunk(
  'invoice/exportInvoice',
  async (invoices, thunkAPI) => {
    return exportInvoiceThunk(
      `/api/stores/${invoices.id}/invoices/export?order_type=${invoices.type}`,
      {
        responseType: 'blob',
      },
      thunkAPI
    );
  }
);

export const exportTransactions = createAsyncThunk(
  'invoice/exportTransactions',
  async (invoices, thunkAPI) => {
    return exportInvoiceThunk(
      `/api/stores/${invoices.id}/transactions/export?order_type=${invoices.type}`,
      {
        responseType: 'blob',
      },
      thunkAPI
    );
  }
);


export const getInvoicePaginate = createAsyncThunk(
  'invoice/getInvoicePaginate',
  async (link, thunkAPI) => {
    return getStoreInvoiceThunk(link, thunkAPI);
  }
);

const InvoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    handleSort: (state, { payload }) => {
      if(payload.value){
        state.orderby = payload.value;
      }
      
      if(payload.val){
        state.orderby_h = payload.val;
      }
      if (payload.sort === 'desc') {
        state.sort = 'asc';
      }
      if (payload.sort === 'asc') {
        state.sort = 'desc';
      }
    },
    handleCancel: (state, { payload }) => {
      state.cancelOrderId = payload;
    },
    handleFilter: (state, { payload }) => {
      console.log('testing filtering',payload)
      if (payload.status) {
        state.status = payload.status;
      }
      if (payload.from) {
        state.from = payload.from;
      }
      if (payload.to) {
        state.to = payload.to;
      }
      if (payload.from_h) {
        state.from_h = payload.from_h;
      }
      if (payload.to_h) {
        state.to_h = payload.to_h;
      }
      if (payload.factory_status) {
        state.factory_status = payload.factory_status;
      }
    },
    setInvoiceData: (state, { payload }) => {
      state.invoiceNumber = payload.number;
      state.packageId = payload.id;
      state.address = payload.address;
      state.shippingCost = payload.shippingCost;
    },
    
    handleSearch: (state, { payload }) => {
      state.search = payload.search;
    },

    clearInvoiceValues: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: {
    [getStoreInvoice.pending]: (state) => {
      state.isLoading = true;
    },
    [getStoreInvoice.fulfilled]: (state, { payload }) => {
      const { invoices, meta, links } = payload;
      console.log('payload',)
      state.isLoading = false;
      state.invoices = invoices;
      state.links = links;
      state.meta = meta;
    },
    [getStoreInvoice.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },

    [getInvoicePaginate.pending]: (state) => {
      state.isLoading = true;
    },
    [getInvoicePaginate.fulfilled]: (state, { payload }) => {
      const { order, links, meta } = payload;
      state.isLoading = false;
      state.order = order;
      state.links = links;
      state.meta = meta;
    },
    [getInvoicePaginate.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },

    [exportInvoice.pending]: (state) => {
      state.isLoading = true;
    },
    [exportInvoice.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [exportInvoice.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },

    [getInvoice.pending]: (state) => {
      state.isLoading = true;
    },
    [getInvoice.fulfilled]: (state, { payload }) => {
      // const { order } = payload;
      // console.log('order',order)
      state.isLoading = false;
      state.orders = payload;
    },
    [getInvoice.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },
    [getStoreTransaction.pending]: (state) => {
      state.isLoading = true;
    },
    [getStoreTransaction.fulfilled]: (state, { payload }) => {

      state.isLoading = false;
      state.links_h = payload.links;
      state.meta_h = payload.meta;
      state.transactions = payload.transactions;

    },
    [getStoreTransaction.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },
    [pay.pending]: (state) => {
      state.isLoading = true;
    },
    [pay.fulfilled]: (state, { payload }) => {
      // const { order } = payload;
      // console.log('order',order)
      state.isLoading = false;
      console.log('fulfilled',payload)
    },
    [pay.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
      console.log('error',payload)
    },
  },
});

export const {
  handleSort,
  clearInvoiceValues,
  handleCancel,
  handleFilter,
  handleViewImage,
  handleSearch,
setInvoiceData
} = InvoiceSlice.actions;
export default InvoiceSlice.reducer;
