import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import plus from "../../assets/img/plus.svg";
import copy from "../../assets/img/copy.svg";
import close from "../../assets/img/close.svg";
import YesNo from "../shared/modals/YesNo";
import { removeUser, setUserId } from "../../features/user/userSlice";
import error from "../../assets/img/error.svg";
import { useDispatch } from "react-redux";
import AddUserAccount from "./modals/AddUserAccount";
import EditUserAccount from "./modals/EditUserAccount";
export default function UserAccounts({ allRows, setModalOpen, modalOpen }) {
  const dispatch = useDispatch();
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [cancelID, setCancelID] = useState([]);
  const [modalEditOpen, setModalEditOpen] = useState(false);

  const handleDelete = (Id) => {
    setDeleteModalShow(true);
    setCancelID(Id);
  };
  return (
    <Container className="p-0 scrollX scrolY ">
      <div className="container my-4 pe-5 d-flex justify-content-between  ">
        <div className="position-absolute modal-setting ">
          {modalEditOpen && (
            <EditUserAccount setModalEditOpen={setModalEditOpen} />
          )}
        </div>
        <div className="modal-index pe-5 marginModal">
          {modalOpen && <AddUserAccount setOpenModal={setModalOpen} />}
        </div>
        {deleteModalShow && (
          <YesNo
            show={deleteModalShow}
            setShow={setDeleteModalShow}
            icon={error}
            title="Delete this user?"
            msg="Are you sure you want to delete this user?"
            color="red"
            child={false}
            handleAction={removeUser}
            currentId={cancelID}
          />
        )}

        <div className="section-title widthAc">User Accounts</div>
        <Button
          className="btn-setting align-items-center btnTabRight"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          <img className="margin-right-new" src={plus} alt="+" /> Add user
          accounts
        </Button>
      </div>

      <div className="container pe-5 width-account">
        <div className="row grid-header ps-lg-4 widtSet">
          <div className="col-lg-2 col-md-3 col-sm-12 width-xs settings-text mrgnstart pe-0">
            User Name
            <svg
              className="grid-chevron"
              xmlns="http://www.w3.org/2000/svg"
              width="11.9"
              height="7"
              viewBox="0 0 11.9 7"
            >
              <path
                id="chevron"
                d="M7.332,5.949a.66.66,0,0,1-.119.385l-3.5,5.25a.7.7,0,0,1-.581.315h-2.1a.7.7,0,0,1-.7-.7.66.66,0,0,1,.119-.385L3.692,5.949.451,1.084A.66.66,0,0,1,.332.7a.7.7,0,0,1,.7-.7h2.1a.7.7,0,0,1,.581.315l3.5,5.25a.66.66,0,0,1,.119.385Zm0,0"
                transform="translate(11.899 -0.332) rotate(90)"
                fill="#b8998d"
              />
            </svg>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-12 width-xs settings-text Marg">
            First Name
            <svg
              className="grid-chevron"
              xmlns="http://www.w3.org/2000/svg"
              width="11.9"
              height="7"
              viewBox="0 0 11.9 7"
            >
              <path
                id="chevron"
                d="M7.332,5.949a.66.66,0,0,1-.119.385l-3.5,5.25a.7.7,0,0,1-.581.315h-2.1a.7.7,0,0,1-.7-.7.66.66,0,0,1,.119-.385L3.692,5.949.451,1.084A.66.66,0,0,1,.332.7a.7.7,0,0,1,.7-.7h2.1a.7.7,0,0,1,.581.315l3.5,5.25a.66.66,0,0,1,.119.385Zm0,0"
                transform="translate(11.899 -0.332) rotate(90)"
                fill="#b8998d"
              />
            </svg>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-12 width-xs settings-text Marg">
            Last Name
            <svg
              className="grid-chevron"
              xmlns="http://www.w3.org/2000/svg"
              width="11.9"
              height="7"
              viewBox="0 0 11.9 7"
            >
              <path
                id="chevron"
                d="M7.332,5.949a.66.66,0,0,1-.119.385l-3.5,5.25a.7.7,0,0,1-.581.315h-2.1a.7.7,0,0,1-.7-.7.66.66,0,0,1,.119-.385L3.692,5.949.451,1.084A.66.66,0,0,1,.332.7a.7.7,0,0,1,.7-.7h2.1a.7.7,0,0,1,.581.315l3.5,5.25a.66.66,0,0,1,.119.385Zm0,0"
                transform="translate(11.899 -0.332) rotate(90)"
                fill="#b8998d"
              />
            </svg>
          </div>

          <div className="col-lg-2 col-md-3 col-sm-12 width-xs settings-text Marg ">
            Role
          </div>
          <div className="col-lg-2 col-md-3 col-sm-12 width-xs settings-text width-date ">
            Action
          </div>
        </div>
        {allRows.map((row) => {
          const { Id, UserName, FirstName, LastName, Role } = row;
          return (
            <div
              className="grid-row-border ps-lg-4 py-3 settings-width-row"
              key={Id}
            >
              <div key={Id} className="row align-items-center">
                <div className="col-lg-2 col-md-3 col-12 grid-cell-body settings-width ps-4 fontSize">
                  {UserName}
                </div>

                <div className="col-lg-2 col-md-3 col-12  grid-cell-body settings-width fontSize">
                  {FirstName}
                </div>
                <div className="col-lg-2 col-md-3 col-12  grid-cell-body settings-width fontSize ">
                  {LastName}
                </div>
                <div className="col-lg-2 col-md-3 col-12 py-1 grid-cell-body settings-width fontSize marginAc">
                  {Role}
                </div>
                <div className="col-lg-2 col-md-3 settings-width btnWidthSet widthButnns">
                  <Button
                    className="bg-transparent no-border p-0 margin-right-2 me-1"
                    onClick={() => {
                      setModalEditOpen(true);
                      dispatch(setUserId(Id));
                    }}
                  >
                    <img src={copy} alt="edit" className="me-1" />
                  </Button>
                  <Button
                    className="bg-transparent no-border p-0 "
                    onClick={() => handleDelete(Id)}
                  >
                    <img src={close} alt="close" />
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
}
