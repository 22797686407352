import React, { useEffect, useState } from "react";
import { Button, Container, Tabs, Tab } from "react-bootstrap";
import BankAccount from "./BankAccount";
import Credit from "./Credit";
import Header from "../../../../shared/HeaderModal";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from "../../../../../features/country/countrySlice";
import { getStates } from "../../../../../features/state/stateSlice";

function EditPaymentMethodModal({
  setModalOpen,
  setRefreshPage,
  paymentMethod,
  selectedId,
}) {
  const dispatch = useDispatch();
  const { countries } = useSelector((store) => store.country);
  const { states } = useSelector((store) => store.state);
  const { stax_customer_id } = useSelector((store) => store.store);
  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [staxId, setStaxId] = useState([]);

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getStates());
  }, []);

  useEffect(() => {
    setStaxId(stax_customer_id);
  }, [stax_customer_id]);

  useEffect(() => {
    if (states) {
      let stateList = [];
      Object.keys(states).map((key, i) =>
        stateList.push({
          value: Object.keys(states)[i],
          label: states[Object.keys(states)[i]],
        })
      );
      setStateList(stateList);
    }
    if (countries) {
      let list = [];
      Object.keys(countries).map((key, i) =>
        list.push({
          value: Object.keys(countries)[i],
          label: countries[Object.keys(countries)[i]],
        })
      );
      setCountryList(list);
    }
  }, [states, countries]);
  return (
    <Container className="py-4 absolute-pos z-index2">
      <div className="center paymentModal">
        <div className=" modal-dialog ms-0 mt-0 form-dialog">
          <div className="modal-content auto-modal">
            <div className="modal-header ms-2">
              <Header
                title="Edit Payment payment"
                subtitle="Please provide us with the credit/debit information along with the address"
              />
              <Button
                type="button"
                className="btn-close me-1 btn-hover"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setModalOpen(false);
                }}
              ></Button>
            </div>

            <Tabs id="uncontrolled-tab-example" className="mb-3 ms-4 me-4">
              {paymentMethod === "card" ? (
                <Tab eventKey="card" title="CREDIT/DEBIT CARD">
                  {console.log(stateList)}
                  <Credit
                    stateList={stateList}
                    countryList={countryList}
                    stax_customer_id={staxId}
                    setModalOpen={setModalOpen}
                    setRefreshPage={setRefreshPage}
                    selectedId={selectedId}
                  />
                </Tab>
              ) : paymentMethod === "bank" ? (
                <Tab eventKey="bank" title="BANK ACCOUNT">
                  {console.log(stateList)}

                  <BankAccount
                    stateList={stateList}
                    countryList={countryList}
                    stax_customer_id={staxId}
                    setModalOpen={setModalOpen}
                    setRefreshPage={setRefreshPage}
                    selectedId={selectedId}
                  />
                </Tab>
              ) : (
                <Tab eventKey="Error" title="Error"></Tab>
              )}
            </Tabs>
          </div>
        </div>
      </div>
    </Container>
  );
}
export default EditPaymentMethodModal;
