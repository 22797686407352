import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../features/user/userSlice";
import { NavLink } from "react-router-dom";
import LoginLogo from "../../assets/img/LoginLogo.svg";
import Logout from "../../assets/img/Logout.svg";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import FormInput from "../shared/FormInput";
import Select from "react-select";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { createStore } from "../../features/retailers/retailersSlice";
import { getCountries } from "../../features/country/countrySlice";
import { getStates } from "../../features/state/stateSlice";

function AddStore() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { countries } = useSelector((store) => store.country);
  const { states } = useSelector((store) => store.state);
  const { storeId } = useSelector((store) => store.stores);
  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  useEffect(() => {
    dispatch(getCountries());
    dispatch(getStates());
  }, []);

  useEffect(() => {
    window.localStorage.setItem("storeId", storeId);
  }, [storeId]);

  useEffect(() => {
    if (states) {
      let stateList = [];
      Object.keys(states).map((key, i) =>
        stateList.push({
          value: Object.keys(states)[i],
          label: states[Object.keys(states)[i]],
        })
      );
      setStateList(stateList);
    }
    if (countries) {
      let list = [];
      Object.keys(countries).map((key, i) =>
        list.push({
          value: Object.keys(countries)[i],
          label: countries[Object.keys(countries)[i]],
        })
      );
      setCountryList(list);
    }
  }, [states, countries]);

  const validation = useFormik({
    initialValues: {
      name: "",
      last: "",
      number: "",
      email: "",
      link: "",
      store: "",
      selected_country: "",
      city: "",
      address: "",
      zip: "",
      street: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This Field is required!"),
      last: Yup.string().required("This Field is required!"),
      number: Yup.string()
        .required("This Field is required!")
        // .typeError("This Field must be only numbers!")
        .min(10, "This field must be a digit between 10 and 15 digits.")
        .max(15, "This field must be a digit between 10 and 15 digits."),
      email: Yup.string()
        .email("The email you entered is invalid!")
        .required("This Field is required!"),
      link: Yup.string().matches(
        /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
        "Please enter a valid URL!"
      ),
      store: Yup.string().required("This Field is required!"),
      selected_country: Yup.object().required("This Field is required!"),
      city: Yup.string().required("This Field is required!"),
      // selected_state: Yup.object().required("This Field is required!"),
      address: Yup.string().required("This Field is required!"),
      zip: Yup.string()
        .required("This Field is required!"),
      street: Yup.string().required("This Field is required!"),
    }),
    onSubmit: (values) => {
      dispatch(
        createStore({
          first_name: values.name,
          last_name: values.last,
          email: values.email,
          phone: values.number.replace(/\D/g, ""),
          website: values.link,
          name: values.store,
          country: values.selected_country.value,
          city: values.city,
          state: values.selected_state.value,
          street: values.street,
          zip: values.zip,
          address: values.address,
        })
      ).then(() => {
        navigate("/store-payment");
      });
    },
  });
  return (
    <>
      <div className="container pe-0 ps-0">
        <div className="d-flex justify-content-between mt-5">
          <div>
            <img src={LoginLogo} alt="logo" className="img-size ms-2" />
          </div>

          <div className=" d-flex">
            <div className="logout-logo">
              <img src={Logout} alt="logo" />
            </div>
            <NavLink to="/login-new" className={"text-decoration-none"}>
              <p
                className="sign-text ms-3 text-decoration-none"
                onClick={(e) => {
                  dispatch(logoutUser());
                }}
              >
                SIGN OUT
              </p>
            </NavLink>
          </div>
        </div>
        <div className="bg-color">
          <p className="title-text text-light pt-3 ps-4">Add Store</p>
          <p className="subtitle-text text-light pb-3 ps-4">
            Please, enter the below information of the new store
          </p>
        </div>
        <hr className="ms-4 width-section" />
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
          }}
        >
          <div className="container wrapper ms-4">
            <div className="width-section ">
              <p className="info-txt mb-4">Store Details</p>
              <div className="row order-title my-3">
                <div className="col-md-6 ">
                  <FormInput
                    type="text"
                    name="name"
                    labelText="Retailer's First Name"
                    placeholder="Please Enter Retailers First Name"
                    className="form-cotrol input-credit "
                    value={validation.values.name}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <p className="error d-flex flex-row-reverse pt-1">
                      {validation.errors.name}
                    </p>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <FormInput
                    type="text"
                    name="last"
                    labelText="Retailer's Last Name"
                    placeholder="Please Enter Retailers Last Name"
                    className="form-cotrol input-credit"
                    value={validation.values.last}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.last && validation.errors.last ? (
                    <p className="error d-flex flex-row-reverse pt-1">
                      {validation.errors.last}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="row order-title my-3">
                <div className="col-md-6 ">
                  <FormInput
                    type="text"
                    name="number"
                    labelText="Phone Number"
                    placeholder="Please Enter Phone Number"
                    className="form-cotrol input-credit "
                    value={validation.values.number}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.number && validation.errors.number ? (
                    <p className="error d-flex flex-row-reverse pt-1">
                      {validation.errors.number}
                    </p>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <FormInput
                    type="text"
                    name="email"
                    labelText=" Email Address"
                    placeholder="Please Enter Email Address"
                    className="form-cotrol input-credit "
                    value={validation.values.email}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <p className="error d-flex flex-row-reverse pt-1">
                      {validation.errors.email}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="row order-title my-3">
                <div className="col-md-6">
                  <FormInput
                    type="text"
                    name="store"
                    labelText="Store Name"
                    placeholder="Please Enter Store Name"
                    className="form-cotrol input-credit "
                    value={validation.values.store}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.store && validation.errors.store ? (
                    <p className="error d-flex flex-row-reverse pt-1">
                      {validation.errors.store}
                    </p>
                  ) : null}
                </div>

                <div className="col-md-6">
                  <FormInput
                    type="text"
                    name="address"
                    labelText="Store Address"
                    placeholder="Please Enter Store Address"
                    className="form-cotrol input-credit "
                    value={validation.values.address}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.address && validation.errors.address ? (
                    <p className="error d-flex flex-row-reverse pt-1">
                      {validation.errors.address}
                    </p>
                  ) : null}
                </div>
              </div>

              <hr className="gray-hr" />
              <div className="row order-title my-3">
                <div className="col-md-6 ">
                  <FormInput
                    type="text"
                    name="link"
                    labelText="Website URL Link"
                    placeholder="Please Enter URL Link"
                    className="form-cotrol input-credit "
                    value={validation.values.link}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.link && validation.errors.link ? (
                    <p className="error d-flex flex-row-reverse pt-1">
                      {validation.errors.link}
                    </p>
                  ) : null}
                </div>
              </div>
              <hr className="gray-hr" />
              {/* row 5 */}
              <p className="info-txt mb-4">Shipping address</p>

              <div className="row order-title my-3">
                <div className="col-md-6 ">
                  <FormInput
                    type="text"
                    name="street"
                    labelText="Street"
                    placeholder="Please Enter Street"
                    className="form-cotrol input-credit "
                    value={validation.values.street}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.street && validation.errors.street ? (
                    <p className="error d-flex flex-row-reverse pt-1">
                      {validation.errors.street}
                    </p>
                  ) : null}
                </div>

                <div className="col-md-6">
                  <FormInput
                    type="text"
                    name="city"
                    labelText="City *"
                    placeholder="Please Enter City Name"
                    className="form-cotrol input-credit "
                    value={validation.values.city}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.city && validation.errors.city ? (
                    <p className="error d-flex flex-row-reverse pt-1">
                      {validation.errors.city}
                    </p>
                  ) : null}
                </div>
              </div>
              {/* row 6 */}
              <div className="row order-title my-3">
                <div className="col-md-6">
                  <label htmlFor="selected_state">STATE *</label>

                  <Select
                    name="selected_state"
                    // components={animatedComponents}
                    defaultValue={validation.values.selected_state}
                    options={stateList}
                    placeholder="Select a State Name"
                    onChange={(selectedOption) => {
                      validation.setFieldValue(
                        "selected_state",
                        selectedOption
                      );
                    }}
                    value={validation.values.selected_state}
                    className="select-colors mt-2 style-select"
                  />
                  {validation.touched.selected_state &&
                  validation.errors.selected_state ? (
                    <p className="error d-flex flex-row-reverse pt-1">
                      {validation.errors.selected_state}
                    </p>
                  ) : null}
                </div>
                <div className="col-md-6 ">
                  <label htmlFor="selected_country">COUNTRY *</label>

                  <Select
                    name="selected_country"
                    // components={animatedComponents}
                    defaultValue={validation.values.selected_country}
                    options={countryList}
                    placeholder="Select a Country Name"
                    onChange={(selectedOption) => {
                      validation.setFieldValue(
                        "selected_country",
                        selectedOption
                      );
                    }}
                    value={validation.values.selected_country}
                    className="select-colors mt-2 style-select"
                  />
                  {validation.touched.selected_country &&
                  validation.errors.selected_country ? (
                    <p className="error d-flex flex-row-reverse pt-1">
                      {validation.errors.selected_country}
                    </p>
                  ) : null}
                </div>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <FormInput
                      type="text"
                      name="zip"
                      labelText="ZIP Code"
                      placeholder="Please Enter ZIP Code"
                      className="form-cotrol input-credit"
                      value={validation.values.zip}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                    />
                    {validation.touched.zip && validation.errors.zip ? (
                      <p className="error d-flex flex-row-reverse pt-1">
                        {validation.errors.zip}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>

              <hr className="gray-hr" />

              <div className="d-flex justify-content-end mb-4">
                <button className="btn-add width-btn-new" type="submit">
                  SAVE STORE INFORMATION
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}

export default AddStore;
