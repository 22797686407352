import { configureStore } from '@reduxjs/toolkit';
import dressSlice from './features/dress/dressSlice';
import userSlice from './features/user/userSlice';
// import colorSlice from './features/color/colorSlice';
// import sizeSlice from './features/size/sizeSlice';
import packageSlice from './features/package/packageSlice';
import customItemSlice from './features/customItem/customItemSlice';
import orderSlice from './features/order/orderSlice';
import paymentSlice from './features/payment/paymentSlice';
import invoiceSlice from './features/invoice/invoiceSlice';
import addressSlice from './features/address/addressSlice';
import storeSlice from './features/store/storeSlice';
import stateSlice from './features/state/stateSlice';
import countrySlice from './features/country/countrySlice';
import retailersSlice from './features/retailers/retailersSlice';
export const store = configureStore({
  reducer: {
    user: userSlice,
    dress: dressSlice,
    // color: colorSlice,
    // size: sizeSlice,
    package: packageSlice,
    customItem: customItemSlice,
    order: orderSlice,
    payment: paymentSlice,
    invoice: invoiceSlice,
    address: addressSlice,
    store: storeSlice,
    country: countrySlice,
    state: stateSlice,
        stores: retailersSlice,

  },
});
