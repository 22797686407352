import React from "react";
import { Container, Form, Button } from "react-bootstrap";
import FormInput from "../../../../shared/FormInput";
import * as Yup from "yup";
import { useFormik } from "formik";

function BankAccount() {
  const validation = useFormik({
    initialValues: {
      name: "",
      routing: "",
      account: "",
      confirm: "",
      street: "",
      city: "",
      state: "",
      country: "",
      code: "",
      number: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This Field is required!"),
      routing: Yup.number()
        .typeError("This field must be a number")
        .required("This Field is required!"),
      account: Yup.number()
        .typeError("This field must be a number")
        .required("This Field is required!"),
      confirm: Yup.number()
        .typeError("This field must be a number")
        .required("This Field is required!"),
      street: Yup.string().required("This Field is required!"),
      city: Yup.string().required("This Field is required!"),
      state: Yup.string().required("This Field is required!"),
      country: Yup.string().required("This Field is required!"),
      code: Yup.number()
        .typeError("This field must be a number")
        .required("This Field is required!"),

      number: Yup.number()
        .typeError("This field must be a number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .required("This Field is required!"),
    }),
    onSubmit: (values) => {
      console.log("values", values);
    },
  });
  return (
    <Container className="py-4">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
        }}
        className="row g-3"
      >
        <div className="col-md-6">
          <FormInput
            type="text"
            name="name"
            labelText="NAME ON BANK ACCOUNT *"
            placeholder="Enter Name On Bank Account"
            value={validation.values.name}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
          {validation.touched.name && validation.errors.name ? (
            <p className="error d-flex flex-row-reverse pt-1">
              {validation.errors.name}
            </p>
          ) : null}
        </div>

        <div className="col-md-6">
          <FormInput
            type="text"
            name="routing"
            labelText="BANK ROUTING NUMBER *"
            placeholder="Enter Routing Number"
            value={validation.values.routing}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
          {validation.touched.routing && validation.errors.routing ? (
            <p className="error d-flex flex-row-reverse pt-1">
              {validation.errors.routing}
            </p>
          ) : null}
        </div>
        <div className="col-md-6">
          <FormInput
            type="text"
            name="account"
            labelText="BANK ACCOUNT NUMBER *"
            placeholder="Enter Bank Account Number"
            value={validation.values.account}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
          {validation.touched.account && validation.errors.account ? (
            <p className="error d-flex flex-row-reverse pt-1">
              {validation.errors.account}
            </p>
          ) : null}
        </div>
        <div className="col-md-6">
          <FormInput
            type="text"
            name="confirm"
            labelText="CONFIRM BANK ACCOUNT NUMBER *"
            placeholder="Re-Enter Bank Account Number"
            value={validation.values.confirm}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
          {validation.touched.confirm && validation.errors.confirm ? (
            <p className="error d-flex flex-row-reverse pt-1">
              {validation.errors.confirm}
            </p>
          ) : null}
        </div>
        <div className="col-md-6">
          <FormInput
            type="text"
            name="street"
            labelText="STREET *"
            placeholder="Enter Street Name"
            value={validation.values.street}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
          {validation.touched.street && validation.errors.street ? (
            <p className="error d-flex flex-row-reverse pt-1">
              {validation.errors.street}
            </p>
          ) : null}
        </div>
        <div className="col-md-6">
          <FormInput
            type="text"
            name="city"
            labelText="CITY *"
            placeholder="Enter City Name"
            value={validation.values.city}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
          {validation.touched.city && validation.errors.city ? (
            <p className="error d-flex flex-row-reverse pt-1">
              {validation.errors.city}
            </p>
          ) : null}
        </div>
        <div className="col-md-6">
          <FormInput
            type="text"
            name="state"
            labelText="STATE *"
            placeholder="Enter State Name"
            value={validation.values.state}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
          {validation.touched.state && validation.errors.state ? (
            <p className="error d-flex flex-row-reverse pt-1">
              {validation.errors.state}
            </p>
          ) : null}
        </div>

        <div className="col-md-6">
          <FormInput
            type="text"
            name="country"
            labelText="COUNTRY *"
            placeholder="Enter Country Name"
            value={validation.values.country}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
          {validation.touched.country && validation.errors.country ? (
            <p className="error d-flex flex-row-reverse pt-1">
              {validation.errors.country}
            </p>
          ) : null}
        </div>

        <div className="col-md-6">
          <FormInput
            type="text"
            name="code"
            labelText="ZIP CODE *"
            placeholder="Enter ZIP Code"
            value={validation.values.code}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
          {validation.touched.code && validation.errors.code ? (
            <p className="error d-flex flex-row-reverse pt-1">
              {validation.errors.code}
            </p>
          ) : null}
        </div>

        <div className="col-md-6">
          <FormInput
            type="text"
            name="number"
            labelText="PHONE NUMBER *"
            placeholder="Enter Phone Number"
            value={validation.values.number}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
          {validation.touched.number && validation.errors.number ? (
            <p className="error d-flex flex-row-reverse pt-1">
              {validation.errors.number}
            </p>
          ) : null}
        </div>
        <div className="d-flex flex-row-reverse mt-5">
          <Button className="btn-secondary btn-saveInfo " type="submit">
            save bank account information
          </Button>
        </div>
      </Form>
    </Container>
  );
}

export default BankAccount;
