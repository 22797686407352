import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios';
import fileDownload from 'js-file-download'

export const createPackageThunk = async (url, factory, thunkAPI) => {
    try {
        const resp = await customFetch.post(url, factory);
        return resp.data.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.msg);
    }
};

export const downloadInvoiceThunk = async (url, packages, thunkAPI) => {
  try {
    const resp = await customFetch.get(url, packages);
    fileDownload(resp.data, "orders.pdf");
    return "Package downloaded successfully";
  } catch (error) {
    checkForUnauthorizedResponse(error, thunkAPI);
  }
};


export const updatePackageThunk = async (url, factory, thunkAPI) => {
    try {
        const resp = await customFetch.post(url, factory);
        return resp.data.data;
    } catch (error) {
        console.log(error.message);
        return thunkAPI.rejectWithValue(error.message);
    }
};

export const exportPackageFactoryThunk = async (url, factory, thunkAPI) => {
    try {
        const resp = await customFetch.get(url, factory);
        fileDownload(resp.data, 'pckages.xlsx')
        return 'Packages exported successfully';
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
};

export const updateShippingCostThunk = async (url, factory, thunkAPI) => {
    try {
        const resp = await customFetch.post(url, factory);
        return resp.data.data;
    } catch (error) {
        console.log(error.message);
        return thunkAPI.rejectWithValue(error.message);
    }
};

export const updateTrackingNumberThunk = async (url, factory, thunkAPI) => {
    try {
        const resp = await customFetch.post(url, factory);
        return resp.data.data;
    } catch (error) {
        console.log(error.message);
        return thunkAPI.rejectWithValue(error.message);
    }
};

export const removePackageThunk = async (url, factory, thunkAPI) => {
    try {
        const resp = await customFetch.delete(url, factory);
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};

export const getPackageFactoryThunk = async (url, factory, thunkAPI) => {
    try {
        const resp = await customFetch.get(url, factory);
        return resp.data.data;
    } catch (error) {
        console.log(error.message);
        return thunkAPI.rejectWithValue(error.message);
    }
};

export const getPackagesThunk = async (url, factory, thunkAPI) => {
    try {
        const resp = await customFetch.get(url, factory);
        return resp.data.data;
    } catch (error) {
        console.log(error.message);
        return thunkAPI.rejectWithValue(error.message);
    }
};

export const getPackageOrdersThunk = async (url, factory, thunkAPI) => {
    try {
        const resp = await customFetch.get(url, factory);
        return resp.data.data;
    } catch (error) {
        console.log(error.message);
        return thunkAPI.rejectWithValue(error.message);
    }
};