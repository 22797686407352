import React, { useState } from "react";
const Error = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      {" "}
      <button
        className="openModalBtn btn-secondary"
        onClick={() => {
          setModalOpen(true);
        }}>
        Open
      </button>
      {modalOpen && (
        <div className="center">
          <div className=" modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setModalOpen(false);
                  }}></button>
              </div>
              <div className="modal-body">
                <svg
                  className="error-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 27 27">
                  <defs>
                    <clipPath id="clip-path">
                      <rect width="27" height="27" fill="none" />
                    </clipPath>
                  </defs>
                  <g id="error" clip-path="url(#clip-path)">
                    <g id="error-2" data-name="error" transform="translate(1.344 1)">
                      <circle
                        id="Ellipse_183"
                        data-name="Ellipse 183"
                        cx="12.5"
                        cy="12.5"
                        r="12.5"
                        transform="translate(-0.344)"
                        fill="rgba(0,0,0,0)"
                        stroke="#43425d"
                        stroke-miterlimit="10"
                        strokeWidth="2"
                      />
                      <line
                        id="Line_24"
                        data-name="Line 24"
                        y2="10.556"
                        transform="translate(12.5 3.799)"
                        fill="none"
                        stroke="#43425d"
                        stroke-miterlimit="10"
                        strokeWidth="2"
                      />
                      <line
                        id="Line_25"
                        data-name="Line 25"
                        y2="2.701"
                        transform="translate(12.5 18.5)"
                        fill="none"
                        stroke="#43425d"
                        stroke-miterlimit="10"
                        strokeWidth="2"
                      />
                    </g>
                  </g>
                </svg>

                <div className="error-message">
                  <p className="error-item">
                    <b>Error</b>
                  </p>
                </div>

                <div className="error-text">
                  <p>
                    The item you have selected will not arrive by the Wear Date.
                    <br />
                    <p className="error-date">
                      {" "}
                      Please <span className="error-span">change the Wear Date,</span> or select a different item.
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Error;
