import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ViewImage from "../shared/ViewImage";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../shared/Loading";
import SortButton from "../shared/SortButton";
import Pagination from "../shared/Pagination";
import eye from "../../assets/img/eye.svg";
import searchsvg from "../../assets/img/search.svg";
import {
  getCustomItemFilter,
  getCustomItems,
  handleSort,
  getCustomItemPaginate,
  handleSearch,
} from "../../features/customItem/customItemSlice";
import SearchForm from "../shared/SearchForm";

const StartCustomOrder = ({ setShowCustomItems }) => {
  const [allRows, setAllRows] = useState([]);
  const dispatch = useDispatch();
  const {
    allCustomItems,
    custom_items,
    isLoading,
    links,
    meta,
    sort,
    orderby,
    search,
  } = useSelector((store) => store.customItem);

  useEffect(() => {
    dispatch(
      getCustomItems({
        sort: sort,
        orderby: orderby,
        search: search,
      })
    );
  }, [sort, orderby, search]);

  useEffect(() => {
    if (custom_items) {
      let arr = [];
      custom_items.map((custom_items) => {
        arr.push({
          Id: custom_items.id,
          Image: custom_items.image,
          ItemName: custom_items.name,
          ItemPrice: custom_items.formatted_wholesale_price,
          ItemDiscription: custom_items.description,
        });
      });
      setAllRows(arr);
    } else {
      dispatch(
        getCustomItems({ sort: sort, orderby: orderby, search: search })
      );
    }
  }, [isLoading, custom_items, links]);

  useEffect(() => {
    dispatch(getCustomItemFilter({}))
  },[])

const handleCustomFilter = (e) => {
  let searchTerm = e.target.value;
  console.log(searchTerm);

  if(allCustomItems) {
    const filteredItems = allCustomItems.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    console.log(filteredItems);
    if (filteredItems) {
      let arr = [];
      filteredItems.map((custom_items) => {
        return (
          <div key={custom_items.id}>
            {arr.push({
               Id: custom_items.id,
               Image: custom_items.image,
               ItemName: custom_items.name,
               ItemPrice: custom_items.formatted_factory_price,
               ItemDiscription: custom_items.description,
            })}
          </div>
        );
      });
      setAllRows(arr);
    }
  }
}
  
  return (
    <>
      <div className="container my-4 toggle-style">
      <div class="searchForm"><div class="d-flex search">
            <input placeholder="Please enter style number or name" 
            type="text" id="formSearch" 
            className="ps-4 no-border search-input form-control"
            onChange={handleCustomFilter}
            />
              <button type="button" id="button-addon2" class="bg-transparent  box-shadow-none search-button btn btn-primary">
                <img className="margin-right-2" src={searchsvg} alt="search" /></button></div></div>
        <div className="d-flex align-items-center  ms-4 toggle-input">
          <label
            className="form-check-label pt-1 d-flex align-items-center pe-2"
            htmlFor="flexSwitchCheckDefault"
          >
            Dresses
          </label>
          <div className="form-check form-switch mx-2">
            <input
              className="form-check-input order-switch2"
              type="checkbox"
              defaultChecked={true}
              id="flexSwitchCheckChecked"
              onClick={(e) => {
                if (e.target.checked) {
                  setShowCustomItems(true);
                } else {
                  setShowCustomItems(false);
                }
              }}
            />
          </div>
          <label
            className=" form-check-label pt-1 d-flex align-items-center ps-2"
            htmlFor="flexSwitchCheckDefault"
          >
            Custom Items
          </label>
        </div>
      </div>
      {isLoading && <Loading />}
      {!isLoading && (
        <div className="container pe-5">
          <div className="row grid-header ps-4">
            <div className="col-md-2  text-title title-width width-xs">
              Image
            </div>
            <SortButton
              sort={sort}
              label="Item Name"
              click={() => dispatch(handleSort({ sort: sort, value: "name" }))}
              rootClass=" col-md-2 col-sm-12 text-title title-width width-xs"
            />
            <SortButton
              sort={sort}
              label="Description"
              click={() => dispatch(handleSort({ sort: sort, value: "name" }))}
              rootClass=" col-md-2 col-sm-12 text-title title-width width-xs"
            />
          </div>

          {allRows.map((row) => {
            const { Id, Image, ItemDiscription, ItemPrice, ItemName } = row;
            let urlLink = `/view-custom-order/${Id}`;
            let modalID = `#myModal${Id}`;
            let modalTarget = `myModal${Id}`;
            return (
              <div
                key={Id}
                className="grid-row-border ps-4 me-2 width-custom-row"
              >
                <div className="row align-items-center white10-13 light-gray-bg2 negative-margins">
                  <div className="col-md-2 width-custom-xs">
                    <img
                      className="grid-image position-relative grid-image-custom"
                      src={Image}
                      alt="item"
                      loading="lazy"
                    />
                    <img
                      src={eye}
                      className="grid-eye ms-1 position-absolute mt-3"
                      alt="eye"
                      data-bs-toggle="modal"
                      data-bs-target={modalID}
                      loading="lazy"
                    />
                    <ViewImage
                      header={ItemName}
                      src={Image}
                      modalTarget={modalTarget}
                      labels={["Item Name", "Discription", "Price"]}
                      body={[ItemName, ItemDiscription, ItemPrice]}
                    />
                  </div>
                  <div className="col-md-2 width-custom-xs">{ItemName}</div>
                  <div className="col-lg-2 col-md-3 width-description">
                    {ItemDiscription}
                  </div>
                  <div className="col-md-2 width-btn width-custom-btn">
                    <Link to={urlLink}>
                      <Button className="btn-secondary height-30">View</Button>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="width-row-order widthLeft">
            {meta && (
              <Pagination
                active={meta.current_page}
                size={parseInt(meta.last_page)}
                step={2}
                previous={links.prev}
                next={links.next}
                dispatch={dispatch}
                handlePage={getCustomItemPaginate}
                path={meta.path}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default StartCustomOrder;
