import React, { useEffect, useState } from "react";
import { Button, Container, Tabs, Tab } from "react-bootstrap";
import BankAccount from "../startOrder/checkout/modals/paymentModal/BankAccount";
import Credit from "../startOrder/checkout/modals/paymentModal/Credit";
import Header from "../../../src/components/shared/Header";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from "../../../src/features/country/countrySlice";
import { getStates } from "../../../src/features/state/stateSlice";
import { useNavigate } from "react-router-dom";

function AddPaymentModal({
  setModalOpen,
  setRefreshPage,
  refreshPage,
  storeId,
  beta,
}) {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { countries } = useSelector((store) => store.country);
  const { states } = useSelector((store) => store.state);
  const { dress } = useSelector((store) => store.dress);
  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [staxId, setStaxId] = useState([]);
  useEffect(() => {
    dispatch(getCountries());
    dispatch(getStates());
  }, []);

  useEffect(() => {
    setStaxId(
      JSON.parse(window.localStorage.getItem("store_stax_customer_id"))
    );
  }, []);

  useEffect(() => {
    if (states) {
      let stateList = [];
      Object.keys(states).map((key, i) =>
        stateList.push({
          value: Object.keys(states)[i],
          label: states[Object.keys(states)[i]],
        })
      );
      setStateList(stateList);
    }
    if (countries) {
      let list = [];
      Object.keys(countries).map((key, i) =>
        list.push({
          value: Object.keys(countries)[i],
          label: countries[Object.keys(countries)[i]],
        })
      );
      setCountryList(list);
    }
  }, [states, countries]);

  return (
    <Container className="py-4 absolute-pos z-index2">
      <div className="center paymentModal">
        <div className=" modal-dialog ms-0 mt-0 form-dialog">
          <div className="modal-content auto-modal">
            <div className="modal-header ms-2">
              <Header
                title="Add Payment Method"
                subtitle="Please provide us with the credit/debit information along with the address"
              />
              <Button
                type="button"
                className="btn-close me-1 btn-hover"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setModalOpen(false);
                }}
              ></Button>
            </div>

            <Tabs
              defaultActiveKey="profile"
              id="uncontrolled-tab-example"
              className="mb-3 ms-4 me-4"
            >
              <Tab eventKey="profile" title="CREDIT/DEBIT CARD">
                <Credit
                  stateList={stateList}
                  countryList={countryList}
                  stax_customer_id={staxId}
                  dress={dress}
                  setModalOpen={setModalOpen}
                  setRefreshPage={setRefreshPage}
                  refreshPage={refreshPage}
                  storeId={storeId}
                  beta={beta}
                  navigate={navigate}
                />
              </Tab>
              <Tab eventKey="home" title="BANK ACCOUNT">
                <BankAccount
                  stateList={stateList}
                  countryList={countryList}
                  stax_customer_id={staxId}
                  dress={dress}
                  setModalOpen={setModalOpen}
                  setRefreshPage={setRefreshPage}
                  refreshPage={refreshPage}
                  storeId={storeId}
                  beta={beta}
                  navigate={navigate}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </Container>
  );
}
export default AddPaymentModal;
