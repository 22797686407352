import React, { useState, useEffect } from "react";
import { Accordion, Card, Button, Container } from "react-bootstrap";
import visa from "../../../assets/img/visa.png";
import master from "../../../assets/img/master-card.png";
import bank from "../../../assets/img/building.svg";
import { useDispatch, useSelector } from "react-redux";
import ViewImage from "../../shared/ViewImage";
import eye from "../../../assets/img/eye.svg";
import moment from "moment";
import { formatPhoneNumber } from "../../../helpers/helpers";
import momenttimezone from "moment-timezone";
import {
  createOrder,
  clearOrderValues,
} from "../../../features/order/orderSlice";
import { getCustomItem } from "../../../features/customItem/customItemSlice";
import { useNavigate } from "react-router-dom";
import { getAddress } from "../../../features/address/addressSlice";
import { getPayment } from "../../../features/payment/paymentSlice";
import { formatStaxDate } from "../../../helpers/helpers";
import YesNo from "./modalCustom";
import { setFixed } from "../../../features/store/storeSlice";
import { getColorById, getSizeById } from "../../../utils/helpers";
export default function OrderSummary({ CustomToggle, order, dress }) {

 
  const [valuesChecked, setValuesChecked] = useState(true);
  const [currentId, setCurrentId] = useState("");
  const [isClicked, setIsClicked] = useState(true);
  const [state, setState] = useState("");
  const [orderModalShow, setOrderModalShow] = useState(false);
  const [customItem, setCustomItem] = useState("");
  const [errorss, setErrorss] = useState("");
  const [load, setLoad] = useState(true);
  const [serverValidation,setServerValidation] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    purchase_order_number,
    type,
    PurchaseOrderValues,
    errorsss,
  } = useSelector((store) => store.order);
  const { store_id, store } = useSelector((store) => store.store);

  const { PaymentCod } = useSelector((store) => store.payment);
  const {
    custom_items_ids,
    custom_size_details,
    hips,
    waist,
    hollow,
    bust,
    wholesale_price,
  } = useSelector((store) => store.dress);
  const { paymentM, payment, PaymentChosen } = useSelector(
    (store) => store.payment
  );
  const { name, price } = useSelector((store) => store.customItem);
  const { address, SelectedAddressId } = useSelector((store) => store.address);

  useEffect(() => {
    dispatch(getCustomItem(customItem[0]));
  }, [customItem]);

  useEffect(() => {
    console.log("p", custom_items_ids);
    dispatch(clearOrderValues());
    if (paymentM) {
      dispatch(getPayment(paymentM));
    }
  }, [paymentM, custom_items_ids]);

  useEffect(() => {
    if (custom_items_ids) {
      var item = Object.keys(custom_items_ids);
      setCustomItem(item.map(Number));
    }
  }, [custom_items_ids]);

  let modalID = `#myModal${currentId}`;
  let modalTarget = `myModal${currentId}`;

  useEffect(() => {
    if (SelectedAddressId) dispatch(getAddress({ id: SelectedAddressId }));
  }, [SelectedAddressId]);

  useEffect(() => {
    if (address) {
      var addressState = Object.values(address.state);
      setState(addressState);
    }
  }, [address, payment]);

  useEffect(() => {
    if (dress) {
      setCurrentId(dress.id);
    }
  }, [address]);

  const handleSubmitOder = () => {

    // adding the slack notification
    const msgdate = new Date();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "subject": "Client Whole Sale React App, Somone did an order",
      "text": `An order was placed on ${msgdate} order object send is {
        type: ${type},
        purchase_order_number: ${purchase_order_number},
        item_type: Dress,
        dress_id: ${dress.dress_id},
        dress_name: ${dress.dress_name},
        dress_style: ${dress.style_number}
        size: ${getSizeById(dress.selected_size)},
        color: ${getColorById(dress.selected_color)},
        client_phone: ${address.phone},
        wear_date: ${dress.date},
        store: ${store.name},
        payment_method: ${paymentM},
        notes: ${dress.note},
        bust: ${bust},
        waist: ${waist},
        hips: ${hips},
        hollow: ${hollow},
        custom_size_details: ${custom_size_details},
      }`
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

fetch("https://us-central1-bridit-983ab.cloudfunctions.net/postToSlack", requestOptions)
  .then(response => response.text())
  .then(result => console.log("results is ",result))
  .catch(error => console.log('error', error));

  fetch("https://us-central1-bridit-983ab.cloudfunctions.net/sendMail", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));

    setIsClicked(false);
    setLoad(false);
    if (custom_size_details !== "" || bust !== "") {
      dispatch(
        createOrder({
          type: type === "Re-Order" ? 2 : 1,
          purchase_order_number,
          item_type: 1,
          item_id: dress.dress_id,
          size: null,
          color: dress.selected_color,
          address: SelectedAddressId,
          wear_date: dress.date,
          store: store_id,
          payment_method: paymentM,
          notes: dress.note,
          bust: bust,
          waist: waist,
          hips: hips,
          hollow: hollow,
          custom_size_details: custom_size_details,
        })
      ).then((payload) => {
        // old code that is not understood
        if (errorsss.status === 400) {
          setErrorss(errorsss.data.data[0]);
        } else {
          if (customItem[0]) {
            setOrderModalShow(true);
          } else {
            setOrderModalShow(false);
            /*setTimeout(() => {
              navigate("/sales-order");
            }, 2000);*/
          }
        }

        // new code to implement the server error validation
        console.log("payload output", payload);
      if(payload.payload.success) {
                //adding the slack notification
                const msgdate = new Date();
                // Convert the date time to GMT -4
                const transformedDateTime = momenttimezone(msgdate).tz("America/Chicago").format();
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
            
                var raw = JSON.stringify({
                  "subject": "SUCCESSFUL ORDER",
                  "text": `the order was successful on ${transformedDateTime} order success messagess {
                    type: ${type},
                    purchase_order_number: ${purchase_order_number},
                    item_type: Dress,
                    dress_id: ${dress.dress_id},
                    dress_name: ${dress.dress_name},
                    dress_style: ${dress.style_number}
                    size: ${getSizeById(dress.selected_size)},
                    color: ${getColorById(dress.selected_color)},
                    client_phone: ${address.phone},
                    wear_date: ${dress.date},
                    store: ${store.name},
                    payment_method: ${paymentM},
                    notes: ${dress.note},
                    bust: ${bust},
                    waist: ${waist},
                    hips: ${hips},
                    hollow: ${hollow},
                    custom_size_details: ${custom_size_details},
                  }`
                });
            
                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body: raw,
                  redirect: 'follow'
                };
            
            fetch("https://us-central1-bridit-983ab.cloudfunctions.net/postToSlack", requestOptions)
              .then(response => response.text())
              .then(result => console.log("results is ",result))
              .catch(error => console.log('error', error));
        
              fetch("https://us-central1-bridit-983ab.cloudfunctions.net/sendMail", requestOptions)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));
              

        navigate("/sales-order")
      }
      else {
        const {data} = payload.payload.response;
        setLoad(true);
        setIsClicked(true);
        setServerValidation(data.data);

        //adding the slack notification
        const msgdate = new Date();
        // Convert the date time to GMT -4
        const transformedDateTime = momenttimezone(msgdate).tz("America/Chicago").format();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
    
        var raw = JSON.stringify({
          "subject": "FAILED ORDER",
          "text": `An order failed on ${transformedDateTime} order error messagess ${data.data.join(", ")}`
        });
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
    
    fetch("https://us-central1-bridit-983ab.cloudfunctions.net/postToSlack", requestOptions)
      .then(response => response.text())
      .then(result => console.log("results is ",result))
      .catch(error => console.log('error', error));

      fetch("https://us-central1-bridit-983ab.cloudfunctions.net/sendMail", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
      }
      //console.log("Payload is ", payload.payload.response);
    });

      // this is the else of other create order
    } else {
      dispatch(
        createOrder({
          type: type === "Re-Order" ? 2 : 1,
          purchase_order_number,
          item_type: 1,
          item_id: dress.dress_id,
          size: dress.selected_size,
          color: dress.selected_color,
          address: SelectedAddressId,
          wear_date: dress.date,
          store: store_id,
          payment_method: paymentM,
          notes: dress.note,
          bust: bust,
          waist: waist,
          hips: hips,
          hollow: hollow,
        })
      ).then((payload) => {
        console.log("the payload of this mission is",payload);
        if (errorsss.status === 400) {
          setErrorss(errorsss.data.data[0]);
        } else {
          if (customItem[0]) {
            setOrderModalShow(true);
          } else {
            setOrderModalShow(false);
            /*setTimeout(() => {
              navigate("/sales-order");
            }, 2000);*/
          }
        }

               // new code to implement the server error validation
               console.log("payload output", payload);
               if(payload.payload.success) {
                         //adding the slack notification
                         const msgdate = new Date();
                         // Convert the date time to GMT -4
                         const transformedDateTime = momenttimezone(msgdate).tz("America/Chicago").format();
                         var myHeaders = new Headers();
                         myHeaders.append("Content-Type", "application/json");
                     
                         var raw = JSON.stringify({
                           "text": `the order was successful on ${transformedDateTime} order success messagess {
                            type: ${type},
                            purchase_order_number: ${purchase_order_number},
                            item_type: Dress,
                            dress_id: ${dress.dress_id},
                            dress_name: ${dress.dress_name},
                            dress_style: ${dress.style_number}
                            size: ${getSizeById(dress.selected_size)},
                            color: ${getColorById(dress.selected_color)},
                            client_phone: ${address.phone},
                            wear_date: ${dress.date},
                            store: ${store.name},
                            payment_method: ${paymentM},
                            notes: ${dress.note},
                            bust: ${bust},
                            waist: ${waist},
                            hips: ${hips},
                            hollow: ${hollow},
                            custom_size_details: ${custom_size_details},
                           }`
                         });
                     
                         var requestOptions = {
                           method: 'POST',
                           headers: myHeaders,
                           body: raw,
                           redirect: 'follow'
                         };
                     
                     fetch("https://us-central1-bridit-983ab.cloudfunctions.net/postToSlack", requestOptions)
                       .then(response => response.text())
                       .then(result => console.log("results is ",result))
                       .catch(error => console.log('error', error));
                 
                       fetch("https://us-central1-bridit-983ab.cloudfunctions.net/sendMail", requestOptions)
                       .then(response => response.text())
                       .then(result => console.log(result))
                       .catch(error => console.log('error', error));
                       
         
                 navigate("/sales-order")
               }
               else {
                 const {data} = payload.payload.response;
                 setLoad(true);
                 setIsClicked(true);
                 setServerValidation(data.data);
         
                 //adding the slack notification
                 const msgdate = new Date();
                 // Convert the date time to GMT -4
                 const transformedDateTime = momenttimezone(msgdate).tz("America/Chicago").format();
                 var myHeaders = new Headers();
                 myHeaders.append("Content-Type", "application/json");
             
                 var raw = JSON.stringify({
                   "text": `An order failed on ${transformedDateTime} order error messagess ${data.data.join(", ")}`
                 });
             
                 var requestOptions = {
                   method: 'POST',
                   headers: myHeaders,
                   body: raw,
                   redirect: 'follow'
                 };
             
             fetch("https://us-central1-bridit-983ab.cloudfunctions.net/postToSlack", requestOptions)
               .then(response => response.text())
               .then(result => console.log("results is ",result))
               .catch(error => console.log('error', error));
         
               fetch("https://us-central1-bridit-983ab.cloudfunctions.net/sendMail", requestOptions)
               .then(response => response.text())
               .then(result => console.log(result))
               .catch(error => console.log('error', error));
               }
               //console.log("Payload is ", payload.payload.response);
             });
    }
  };

  useEffect(() => {
    if (PurchaseOrderValues === true) {
      setValuesChecked(false);
    } else {
      setValuesChecked(true);
    }
  }, [PurchaseOrderValues]);

  useEffect(() => {
    dispatch(setFixed(false));
  });
  return (
    <div className="pb-4">
      <Card className="card mb-2 pe-lg-5 cardAddresWidth scrollX">
        <Card.Header className="bg-white">
          <CustomToggle
            eventKey="3"
            title="Order Summary"
            subtitle="You are one step away, Please review order"
          ></CustomToggle>
        </Card.Header>
        {orderModalShow && (
          <YesNo
            show={orderModalShow}
            setShow={setOrderModalShow}
            title="This Order is Placed, However, this dress has the following linked accessory item(s):"
            msg={`Name: ${name}, Price: ${price}`}
            msg2={`Would you like to order this item?`}
            color="red"
            child={false}
            nav={`/view-custom-order/${customItem[0]}`}
            navNo={`/sales-order`}
            data={dress.custom_item}
            dress={dress}
            paymentM={paymentM}
            store_id={store_id}
            SelectedAddressId={SelectedAddressId}
            purchase_order_number={purchase_order_number}
            type={type}
          />
        )}
        <Accordion.Collapse eventKey="3">
          <Card.Body className="d-flex row ms-5 ">
            <hr className="hrAccordionrP" />
            <div className="col-lg-8 col-sm-8 col-xs-8 pt-2 marginTop">
              <div className="row grid-header mt-4 p-0 rowWidth">
                <div className="col-lg-2 ms-4 col-sm-2 width-label">IMAGE</div>
                <div className="col-lg-2 col-sm-2 width-label">STYLE NUM</div>
                <div className="col-lg-2 ps-0 col-sm-2 width-label">
                  dress name
                </div>
                {custom_size_details === "" && bust === "" ? (
                  <div className="col-lg-2 ps-0 col-sm-2 width-label">
                    Wear date
                  </div>
                ) : (
                  <div className="col-lg-3 ms-4 ps-0 col-sm-2 width-label">
                    Wear date
                  </div>
                )}
                <div className="col-lg ps-0 col-sm-2 width-label">Color</div>
                {dress.size &&
                custom_size_details === "" &&
                dress.size &&
                bust === "" ? (
                  <div className="col-lg ps-lg-4 ps-0 col-sm-2 width-label">
                    size
                  </div>
                ) : (
                  ""
                )}
                <div className="col-lg-1"></div>
              </div>
              <div className="grid-row-border-order ps-4 grid-height summaryPaymentWidthTab">
                <div className="row align-items-center ">
                  <div className="d-flex margin-top-sum">
                    <div className="pb-2 show-label grid-header ">Image</div>
                    <div className="show-label grid-header ms-4">Style NUM</div>
                    <div className="show-label grid-header ms-4">
                      Dress name
                    </div>
                  </div>
                  <div className="d-flex title-width ">
                    {dress ? (
                      <div className="show-label ms-5 ps-2 mb-2 ">
                        {dress.style_number}
                      </div>
                    ) : null}
                    {dress ? (
                      <div className="show-label ms-3 name-text padLeft">
                        {dress.dress_name}
                      </div>
                    ) : null}
                  </div>

                  <div className="d-flex ms-5 ps-3 ">
                    <div className="pb-2 show-label grid-header">Wear date</div>
                    <div className="show-label grid-header  color-text  marginLeftOrder">
                      Color
                    </div>
                  </div>
                  <div className="d-flex title-width ">
                    {dress ? (
                      <div className="show-label ms-5 ps-1 mb-2">
                        {moment(dress.wear_date).format("MM/DD/YYYY")}
                      </div>
                    ) : null}
                    {dress ? (
                      <div className="show-label ms-2 ps-1 ">{dress.color}</div>
                    ) : null}
                  </div>
                  {dress.size &&
                  custom_size_details === "" &&
                  dress.size &&
                  bust === "" ? (
                    <div className="show-label grid-header ms-3 ps-5 pt-2">
                      Size
                    </div>
                  ) : (
                    ""
                  )}
                  {dress.size &&
                  custom_size_details === "" &&
                  dress.size &&
                  bust === "" ? (
                    <div className="show-label  ms-3 ps-5 title-width">
                      {dress.size}
                    </div>
                  ) : null}

                  <div className="col-lg-2 col-md-2 ">
                    {dress ? (
                      <img
                        className="grid-image position-relative grid-image-sum"
                        src={dress.dress_image}
                        alt={dress.dress_name}
                      />
                    ) : null}

                    <img
                      src={eye}
                      className="grid-eye ms-1 grid-eye-checkout position-absolute mt-lg-3 mt-md-3"
                      alt="eye"
                      data-bs-toggle="modal"
                      data-bs-target={modalID}
                    />
                    {dress ? (
                      <ViewImage
                        header={dress.name}
                        src={dress.image}
                        modalTarget={modalTarget}
                        labels={["Item Name", "Style Number"]}
                        body={[dress.name, dress.style_number]}
                      />
                    ) : null}
                  </div>
                  {dress ? (
                    <div className="col-lg width-label widthLabelTab">
                      {dress.style_number}
                    </div>
                  ) : null}
                  {dress ? (
                    <div className="col-lg width-label widthLabelTab">
                      {dress.dress_name}
                    </div>
                  ) : null}
                  {dress ? (
                    <div className="col-lg col-md-3 width-label widthLabelTab">
                      {moment(dress.wear_date).format("MM/DD/YYYY")}
                    </div>
                  ) : null}
                  {dress ? (
                    <div className="col-lg width-label widthColor  widthLabelTab">
                      {dress.color}
                    </div>
                  ) : null}
                  {dress &&
                  custom_size_details === "" &&
                  dress &&
                  bust === "" ? (
                    <div className="col-lg  width-label  widthLabelTab ps-0">
                      {dress.size}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="grid-row-border-orderNew ps-4 grid-height summaryPaymentWidthTab py-4 mt-2">
                {bust ? (
                  <>
                    <div className="grid-headerNew ">Bust</div>
                    <div className="widthLabelTab fontCustom ">{bust}</div>
                  </>
                ) : (
                  ""
                )}
                {waist ? (
                  <>
                    <div className="grid-headerNew pt-2">Waist</div>
                    <div className=" widthLabelTab fontCustom ">{waist}</div>
                  </>
                ) : (
                  ""
                )}
                {hips ? (
                  <>
                    <div className="grid-headerNew pt-2">Hips</div>
                    <div className=" widthLabelTab fontCustom ">{hips}</div>
                  </>
                ) : (
                  ""
                )}
                {hollow ? (
                  <>
                    <div className="grid-headerNew pt-2">Hollow to Hem</div>
                    <div className="widthLabelTab fontCustom ">{hollow}</div>
                  </>
                ) : (
                  ""
                )}
                {custom_size_details ? (
                  <>
                    <div className="grid-headerNew pt-2">Other</div>
                    <div className="widthLabelTab fontCustom">
                      {custom_size_details}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>

              <div className="border mt-2 lh-1 pb-2 widthAddress widthAddressTab">
                <div className="row px-2 pb-4 ">
                  {address ? (
                    <div className="second-title col-lg-11 px-4 mt-4">
                      {address.full_name}
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="row d-flex ">
                    {address ? (
                      <>
                        <address className="paragraph col-lg-auto px-4 mb-2">
                          {address.street}
                          {", "} {address.city}
                          {", "} {state} {address.zip}
                        </address>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  {address ? (
                    <div className="paragraph px-4  pb-2">
                      {formatPhoneNumber(address.phone)}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/*  */}
              {PaymentCod === false ? (
                <div className="border pb-4 mt-2 lh-1 align-items-center paymentRow summaryPaymentWidthTab">
                  <div className="row pt-4 ">
                    <div className="col-lg-11 grid-row-visa align-items-center d-flex spaceAroundOnTab">
                      {payment ? (
                        <div className="col-lg-auto pb-2 mx-2 px-3 ">
                          <img
                            src={
                              payment.card_type === "visa"
                                ? visa
                                : payment.card_type === "mastercard"
                                ? master
                                : bank
                            }
                            alt="visa"
                            className="visa-order"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {payment ? (
                        <div className="col-lg-auto ps-2 payment-text">
                          **** **** **** {payment.card_last_four}
                        </div>
                      ) : (
                        ""
                      )}
                      {payment ? (
                        <div className="col-lg-4 ps-4  payment-text date-payment">
                          Expires on{" "}
                          {moment(
                            formatStaxDate(payment.card_exp_datetime)
                          ).format("MM/DD/YYYY")}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="border mt-2 lh-1 pb-2">
                  <p className="second-title mt-4 px-4">CREDIT ON DELIVERY</p>
                </div>
              )}
              {/*  */}
              <div className="border mt-2 paymentRow summaryPaymentWidthTab">
                <div className="row ">
                  <div className="col-lg-11 ">
                    <div className="px-4 details-header">
                      purchase order number
                    </div>
                    <div className="px-4">{purchase_order_number}</div>
                  </div>
                </div>
                <p className="px-4 details-header mt-3">type of order</p>
                <p className="px-4 pb-4">{type}</p>
              </div>
            </div>
            <div className="col-lg-4 pt-5 mrginTopSum">
              <Container className="bg-light px-1 head-container">
                <p className="summary-title ps-2">summary</p>
                <hr className="mx-0" />
                <p className="ps-2">Dress Cost</p>
                {dress ? (
                  <p className="sales-summary-cost ps-2">{dress.dress_price}</p>
                ) : null}
                <p className="summary-text ps-2">
                  This is an estimated cost, which is subject to change in the
                  sales order if additional charges (for example for larger size
                  wedding dresses, or custom measurements, or a rush fee on case
                  by case basis) are applied
                </p>
                {bust ||
                waist ||
                hips ||
                hollow ||
                custom_size_details !== "" ? (
                  <>
                    <p className="ps-2">Custom Measurement</p>
                    <p className="sales-summary-cost ps-2">$95</p>
                    <p className="ps-2">Total</p>
                    {wholesale_price ? (
                      <p className="sales-summary-cost ps-2">
                        ${wholesale_price + 95}
                      </p>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </Container>
              {load ? (
                <Button
                  className="btn-secondary summary-btn"
                  onClick={handleSubmitOder}
                  disabled={valuesChecked}
                >
                  PLACE ORDER
                </Button>
              ) : (
                <Button className="btn-secondary summary-btn">Loading</Button>
              )}

              {valuesChecked === true && isClicked ? (
                <p className="estimated_ship_date_container ms-0">
                  Please Fill Missing Sections
                </p>
              ) : (
                ""
              )}
              <p className="estimated_ship_date_container ms-0">{errorss}</p>
              <p className="estimated_ship_date_container ms-0">
                  {serverValidation &&
                  serverValidation.map((error,index)=> {
                    return(<>{error}<br/></>)
                  })
                  }
                </p>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </div>
  );
}
