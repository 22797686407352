import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addTokenToLocalStorage,
  addUserToLocalStorage,
  getUserFromLocalStorage,
  getTokenFromLocalStorage,
  removeTokenFromLocalStorage,
  removeUserFromLocalStorage,
  addStoreIdToLocalStorage,
  removeStoreIdFromLocalStorage,
  removeRoleIdFromLocalStorage,
  addRoleIdToLocalStorage,
  getRoleIdFromLocalStorage,
  removeStaxCustomerIdFromLocalStorage,
} from "../../utils/localStorage";
import {
  loginUserThunk,
  registerUserThunk,
  updateUserThunk,
  removeUserThunk,
  clearStoreThunk,
  forgetPasswordThunk,
  resetPasswordThunk,
  getUsersThunk,
} from "./userThunk";

const initialState = {
  isLoading: false,
  isSidebarOpen: false,
  user: getUserFromLocalStorage(),
  token: getTokenFromLocalStorage(),
  user_role_id: getRoleIdFromLocalStorage(),
  endsession: false,
  isForgetPassword: false,
  isEmailSend: false,
  isResetSuccess: false,
  showAll: false,
  userId: "",
  registered_user: "",
  error:''
};

export const getUsers = createAsyncThunk(
  "user/getUsers",
  async (store_id, thunkAPI) => {
    return getUsersThunk(`/api/stores/${store_id}/users`, thunkAPI);
  }
);
export const registerUser = createAsyncThunk(
  "user/registerUser",
  async (user, thunkAPI) => {
    return registerUserThunk("/api/users", user, thunkAPI);
  }
);
export const getUser = createAsyncThunk(
  "user/getUser",
  async (user, thunkAPI) => {
    return getUsersThunk("/api/users/" + user.id, thunkAPI);
  }
);
export const forgetPassword = createAsyncThunk(
  "user/forgetPassword",
  async (email, thunkAPI) => {
    return forgetPasswordThunk("/api/users/forgot-password", email, thunkAPI);
  }
);
export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (user, thunkAPI) => {
    return loginUserThunk("/api/users/store-login", user, thunkAPI);
  }
);
export const loginWholesaleUser = createAsyncThunk(
  "user/loginUser",
  async (user, thunkAPI) => {
    return loginUserThunk("/api/users/wholesale-login", user, thunkAPI);
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (user, thunkAPI) => {
    return resetPasswordThunk("/api/users/reset-password?type=2", user, thunkAPI);
  }
);

export const updateUser = createAsyncThunk("user/updateUser", updateUserThunk);

export const removeUser = createAsyncThunk("user/removeUser", removeUserThunk);

export const clearStore = createAsyncThunk("user/clearStore", clearStoreThunk);
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    toggleForgetPassword: (state) => {
      state.isForgetPassword = !state.isForgetPassword;
    },
    logoutUser: (state, { payload }) => {
      state.user = null;
      state.token = null;
      state.isSidebarOpen = false;
      state.user_role_id = null;
      removeUserFromLocalStorage();
      removeTokenFromLocalStorage();
      removeStoreIdFromLocalStorage();
      removeRoleIdFromLocalStorage();
      removeStaxCustomerIdFromLocalStorage();
    },
    getUserRole: (state, { payload }) => {
      state.user_role_id = getRoleIdFromLocalStorage();
    },
    getToken: (state, { payload }) => {
      return state.token;
    },
    setUserId: (state, { payload }) => {
      state.userId = payload;
    },
  },
  extraReducers: {
    [getUser.pending]: (state) => {
      // state.isLoading = true;
    },
    [getUser.fulfilled]: (state, { payload }) => {
      state.registered_user = payload;
      state.isLoading = false;
    },
    [getUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [getUsers.pending]: (state) => {
      state.isLoading = true;
    },
    [getUsers.fulfilled]: (state, { payload }) => {
      const { users, meta, links } = payload;
      state.users = users;
      state.links = links;
      state.meta = meta;
      state.isLoading = false;
    },
    [getUsers.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [registerUser.pending]: (state) => {
      state.isLoading = true;
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.showAll = !state.showAll;
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },
    [loginUser.pending]: (state) => {
      state.isLoading = true;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      if(payload.success){
      const { user, token } = payload.data;
      state.isLoading = false;
      state.user = user;
      state.token = token;

      addUserToLocalStorage(user);
      addTokenToLocalStorage(token);
      
      if (user) {
        addStoreIdToLocalStorage(user.userable_id);
        addRoleIdToLocalStorage(user.role_id);
      }
      window.location.reload();

    }
    else{
      state.isLoading = false;
      state.error=payload.response.data.message

    }
    
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
      const { status } = payload.response.status;      
      if (status === 401) {
        state.endsession = true;
      }
    },
    [resetPassword.pending]: (state) => {
      state.isLoading = true;
    },
    [resetPassword.fulfilled]: (state, { payload }) => {
      const {  success } = payload;
      state.isLoading = false;
      state.isEmailSend = success;
    },
    [resetPassword.rejected]: (state, { payload }) => {
      // const { status } = payload.response.status;
      // const { data } = payload.response.data;
      // state.isLoading = false;
      // if (status === 401) {
      //   state.endsession = true;
      // }
    },
    [updateUser.pending]: (state) => {
      state.isLoading = true;
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [updateUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [forgetPassword.pending]: (state) => {
      state.isLoading = true;
    },
    [forgetPassword.fulfilled]: (state, { payload }) => {
      const { success } = payload;
      state.isLoading = false;
      state.isEmailSend = success;
    },
    [forgetPassword.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },
    [removeUser.pending]: (state) => {
      state.isLoading = true;
    },
    [removeUser.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.showAll = !state.showAll;
    },
    [removeUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    
  },
});

export const {
  toggleSidebar,
  logoutUser,
  getToken,
  toggleForgetPassword,
  getUserRole,
  setUserId,
} = userSlice.actions;
export default userSlice.reducer;
