import React, { useState, useEffect, useRef } from "react";
import Header from "../shared/Header";
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../shared/Pagination";
import { useDispatch, useSelector } from "react-redux";
import SortButton from "../shared/SortButton";
import DateFilter from "../shared/DateFilter";
import Export from "../shared/Export";
import Loading from "../shared/Loading";
import {
  getStoreInvoice,
  handleFilter,
  getInvoicePaginate,
  exportInvoice,
  clearInvoiceValues,
  handleSort,
  setInvoiceData,
} from "../../features/invoice/invoiceSlice";
import { formatDate } from "../../helpers/helpers";
import { setInvoice, setFixed } from "../../features/store/storeSlice";

export default function InvoicesList() {
  const [allRows, setAllRows] = useState([]);
  const dispatch = useDispatch();
  const { store_id, invoice } = useSelector((store) => store.store);

  const {
    invoices,
    isLoading,
    links,
    meta,
    sort,
    orderby,
    status,
    from,
    to,
    factory_status,
  } = useSelector((store) => store.invoice);

  useEffect(() => {
    dispatch(
      getStoreInvoice({
        id: store_id,
        from: from,
        to: to,
        sort: sort,
        orderby: orderby,
        factory_status: factory_status,
      })
    );
  }, [sort, orderby, status, store_id, from, to]);

  useEffect(() => {
    dispatch(clearInvoiceValues);

    dispatch(
      getStoreInvoice({
        id: store_id,
        from: from,
        to: to,
        sort: sort,
        orderby: orderby,
        factory_status: factory_status,
      })
    );
  }, [sort, orderby, factory_status, from, to, store_id]);

  useEffect(() => {
    if (invoices && invoices[0]) {
      let invoiceArray = [];
      invoices.map((invoice) => {
        return invoiceArray.push({
          id: invoice.id,
          number: invoice.number,
          date: formatDate(invoice.created_at),
          price: invoice.store_package.meta.formatted_total,
          shipping: invoice.store_package.tracking_number,
          shippingCost: invoice.store_package.formatted_shipping_cost,
          status: invoice.status,
          Id: invoice.store_package_id,
          address: invoice.store_package.store,
        });
      });

      setAllRows(invoiceArray);
    } else {
      setAllRows([
        {
          id: "",
          number: "",
          date: "",
          price: "",
          shipping: "",
          shippingCost: "",
          status: "",
          Id: "",
          address: "",
        },
      ]);
    }
  }, [invoices, isLoading, links, meta, factory_status, from, to]);

  const checkBoxRef = useRef("");
  useEffect(() => {
    dispatch(setInvoice(true));
    dispatch(setFixed(true));
  }, []);

  return (
    <Container className="p-0 invoice scrollXOnSmallSize ScrollXHiddenOnMediumMobile ">
      <div className={`${invoice === true && "mrgnTp"}`}>
        <div>
          <Header title="Invoices" subtitle="Manage all Invoices" />
        </div>
        <div className="mrgnTopTrans">
          <DateFilter handleFilter={handleFilter} />
        </div>
        <hr className="mx-4 hrTable hrInv" />
        <div className="container my-4 ps-3 pe-lg-5 d-flex justify-content color-secondary ">
          <div className="exportwi">
            <Export
              label="Export Sample Orders Invoices"
              rootClass="d-flex align-items-center white12Text text-uppercase pt-2 "
              action={exportInvoice}
              num={1}
            />
          </div>{" "}
          <div className="export2">
            <Export
              label="Export Repeat Orders Invoices"
              rootClass=" d-flex align-items-center white12Text text-uppercase pt-2 "
              action={exportInvoice}
              num={2}
            />
          </div>
          <div className="exp">
            <Export
              label="Export Total Invoices"
              rootClass=" d-flex align-items-center white12Text text-uppercase pt-2 "
              action={exportInvoice}
              num={""}
            />
          </div>
        </div>

        {/* <div className="container my-4 pe-5 d-flex ween width-fully ms-4">
        <div className="d-flex">
          <div
            className="btn-toolbar"
            role="toolbar"
            aria-label="Toolbar with button groups"
          >
            <button
              onClick={() => dispatch(handleFilter({ factory_status: "" }))}
              type="button"
              className="invoice-box me-2"
            >
              TOTAL INVOICE
            </button>
            <button
              onClick={() => dispatch(handleFilter({ factory_status: 1 }))}
              type="button"
              className="invoice-box me-2"
            >
              OPEN
            </button>
            <button
              onClick={() => dispatch(handleFilter({ factory_status: 3 }))}
              type="button"
              className="invoice-box me-2"
            >
              PAID IN FULL
            </button>
            <button
              onClick={() => dispatch(handleFilter({ factory_status: 2 }))}
              type="button"
              className="invoice-box me-2"
            >
              PAID PARTIALLY
            </button>
          </div>
        </div> */}
        {/* <Link
          to="/make-a-payment"
          state={checked}
          style={{ pointerEvents: checked.id ? "" : "none" }}
        >
          <button className="btn-2 me-5"> MAKE A PAYMENT </button>
        </Link>
      </div> */}
        {isLoading && <Loading />}
        {!isLoading && (
          <div className="  py-2 ms-lg-4 ">
            <div className="row grid-header fit-content ps-3 me-5 widthInv widthIn">
              {status === "Paid Fully" ? (
                <div className="col-lg-1 col-md-1 widthInvoice ">
                  <Button className="bg-transparent grid-header no-border p-0">
                    Select All
                  </Button>
                </div>
              ) : (
                <div className="col-lg-1 col-md-1   widthInvoice width-Inv" />
              )}

              <SortButton
                sort={sort}
                label="INVOICE #"
                value="number"
                click={() =>
                  dispatch(handleSort({ sort: sort, value: "number" }))
                }
                rootClass="col-lg-1 col-md-1 col-sm-12  width-Inv mrgnRght"
              />

              <SortButton
                sort={sort}
                label="DATE"
                value="created_at"
                click={() =>
                  dispatch(handleSort({ sort: sort, value: "created_at" }))
                }
                rootClass="col-lg-1 col-md-1 col-sm-12 ms-3   date-margin width-Invdate"
              />
              <SortButton
                sort={sort}
                label="Amount Charged"
                value="total"
                click={() =>
                  dispatch(
                    handleSort({
                      sort: sort,
                      value: "total",
                    })
                  )
                }
                rootClass="col-lg-2 col-md-2 col-sm-12 ps-4   width-amount color-text pe-0 width-Inv"
              />
              <SortButton
                sort={sort}
                label="SHIPPING TRACKING #"
                value="tracking_number"
                click={() =>
                  dispatch(handleSort({ sort: sort, value: "tracking_number" }))
                }
                rootClass="col-md-2 col-sm-12 width-amount width-Inv widthTr"
              />
              <SortButton
                sort={sort}
                label="Status"
                value="status"
                click={() =>
                  dispatch(handleSort({ sort: sort, value: "status" }))
                }
                rootClass="col-lg-2 col-md-2  grid-status col-sm-12 margin-right pe-0 mrgnSta ps-3 ms-lg-4 paddingStatusTab width-InvStatus"
              />
              <SortButton
                sort={sort}
                label="Shipping Cost"
                value="shippingCost"
                click={() =>
                  dispatch(handleSort({ sort: sort, value: "shippingCost" }))
                }
                rootClass="col-lg-2 col-md-2 grid-status col-sm-12 width-InvShip"
              />
            </div>
            {allRows.map((row) => {
              const {
                id,
                number,
                date,
                price,
                shipping,
                status,
                Id,
                address,
                shippingCost,
              } = row;
              return (
                <div
                  key={id}
                  className="grid-row-border ps-3 py-3 me-5 width-200 ms-3"
                >
                  <div className="row align-items-center">
                    {status === "Paid Fully" ? (
                      ""
                    ) : (
                      <div className="form-check col-md-1 status-width width-itemInvoice widthOnMiniTab">
                        <input
                          className="ms-lg-2 form-check-input form-check-input2 widthmx "
                          type="checkbox"
                          id="flexCheckDefault"
                          ref={checkBoxRef}
                        />
                      </div>
                    )}

                    <Link
                      onClick={() => {
                        dispatch(
                          setInvoiceData({
                            number,
                            id,
                            address,
                            shippingCost,
                          })
                        );
                      }}
                      to={`/invoice-summary/${Id}`}
                      className="col-lg-1 col-md-1 col-sm-12   grid-cell-invoice width-itemInvoice width-date moveToLeft widthOnMiniTab ps-lg-0"
                      state={{
                        idl: id,
                        addressl: address,
                        numberl: number,
                        shippingCostl: shippingCost,
                      }}
                    >
                      {number}
                    </Link>
                    <div className="col-lg-1 col-md-1 col-sm-1 grid-cell-date  width-itemInvoice date-width marg ">
                      {date}
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2  py-1 grid-cell-date ps-5 width-itemInvoice marginStart">
                      {price}
                    </div>

                    <div className="col-md-2 col-12 col-sm-2 py-1 grid-cell-date ps-4  width-itemInvoice margin-text marginStartShi startMrg">
                      {shipping}
                    </div>
                    <div className="col-md-2 col-sm-2 grid-cell-status ps-4  width-itemInvoice status-margin mrgnLftSta ">
                      {status}
                    </div>
                    <div className="col-md-2 col-sm-2  width-itemInvoice marginInv cstmrgn shippingCostInMini ms-lg-5">
                      {shippingCost}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className="width-row-sale widthPag">
          {meta && (
            <Pagination
              active={meta.current_page}
              size={parseInt(meta.last_page)}
              step={2}
              previous={links.prev}
              next={links.next}
              dispatch={dispatch}
              handlePage={getInvoicePaginate}
              path={meta.path}
            />
          )}
        </div>
      </div>
    </Container>
  );
}
