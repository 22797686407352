export const addUserToLocalStorage = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const addTokenToLocalStorage = (s_token) => {
  localStorage.setItem("s_token", JSON.stringify(s_token));
};
export const addStaxIdStorage = (id) => {
  localStorage.setItem("store_stax_customer_id", JSON.stringify(id));
};

export const addStoreIdToLocalStorage = (StoreId) => {
  localStorage.setItem("StoreId", JSON.stringify(StoreId));
};
export const addRoleIdToLocalStorage = (RoleId) => {
  localStorage.setItem("RoleId", JSON.stringify(RoleId));
};
export const addStaxCustomerIdToLocalStorage = (StaxCustomerId) => {
  localStorage.setItem("StaxCustomerId", JSON.stringify(StaxCustomerId));
};

export const removeStaxCustomerIdFromLocalStorage = () => {
  localStorage.removeItem("StaxCustomerId");
};
export const removeUserFromLocalStorage = () => {
  localStorage.removeItem("user");
};

export const removeTokenFromLocalStorage = () => {
  localStorage.removeItem("s_token");
};
export const removeStoreIdFromLocalStorage = () => {
  localStorage.removeItem("StoreId");
};
export const removeRoleIdFromLocalStorage = () => {
  localStorage.removeItem("RoleId");
};

export const getUserFromLocalStorage = () => {
  const result = localStorage.getItem("user");
  const user = result ? JSON.parse(result) : null;
  return user;
};

export const getStaxCustomerIdFromLocalStorage = () => {
  const result = localStorage.getItem("StaxCustomerId");
  const StaxCustomerId = result ? JSON.parse(result) : null;
  return StaxCustomerId;
};
export const getStoreIdFromLocalStorage = () => {
  const result = localStorage.getItem("StoreId");
  if(result != "undefined") {
  const StoreId = result ? JSON.parse(result) : null;
  return StoreId;
  }
  return null;
};

export const getTokenFromLocalStorage = () => {
  const result = localStorage.getItem("s_token");
  const s_token = result ? JSON.parse(result) : null;
  return s_token;
};
export const getStoreFromLocalStorage = () => {
  const result = localStorage.getItem("user");
  const store = result ? JSON.parse(result) : null;
  return store;
};
export const getRoleIdFromLocalStorage = () => {
  const result = localStorage.getItem("RoleId");
  const RoleId = result ? JSON.parse(result) : null;
  return RoleId;
};

export const addColorsToLocalStorage = (color) => {
  localStorage.setItem("color", JSON.stringify(color));
};

export const removeColorFromLocalStorage = () => {
  localStorage.removeItem("color");
};

export const getColorsFromLocalStorage = () => {
  const result = localStorage.getItem("color");
  const color = result ? JSON.parse(result) : null;
  return color;
};

export const addLanguageToLocalStorage = (language) => {
  localStorage.setItem("language", JSON.stringify(language));
};

export const removeLanguageFromLocalStorage = () => {
  localStorage.removeItem("language");
};

export const getLanguagesFromLocalStorage = () => {
  const result = localStorage.getItem("language");
  const language = result ? JSON.parse(result) : null;
  return language;
};

export const addCustomItemToLocalStorage = (CustomItem) => {
  localStorage.setItem("CustomItem", JSON.stringify(CustomItem));
};

export const removeCustomItemFromLocalStorage = () => {
  localStorage.removeItem("CustomItem");
};

export const getCustomItemFromLocalStorage = () => {
  const result = localStorage.getItem("CustomItem");
  const CustomItem = result ? JSON.parse(result) : null;
  return CustomItem;
};

export const addOrderToLocalStorage = (order) => {
  localStorage.setItem("order", JSON.stringify(order));
};

export const removeOrderFromLocalStorage = () => {
  localStorage.removeItem("order");
};

export const getOrderFromLocalStorage = () => {
  const result = localStorage.getItem("order");
  const order = result ? JSON.parse(result) : null;
  return order;
};

export const addFactoryToLocalStorage = (factory) => {
  localStorage.setItem("factory", JSON.stringify(factory));
};

export const removeFactoryFromLocalStorage = () => {
  localStorage.removeItem("factory");
};

export const getFactoryFromLocalStorage = () => {
  const result = localStorage.getItem("factory");
  const factory = result ? JSON.parse(result) : null;
  return factory;
};

export const addPackageToLocalStorage = (Package) => {
  localStorage.setItem("Package", JSON.stringify(Package));
};

export const removePackageFromLocalStorage = () => {
  localStorage.removeItem("Package");
};

export const getPackageFromLocalStorage = () => {
  const result = localStorage.getItem("Package");
  const Package = result ? JSON.parse(result) : null;
  return Package;
};

export const addDressToLocalStorage = (Package) => {
  localStorage.setItem("Dress", JSON.stringify(Package));
};

export const removeDressFromLocalStorage = () => {
  localStorage.removeItem("Dress");
};

export const getDressFromLocalStorage = () => {
  const result = localStorage.getItem("Dress");
  const dress = result ? JSON.parse(result) : null;
  return dress;
};

export const addSizeToLocalStorage = (sizes) => {
  localStorage.setItem("Sizes", JSON.stringify(sizes));
};

export const removeSizeFromLocalStorage = () => {
  localStorage.removeItem("Sizes");
};

export const getSizeFromLocalStorage = () => {
  const result = localStorage.getItem("Sizes");
  const sizes = result ? JSON.parse(result) : null;
  return sizes;
};
