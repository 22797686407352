import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  updatePackageThunk,
  updateShippingCostThunk,
  updateTrackingNumberThunk,
  removePackageThunk,
  getPackageFactoryThunk,
  getPackagesThunk,
  getPackageOrdersThunk,
  exportPackageFactoryThunk,
  downloadInvoiceThunk
} from './packageThunk';

const initialFiltersState = {
  search: '',
  searchStatus: 'all',
  searchType: 'all',
  sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],
  per_page: 10,
  from: '',
  to: '',
};

const initialState = {
  isLoading: false,
  packages: null,
  orderPackages: null,
  links: null,
  meta: null,
  sort: 'desc',
  orderby: 'created_at',
  endsession: false,
  ...initialFiltersState,
  packageMeta:'',
  number: '',

};


export const downloadInvoice = createAsyncThunk(
  "package/downloadInvoice",
  async (packageId, thunkAPI) => {
    return downloadInvoiceThunk(
      `api/invoices/${packageId}/download`,
      {
        responseType: "blob",
      },
      thunkAPI
    );
  }
);

export const updatePackage = createAsyncThunk(
  'package/updatePackage',
  async (factoryPackage, thunkAPI) => {
    return updatePackageThunk(
      '/api/factory-packages/' + factoryPackage.id,
      factoryPackage,
      thunkAPI
    );
  }
);

export const updateShippingCost = createAsyncThunk(
  'package/updateShippingCost',
  async (factoryPackage, thunkAPI) => {
    return updateShippingCostThunk(
      '/api/factory-packages/' +
        factoryPackage.id +
        '/update-shipping-cost' +
        factoryPackage,
      thunkAPI
    );
  }
);

export const updateTrackingNumber = createAsyncThunk(
  'package/updateTrackingNumber',
  async (factoryPackage, thunkAPI) => {
    return updateTrackingNumberThunk(
      '/api/factory-packages/' + factoryPackage.id + '/update-tracking-number',
      factoryPackage,
      thunkAPI
    );
  }
);

export const removePackage = createAsyncThunk(
  'package/removePackage',
  async (factoryPackage, thunkAPI) => {
    return removePackageThunk(
      '/api/factory-packages',
      factoryPackage,
      thunkAPI
    );
  }
);

export const getPackageFactory = createAsyncThunk(
  'package/getPackageFactory',
  async (factoryPackage, thunkAPI) => {
    console.log(
      `/api/factories/${factoryPackage.id}/packages?from=${factoryPackage.from}&to=${factoryPackage.to}&order_dir=${factoryPackage.sort}&order_by=${factoryPackage.orderby}`
    );
    if (factoryPackage.per_page != null) {
      return getPackageFactoryThunk(
        `/api/factories/${factoryPackage.id}/packages?per_page=${factoryPackage.per_page}&from=${factoryPackage.from}&to=${factoryPackage.to}&order_dir=${factoryPackage.sort}&order_by=${factoryPackage.orderby}`,
        thunkAPI
      );
    }
    return getPackageFactoryThunk(
      `/api/factories/${factoryPackage.id}/packages?from=${factoryPackage.from}&to=${factoryPackage.to}&order_dir=${factoryPackage.sort}&order_by=${factoryPackage.orderby}`,
      thunkAPI
    );
  }
);

export const getPackageFactoryPaginate = createAsyncThunk(
  'dress/getPackageFactoryPaginate',
  async (link, thunkAPI) => {
    return getPackageFactoryThunk(link, thunkAPI);
  }
);

export const getPackage = createAsyncThunk(
  'package/getPackage',
  async (storePackage, thunkAPI) => {
    return getPackagesThunk(
      '/api/store-packages/' + storePackage.id,
      storePackage,
      thunkAPI
    );
  }
);

export const getPackageOrders = createAsyncThunk(
  'package/getPackageOrders',
  async (factoryPackage, thunkAPI) => {
    if (factoryPackage.per_page != null) {
      return getPackageOrdersThunk(
        `/api/store-packages/${factoryPackage.id}/orders?search=${factoryPackage.search}&order_dir=${factoryPackage.sort}&order_by=${factoryPackage.orderby}&per_page=${factoryPackage.per_page}`,
        thunkAPI
      );
    }
    return getPackageOrdersThunk(
      `/api/store-packages/${factoryPackage.id}/orders?search=${factoryPackage.search}&order_dir=${factoryPackage.sort}&order_by=${factoryPackage.orderby}`,
      thunkAPI
    );
  }
);

export const getPackageOrdersPaginate = createAsyncThunk(
  'package/getPackageOrdersPaginate',
  async (link, thunkAPI) => {
    return getPackageOrdersThunk(link, thunkAPI);
  }
);


export const exportFactoryPackages = createAsyncThunk(
  'package/exportFactoryPackages',
  async (factoryPackage, thunkAPI) => {
    return exportPackageFactoryThunk(
      `/api/factories/${factoryPackage.id}/packages/export`,
      {
        responseType: 'blob',
      },
      thunkAPI
    );
  }
);

const packageSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {
    handleNext: (state, { payload }) => {},
    handlePrevious: (state, { payload }) => {},
    handlePage: (state, { payload }) => {},
    handleSort: (state, { payload }) => {
      state.orderby = payload.value;
      if (payload.sort === 'desc') {
        state.sort = 'asc';
      }
      if (payload.sort === 'asc') {
        state.sort = 'desc';
      }
    },
    handleFilter: (state, { payload }) => {
      console.log('payload', payload);
      if (payload.per_page) {
        state.per_page = payload.per_page;
      }
      if (payload.from) {
        state.from = payload.from;
      }
      if (payload.to) {
        state.to = payload.to;
      }
    },
    clearPackageValues: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: {
    
    [getPackage.pending]: (state) => {
      state.isLoading = true;
    },
    [getPackage.fulfilled]: (state, { payload }) => {
                  console.log('r',payload);
      const { links, meta, address  } = payload;
      state.isLoading = false;
      state.address = address;
      state.packages = payload;
      state.number = payload.invoice.number;
      state.links = links;
      state.packageMeta = meta;
    },
    [getPackage.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },

     [downloadInvoice.pending]: (state) => {
      state.isLoading = true;
    },
    [downloadInvoice.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
     
    },
    [downloadInvoice.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [exportFactoryPackages.pending]: (state) => {
      state.isLoading = true;
    },
    [exportFactoryPackages.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [exportFactoryPackages.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },

    [getPackageFactory.pending]: (state) => {
      state.isLoading = true;
    },
    [getPackageFactory.fulfilled]: (state, { payload }) => {
      const { packages, links, meta } = payload;
      state.isLoading = false;
      state.packages = packages;
      state.links = links;
      state.meta = meta;
    },
    [getPackageFactory.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },

    [getPackageFactoryPaginate.pending]: (state) => {
      state.isLoading = true;
    },
    [getPackageFactoryPaginate.fulfilled]: (state, { payload }) => {
      const { packages, links, meta } = payload;
      console.log(payload);
      state.isLoading = false;

      state.packages = packages;
      state.links = links;
      state.meta = meta;
    },
    [getPackageFactoryPaginate.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },
    [getPackageOrdersPaginate.pending]: (state) => {
      state.isLoading = true;
    },
    [getPackageOrdersPaginate.fulfilled]: (state, { payload }) => {
      console.log('r',payload);
      const { orders, links, meta } = payload;
      state.isLoading = false;
      state.orderPackages = orders;
      state.links = links;
      state.meta = meta;
    },
    [getPackageOrdersPaginate.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },
    [getPackageOrders.pending]: (state) => {
      state.isLoading = true;
    },
    [getPackageOrders.fulfilled]: (state, { payload }) => {
      const { orders, links, meta } = payload;
      state.isLoading = false;
      state.orderPackages = orders;
      state.links = links;
      state.meta = meta;
    },
    [getPackageOrders.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },

    [updatePackage.pending]: (state) => {
      state.isLoading = true;
    },
    [updatePackage.fulfilled]: (state, { payload }) => {
      
      // const { packages } = payload;
      state.isLoading = false;
      // state.factory = packages;
      // addPackageToLocalStorage(packages);;
    },
    [updatePackage.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },

    [updateShippingCost.pending]: (state) => {
      state.isLoading = true;
    },
    [updateShippingCost.fulfilled]: (state, { payload }) => {
      // const { packages } = payload;
      state.isLoading = false;
      // state.factory = packages;
      // addPackageToLocalStorage(packages);;
    },
    [updateShippingCost.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },

    [updateTrackingNumber.pending]: (state) => {
      state.isLoading = true;
    },
    [updateTrackingNumber.fulfilled]: (state, { payload }) => {
      // const { packages } = payload;
      state.isLoading = false;
      // state.factory = packages;
      // addPackageToLocalStorage(packages);;
    },
    [updateTrackingNumber.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },

    [removePackage.pending]: (state) => {
      state.isLoading = true;
    },
    [removePackage.fulfilled]: (state, { payload }) => {
      // const { packages } = payload;
      state.isLoading = false;
      // state.factory = packages;
      // addPackageToLocalStorage(packages);;
    },
    [removePackage.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },
  },
});

export const {
  handleExport,
  handleNext,
  handlePrevious,
  handleSort,
  handleFilter,
  handlePage,
  clearPackageValues,
} = packageSlice.actions;
export default packageSlice.reducer;
