import React, { useEffect } from "react";
import FormInput from "../../shared/FormInput";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Container, Form } from "react-bootstrap";
import Header from "../../shared/HeaderModal";
import { useDispatch, useSelector } from "react-redux";
import { getStoreAddresses } from "../../../features/address/addressSlice";
import { registerUser } from "../../../features/user/userSlice";
import Select from "react-select";
import { getStoreId } from "../../../features/store/storeSlice";
export default function AddUserAccount({ setOpenModal }) {
  const dispatch = useDispatch();
  const { store_id } = useSelector((store) => store.store);
  const { addresses } = useSelector((store) => store.address);

  useEffect(() => {
    dispatch(getStoreId());
  }, []);

  useEffect(() => {
    if (store_id) {
      dispatch(getStoreAddresses({ id: store_id }));
    }
  }, [store_id]);

  const modifiedAddresses = [];
  if (addresses) {
    addresses.map((address) => {
      modifiedAddresses.push({
        value: address.id,
        label: address.full_name,
      });
    });
  }

  const validation = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      user: "",
      email: "",
      password: "",
      confirm: "",
      role: "",
      address: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("This Field is required!"),
      last_name: Yup.string().required("This Field is required!"),
      user: Yup.string().required("This Field is required!"),
      email: Yup.string()
        .email("The email you entered is invalid!")
        .required("This Field is required!"),

      password: Yup.string()
        .required("This Field is required")
        .min(8, "Password is too short - should be 8 chars minimum."),

      confirm: Yup.string()
        .required("This Field is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
      role: Yup.object().required("This Field is required"),
      address: Yup.object().required("This Field is required"),
    }),
    onSubmit: (values) => {
      const user = {
        name: values.user,
        email: values.email,
        password: values.password,
        role: values.role.value,
        // language_id: values.language_id,
        first_name: values.first_name,
        last_name: values.last_name,
        address: values.address.value,
      };
      dispatch(registerUser(user));
      setOpenModal(false);
    },
  });

  return (
    <Container className="px-0 py-2">
      <div className=" modal-dialog form-dialog form-index">
        <div className="modal-content auto-modal">
          <div className="modal-header">
            <Header
              title="Add User Account"
              subtitle="Please fill the information below and save the new account"
            />

            <button
              type="button"
              className="btn-close btn-form"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setOpenModal(false);
              }}
            />
          </div>

          <div className="modal-body">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
              }}
              className="row g-3"
            >
              <div className="col-md-6">
                <FormInput
                  type="text"
                  name="first_name"
                  labelText="FIRST NAME *"
                  placeholder="Enter the First Name"
                  value={validation.values.name}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.touched.first_name &&
                validation.errors.first_name ? (
                  <p className="error d-flex flex-row-reverse pt-1">
                    {validation.errors.first_name}
                  </p>
                ) : null}
              </div>
              <div className="col-md-6">
                <FormInput
                  type="text"
                  name="last_name"
                  labelText="LAST NAME *"
                  placeholder="Enter Last Name"
                  value={validation.values.last}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.touched.last_name && validation.errors.last_name ? (
                  <p className="error d-flex flex-row-reverse pt-1">
                    {validation.errors.last_name}
                  </p>
                ) : null}
              </div>

              <div className="col-md-6">
                <FormInput
                  type="text"
                  name="user"
                  labelText="USERNAME *"
                  placeholder="Enter Username"
                  value={validation.values.user}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.touched.user && validation.errors.user ? (
                  <p className="error d-flex flex-row-reverse pt-1">
                    {validation.errors.user}
                  </p>
                ) : null}
              </div>
              <div className="col-md-6">
                <FormInput
                  type="text"
                  name="email"
                  labelText="EMAIL ADDRESS *"
                  placeholder="Enter Email Address"
                  value={validation.values.email}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.touched.email && validation.errors.email ? (
                  <p className="error d-flex flex-row-reverse pt-1">
                    {validation.errors.email}
                  </p>
                ) : null}
              </div>
              <div className="col-md-6">
                <FormInput
                  type="password"
                  name="password"
                  labelText="PASSWORD *"
                  placeholder="Enter Password"
                  value={validation.values.password}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.touched.password && validation.errors.password ? (
                  <p className="error d-flex flex-row-reverse pt-1">
                    {validation.errors.password}
                  </p>
                ) : null}
              </div>
              <div className="col-md-6">
                <FormInput
                  type="password"
                  name="confirm"
                  labelText="CONFIRM PASSWORD *"
                  placeholder="Enter Password"
                  value={validation.values.confirm}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                />
                {validation.touched.confirm && validation.errors.confirm ? (
                  <p className="error d-flex flex-row-reverse pt-1">
                    {validation.errors.confirm}
                  </p>
                ) : null}
              </div>
              <div className="col-md-6">
                <label className="label-text ms-0">ACCOUNT TYPE *</label>
                <Select
                  name="role"
                  value={validation.values.role}
                  defaultValue={validation.values.role}
                  options={[
                    { value: 1, label: "Admin Account" },
                    { value: 2, label: "Regular Account" },
                  ]}
                  onChange={(selectedOption) => {
                    validation.setFieldValue("role", selectedOption);
                  }}
                  placeholder="Select Account Type"
                />
                {validation.touched.role && validation.errors.role ? (
                  <p className="error d-flex flex-row-reverse pt-1">
                    {validation.errors.role}
                  </p>
                ) : null}
              </div>
              <div className="col-md-6">
                <label className="label-text ms-0">ADDRESS *</label>
                <Select
                  name="address"
                  value={validation.values.address}
                  defaultValue={validation.values.address}
                  options={modifiedAddresses}
                  onChange={(selectedOption) => {
                    validation.setFieldValue("address", selectedOption);
                  }}
                  placeholder="Select an address"
                />
                {validation.touched.address && validation.errors.address ? (
                  <p className="error d-flex flex-row-reverse pt-1">
                    {validation.errors.address}
                  </p>
                ) : null}
              </div>

              <div className="modal-footer">
                <button className="btn-secondary" type="submit">
                  ADD USER
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Container>
  );
}
