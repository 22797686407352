import React, { useEffect, useState } from "react";
import { Accordion, Card, Form } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import FormInput from "../../shared/FormInput";
import { useDispatch } from "react-redux";
import { SetOrderNumber, setValues } from "../../../features/order/orderSlice";
import { setFixed } from "../../../features/store/storeSlice";

export default function PurchaseOrder({ CustomToggle, ToggleNext }) {
  const dispatch = useDispatch();
  const [dataChosen, setDataChosen] = useState(false);

  const validation = useFormik({
    initialValues: {
      purchase: "",
      order: "",
    },
    validationSchema: Yup.object({
      purchase: Yup.string().required("This Field is required!"),
      order: Yup.string().required("This Field is required!"),
    }),
    onSubmit: (values) => {
      dispatch(SetOrderNumber(values));
    },
  });

  useEffect(() => {
    if (validation.values.purchase !== "" && validation.values.order !== "") {
      setDataChosen(true);
      dispatch(setValues(true));
    } else {
      setDataChosen(false);
    }
  }, [validation.values]);
  useEffect(() => {
    dispatch(setFixed(false));
  });
  return (
    <>
      <Card className="card mb-2 pe-5 cardAddresWidth scrollX">
        <Card.Header className="bg-white">
          <CustomToggle
            eventKey="2"
            title="Purchase Order Number"
            subtitle="Enter Purchase Order Name/Number"
          ></CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body className="d-flex row ms-5 styleWidthInput">
            <div className="row ">
              <div className="col-lg-6">
                <hr className="hrAccordionOrder" />
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                  }}
                >
                  <fieldset>
                    <div className="mb-3 py-4 ">
                      <FormInput
                        type="text"
                        name="purchase"
                        labelText="PURCHASE ORDER NUMBER *"
                        placeholder="Enter Name or Number"
                        value={validation.values.purchase}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        className="form-control input-purchase"
                      />
                      {validation.touched.purchase &&
                      validation.errors.purchase ? (
                        <p className="error pt-1">
                          {validation.errors.purchase}
                        </p>
                      ) : null}
                    </div>
                    <div className="mb-3 input-purchase">
                      <label
                        htmlFor="disabledSelect"
                        className="purchaseorder-label ms-0"
                      >
                        TYPE OF ORDER *
                      </label>

                      <select
                        id="disabledSelect"
                        name="order"
                        className="form-select purchaseorder-input purchaseorder-option  "
                        value={validation.values.order}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                      >
                        <option>Select Type of Order</option>
                        <option>Sample</option>
                        <option>Re-Order</option>
                      </select>
                      {validation.touched.order && validation.errors.order ? (
                        <p className="error pt-1">{validation.errors.order}</p>
                      ) : null}
                    </div>
                  </fieldset>
                  <div className="justify-content-between align-item-end d-flex ">
                    <div></div>
                    <div className="me-0">
                      <ToggleNext
                        eventKey="3"
                        disabled={!dataChosen}
                        className="row-button-purchase"
                      >
                        Next
                      </ToggleNext>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
}
