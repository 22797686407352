import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../shared/Header";
import { Container, Button } from "react-bootstrap";
import ViewImage from "../shared/ViewImage";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { formatDate } from "../../helpers/helpers";
import { formatPhoneNumber } from "../../helpers/helpers";
import { setFixed, setInvoiceSummary } from "../../features/store/storeSlice";

import {
  getPackage,
  getPackageOrders,
  downloadInvoice,
} from "../../features/package/packageSlice";

export default function InvoiceSummary() {
  const [allRows, setAllRows] = useState([]);
  const [state, setState] = useState([]);

  // const location = useLocation();
  // const { idl, addressl, numberl, shippingCostl } = location.state;
  const { Id } = useParams();
  const dispatch = useDispatch();
  const {
    orderPackages,
    isLoading,
    links,
    meta,
    sort,
    orderby,
    from,
    to,
    search,
    packageMeta,
    packages
  } = useSelector((store) => store.package);
  const { shippingCost } = useSelector((store) => store.invoice);
  const { address, number } = useSelector((store) => store.package);
  useEffect(() => {
    if (Id) {
      dispatch(
        getPackageOrders({
          id: Id,
          from: from,
          to: to,
          sort: sort,
          orderby: orderby,
          search: search,
        })
      );
    }
  }, [sort, orderby, from, to, search, Id]);

  useEffect(() => {
    console.log("f", Id);
    if (Id) {
      dispatch(
        getPackage({
          id: Id,
          from: from,
          to: to,
          sort: sort,
          orderby: orderby,
          search: search,
        })
      );
    }
  }, [sort, orderby, from, to, search, Id]);
  useEffect(() => {
    console.log("a", address);
    if (orderPackages) {
      let packageArray = [];
      orderPackages.map((order) => {
        return packageArray.push({
          Id: order.id,
          Image: order.orderable.image,
          Po: order.purchase_order_number,
          TypeOfOrder: order.type,
          DressName: order.orderable.name,
          WearDate: formatDate(order.wear_date),
          color: order.color ? order.color.label : "",
          size: order.size ? order.size.label : "",
          Cost: order.formatted_wholesale_price,
        });
      });
      setAllRows(packageArray);
    }
  }, [orderPackages, isLoading, links, meta, address]);

  useEffect(() => {
    if (address) {
      var addressState = Object.values(address.state);
      setState(addressState);
    }
  }, [address]);
  useEffect(() => {
    dispatch(setInvoiceSummary(true));
  }, []);
  useEffect(() => {
    dispatch(setFixed(false));
  });

  return (
    <div className="scrollX">
      {/* <div className={`${invoiceSummary === true && "mrgnTp"}`}> */}
      <div className="widthInvoiceList ">
        <Header
          title={`Invoice # ${number}`}
          subtitle="Please check the invoice and download it as pdf"
        />
      </div>
      <Container>
        <div className="container my-4 px-0 ">
          <Link to="/invoices" className="arrow">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19.7"
              height="9.542"
              viewBox="0 0 19.7 9.542"
            >
              <g
                id="Group_439"
                data-name="Group 439"
                transform="translate(-9.045 -14.126)"
              >
                <g id="right-arrow" transform="translate(9.045 14.126)">
                  <g
                    id="Group_257"
                    data-name="Group 257"
                    transform="translate(0 0)"
                  >
                    <path
                      id="Path_84"
                      data-name="Path 84"
                      d="M.226,136.226h0l4.021-4a.77.77,0,0,1,1.086,1.091L2.633,136h16.3a.77.77,0,1,1,0,1.539H2.633l2.7,2.687a.77.77,0,0,1-1.086,1.091l-4.021-4h0A.77.77,0,0,1,.226,136.226Z"
                      transform="translate(0 -132)"
                      fill="#b8998d"
                    />
                  </g>
                </g>
              </g>
            </svg>

            <span className=" back-to-list px-2">back to list</span>
          </Link>
        </div>

        <Container className="border row mb-4 widthInvoiceList  widthOnMiniTab scrollXOnSmallSize ScrollXHiddenOnMediumMobile">
          <h5 className="summary-header">Invoice Summary</h5>
          <hr className="mx-6" />

          <div className="col-lg-9 ">
            <div className=" row grid-header mt-4 ms-3">
              <div className="col-lg-1 hideTitle widthDetailsInvoice">
                image
              </div>
              <div className="col-lg-2 ms-2 hideTitle widthDetailsInvoice">
                PO #
              </div>
              <div className="col-lg-2 ps-2 hideTitle widthDetailsInvoice">
                Type of order
              </div>
              <div className="col-lg-2 hideTitle widthDetailsInvoice">
                dress name
              </div>
              <div className="col-lg-1 hideTitle widthDetailsInvoice">
                wear date
              </div>
              <div className="col-lg-1 ps-4 hideTitle widthDetailsInvoice">
                color
              </div>
              <div className="col-lg-1 ps-4 hideTitle widthDetailsInvoice">
                size
              </div>
              <div className="col-lg-1 ps-4 hideTitle widthDetailsInvoice">
                cost
              </div>
            </div>

            {allRows.map((row) => {
              const {
                Id,
                Image,
                Po,
                TypeOfOrder,
                DressName,
                WearDate,
                color,
                size,
                Cost,
              } = row;
              let modalID = `#myModal${Id}`;
              let modalTarget = `myModal${Id}`;
              return (
                <div
                  key={Id}
                  className="grid-row-border ps-4 me-2 borderInvoiceWidthTab"
                >
                  <div className="row align-items-center">
                    <div className="d-flex margin-top-sum">
                      <div className="col-lg-1 show-label grid-header">
                        image
                      </div>
                      <div className="col-lg-2 ms-2 show-label grid-header ps-4">
                        PO #
                      </div>
                      <div className="col-lg-2 ps-2 show-label grid-header ps-5 ms-3">
                        Type of order
                      </div>
                    </div>
                    <div className="d-flex title-width">
                      <div className="col-lg-2 ms-2 show-label fontInvoice ps-5">
                        {Po}
                      </div>
                      <div className="col-lg-2 ps-4 show-label fontInvoice">
                        {TypeOfOrder}
                      </div>
                    </div>
                    <div className="d-flex margin-top-sum">
                      <div className="col-lg-2 hideTitle show-label grid-header ps-5 ms-2">
                        dress name
                      </div>
                      <div className="col-lg-1 hideTitle show-label grid-header ps-5">
                        wear date
                      </div>
                    </div>
                    <div className="d-flex title-width ">
                      <div className="col-lg-2 show-label fontInvoice ps-5  ms-2">
                        {DressName}
                      </div>
                      <div className="col-lg-1 show-label fontInvoice ps-5">
                        {WearDate}
                      </div>
                    </div>
                    <div className="d-flex margin-top-sum">
                      <div className="col-lg-1 ps-4 hideTitle hideTitle show-label grid-header ps-5 ms-2">
                        color
                      </div>
                      <div className="col-lg-1 ps-4 hideTitle hideTitle show-label grid-header ps-5 ms-5">
                        size
                      </div>
                    </div>
                    <div className="d-flex title-width">
                      <div className="MarginLe show-label fontInvoice mrgnColor">
                        {color}
                      </div>
                      <div className=" show-label fontInvoice ps-4 paddingLeft">
                        {size}
                      </div>
                    </div>
                    <div className="col-lg-1 ps-4 hideTitle hideTitle show-label grid-header ps-5 ms-4 mt-4">
                      cost
                    </div>
                    <div className="col-lg-1 show-label fontInvoice ps-5 ms-4">
                      {Cost}
                    </div>

                    <div className="col-lg-1 widthDetailsInvoice">
                      <img
                        className="grid-image imgInvoice position-relative"
                        src={Image}
                        alt={DressName}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        className="invoice-eye"
                        data-bs-toggle="modal"
                        data-bs-target={modalID}
                      >
                        <g
                          id="Group_706"
                          data-name="Group 706"
                          transform="translate(-515.8 -489.8)"
                        >
                          <circle
                            id="Ellipse_177"
                            data-name="Ellipse 177"
                            cx="10"
                            cy="10"
                            r="10"
                            transform="translate(515.8 489.8)"
                            fill="#b8998d"
                          />
                          <g id="view" transform="translate(519.428 495.885)">
                            <path
                              id="Path_9797"
                              data-name="Path 9797"
                              d="M7.386,5A7.46,7.46,0,0,0,1,9.064a7.46,7.46,0,0,0,6.386,4.064,7.46,7.46,0,0,0,6.386-4.064A7.46,7.46,0,0,0,7.386,5Zm0,6.966a6.1,6.1,0,0,1-5.006-2.9,6.1,6.1,0,0,1,5.006-2.9,6.094,6.094,0,0,1,5.006,2.9,6.094,6.094,0,0,1-5.006,2.9Z"
                              transform="translate(-1 -5)"
                              fill="#fff"
                            />
                            <circle
                              id="Ellipse_176"
                              data-name="Ellipse 176"
                              cx="1.963"
                              cy="1.963"
                              r="1.963"
                              transform="translate(4.459 2.137)"
                              fill="#fff"
                            />
                          </g>
                        </g>
                      </svg>
                      <ViewImage
                        header={DressName}
                        src={Image}
                        modalTarget={modalTarget}
                        labels={["Item Name", "Po#"]}
                        body={[DressName, Po]}
                      />
                    </div>
                    <div className="col-lg-2 ms-2 hideTitle widthDetailsInvoice">
                      {Po}
                    </div>
                    <div className="col-lg-2 ps-3 hideTitle widthDetailsInvoice">
                      {TypeOfOrder}
                    </div>
                    <div className="col-lg-2 hideTitle widthDetailsInvoice">
                      {DressName}
                    </div>
                    <div className="col-lg-1 hideTitle widthDetailsInvoice">
                      {WearDate}
                    </div>
                    <div className="col-lg-1 ms-4 hideTitle widthDetailsInvoice">
                      {color}
                    </div>
                    <div className="col-lg-1 hideTitle widthDetailsInvoice">
                      {size}
                    </div>
                    <div className="col-lg-1 hideTitle widthDetailsInvoice">
                      {Cost}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="col-lg-3">
            <Container className="bg-light head-container h-auto ps-2">
              <p className="summary-title paddingToLeftTab">Address</p>
              <hr className="mx-0" />
              {address ? (
                <>
                  <p className="second-title mb-0  paddingToLeftTab ">{`${address.full_name}`}</p>

                  <p className="paragraph mb-0 paddingToLeftTab">
                    {`${address.street}, ${address.city}, ${state} 
                    ${address.zip}`}
                  </p>
                  <p className="paragraph pb-4 pt-0  paddingToLeftTab">
                    {formatPhoneNumber(address.phone)}
                  </p>
                </>
              ) : (
                ""
              )}
            </Container>

            <Container className="bg-light head-container ps-2">
              <p className="summary-title  paddingToLeftTab">summary</p>
              <hr className="mx-0" />

              <p className="paddingToLeftTab">SUBTOTAL</p>
              {packageMeta ? (
                <p className="cost  paddingToLeftTab">
                  {packageMeta.formatted_sub_total}
                </p>
              ) : (
                ""
              )}
              <br></br>
              <p className=" paddingToLeftTab">SHIPPING</p>
              {shippingCost ? (
                <p className="cost  paddingToLeftTab">{shippingCost}</p>
              ) : (
                ""
              )}
              <br></br>
              <p className=" paddingToLeftTab">TOTAL</p>
              {packageMeta ? (
                <p className="sales-summary-cost  paddingToLeftTab">
                  {packageMeta.formatted_total}
                </p>
              ) : (
                ""
              )}
            </Container>
            <Button
              className="btn-secondary summary-btn"
              onClick={() => {
                console.log(Id);
                dispatch(downloadInvoice(packages.invoice_id));
              }}
            >
              download pdf
            </Button>
          </div>
        </Container>
      </Container>
    </div>
    // </div>
  );
}
