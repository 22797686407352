import moment from "moment";

const formatDate = (theobject) => {
  return moment.utc(theobject).format("MM/DD/YYYY");
};
const formatFullMonthDate = (theobject) => {
  return moment.utc(theobject).format("MMMM DD, YYYY");
};

const formatStaxDate = (theobject) => {
  return moment.utc(theobject).format("MM/YY");
};

const formatDateField = ({ theobject }) => {
  return moment(theobject).format("YYYY-MM-DD");
};

const appendScript = (scriptToAppend) => {
  const script = document.createElement("script");
  script.src = scriptToAppend;
  script.async = true;
  document.body.appendChild(script);
};


const formatPhoneNumber = (value) =>{
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
}

export { formatDate, formatDateField, appendScript, formatStaxDate, formatPhoneNumber, formatFullMonthDate}
