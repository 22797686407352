import React from "react";
const StaxInput = ({
  name,
  value,
  labelText,
  placeholder,
  className,
  maxLength,
  onChange,
}) => {
  return (
    <>
      <label htmlFor={name} className="label-text ms-0 pt-2 mb-0">
        {labelText || name}
      </label>

      <input
        className={className ? className : "payment_input_box"}
        name={name}
        maxLength={maxLength}
        placeholder={placeholder}
        onChange={onChange}
        value={value || ""}
      />
    </>
  );
};
export default StaxInput;
