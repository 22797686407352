import React, { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import Header from "../../../shared/HeaderModal";
import FormInput from "../../../shared/FormInput";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from "../../../../features/country/countrySlice";
import { getStates } from "../../../../features/state/stateSlice";
import { createAddress } from "../../../../features/address/addressSlice";
import Select from "react-select";

function AddressModal({ setModal }) {
  const dispatch = useDispatch();
  const { countries } = useSelector((store) => store.country);
  const { states } = useSelector((store) => store.state);

  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  useEffect(() => {
    dispatch(getCountries());
    dispatch(getStates());
  }, []);

  useEffect(() => {
    if (states) {
      let stateList = [];
      Object.keys(states).map((key, i) =>
        stateList.push({
          value: Object.keys(states)[i],
          label: states[Object.keys(states)[i]],
        })
      );
      setStateList(stateList);
    }
    if (countries) {
      let list = [];
      Object.keys(countries).map((key, i) =>
        list.push({
          value: Object.keys(countries)[i],
          label: countries[Object.keys(countries)[i]],
        })
      );
      setCountryList(list);
    }
  }, [states, countries]);

  const validation = useFormik({
    initialValues: {
      first: "",
      last: "",
      street: "",
      city: "",
      state: "",
      country: "",
      zip: "",
      number: "",
    },
    validationSchema: Yup.object({
      first: Yup.string().required("This Field is required!"),
      last: Yup.string().required("This Field is required!"),
      street: Yup.string().required("This Field is required!"),
      city: Yup.string().required("This Field is required!"),
      selected_country: Yup.object().required("This Field is required!"),
      selected_state: Yup.object().required("This Field is required!"),
      zip: Yup.string()
        .required("This Field is required!"),
      number: Yup.number()
        .typeError("This field must be a number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .required("This Field is required!"),
      // .min(10, 'The phone must be between 10 and 15 digits.')
      // .max(15, 'The phone must be between 10 and 15 digits.')
    }),
    onSubmit: (values) => {
      let address = {
        full_name: values.first + " " + values.last,
        street: values.street,
        city: values.city,
        state: values.selected_state.value,
        country: values.selected_country.value,
        zip: values.zip,
        phone: values.number,
        store: 1,
      };
      dispatch(createAddress(address));
      setModal(false);
    },
  });

  console.log("validationvalues", validation.values);
  return (
    <Container className="py-4 absolute-pos z-index2">
      <div className="center">
        <div className=" modal-dialog form-dialog m-0">
          <div className="modal-content auto-modal">
            <div className="modal-header">
              <Header
                title="Add New Address"
                subtitle="Please fill the information below and save the new address"
              />

              <Button
                type="button"
                className="btn-close btn-hover"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setModal(false);
                }}
              />
            </div>

            <div className="modal-body">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                }}
                className="row g-3"
              >
                <div className="col-md-6">
                  <FormInput
                    type="text"
                    name="first"
                    labelText="FIRST NAME *"
                    placeholder="First Name"
                    value={validation.values.first}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.first && validation.errors.first ? (
                    <p className="error d-flex flex-row-reverse pt-1">
                      {validation.errors.first}
                    </p>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <FormInput
                    type="text"
                    name="last"
                    labelText="LAST NAME *"
                    placeholder="Last Name"
                    value={validation.values.last}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.last && validation.errors.last ? (
                    <p className="error d-flex flex-row-reverse pt-1">
                      {validation.errors.last}
                    </p>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <FormInput
                    type="text"
                    name="street"
                    labelText="STREET *"
                    placeholder="Enter Street Name"
                    value={validation.values.street}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.street && validation.errors.street ? (
                    <p className="error d-flex flex-row-reverse pt-1">
                      {validation.errors.street}
                    </p>
                  ) : null}
                </div>

                <div className="col-md-6">
                  <FormInput
                    type="text"
                    name="city"
                    labelText="CITY *"
                    placeholder="Enter City Name"
                    value={validation.values.city}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.city && validation.errors.city ? (
                    <p className="error d-flex flex-row-reverse pt-1">
                      {validation.errors.city}
                    </p>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="selected_state"
                    className="label-text ms-0 pt-2 mb-0"
                  >
                    STATE *
                  </label>
                  <Select
                    name="selected_state"
                    // components={animatedComponents}
                    defaultValue={validation.values.selected_state}
                    options={stateList}
                    placeholder="Select a State Name"
                    onChange={(selectedOption) => {
                      validation.setFieldValue(
                        "selected_state",
                        selectedOption
                      );
                    }}
                    value={validation.values.selected_state}
                    className="select-colors"
                  />
                  {validation.touched.selected_state &&
                  validation.errors.selected_state ? (
                    <p className="error d-flex flex-row-reverse pt-1">
                      {validation.errors.selected_state}
                    </p>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="selected_country"
                    className="label-text ms-0 pt-2 mb-0"
                  >
                    COUNTRY *
                  </label>
                  <Select
                    name="selected_country"
                    // components={animatedComponents}
                    defaultValue={validation.values.selected_country}
                    options={countryList}
                    placeholder="Select a Country Name"
                    onChange={(selectedOption) => {
                      validation.setFieldValue(
                        "selected_country",
                        selectedOption
                      );
                    }}
                    value={validation.values.selected_country}
                    className="select-colors"
                  />
                  {validation.touched.selected_country &&
                  validation.errors.selected_country ? (
                    <p className="error d-flex flex-row-reverse pt-1">
                      {validation.errors.selected_country}
                    </p>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <FormInput
                    type="text"
                    name="zip"
                    labelText="ZIP CODE *"
                    placeholder="Enter ZIP Code"
                    value={validation.values.zip}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.zip && validation.errors.zip ? (
                    <p className="error d-flex flex-row-reverse pt-1">
                      {validation.errors.zip}
                    </p>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <FormInput
                    type="text"
                    name="number"
                    labelText="PHONE NUMBER *"
                    placeholder="Enter Phone Number"
                    value={validation.values.number}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.number && validation.errors.number ? (
                    <p className="error d-flex flex-row-reverse pt-1 ">
                      {validation.errors.number}
                    </p>
                  ) : null}
                </div>

                <div className="d-flex flex-row-reverse mt-4">
                  <Button className="btn-secondary next-btn mt-2" type="submit">
                    save address
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default AddressModal;
