import React from "react";

export default function Header({ title, subtitle }) {
  return (
    <div className="header-bg px-4 py-3 width-subtitle">
      <p className="title">{title}</p>
      <p className="subTitle mb-0 width-subtitle-title">{subtitle}</p>
    </div>
  );
}
