import React from "react";

const StaxDiv = ({ name, labelText, className, id }) => {
  return (
    <>
      <label htmlFor={name} className="label-text ms-0 pt-2 mb-0">
        {labelText || name}
      </label>
      <div className="payment_input_box">
        <div id={id} className={className ? className : ""} />
      </div>
    </>
  );
};
export default StaxDiv;
