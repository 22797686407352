import React, { useState } from "react";
import { Button, Accordion, Card } from "react-bootstrap";
import copy from "../../../assets/img/copy.svg";
import plus from "../../../assets/img/brown-plus.svg";
import close from "../../../assets/img/close.svg";
import visa from "../../../assets/img/visa.png";
import masterCard from "../../../assets/img/master-card.png";
import bank from "../../../assets/img/building.svg";
import AddPaymentModal from "./modals/paymentModal/addPaymentMethodModal";
import EditPaymentModal from "./modals/EditpaymentModal/EditPaymentMethodModal";
import error from "../../../assets/img/error.svg";
import YesNo from "../../shared/modals/YesNo";
import Loading from "../../shared/Loading";
import { setFixed } from "../../../features/store/storeSlice";

import {
  getPaymentMethods,
  removePaymentMethod,
  handleDelete,
  getPayment,
  clearPaymentValues,
  handleIds,
  setPayment,
  setpaymentCOD,
} from "../../../features/payment/paymentSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getStore, getStoreId } from "../../../features/store/storeSlice";
import { formatStaxDate } from "../../../helpers/helpers";
import { refreshPage } from "../../../features/payment/paymentSlice";

export default function PaymentMethod({ CustomToggle, ToggleNext }) {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [deletModal, setDeletModal] = useState(false);
  const [allRows, setAllRows] = useState([]);
  const [deleteId, setDeleteID] = useState("");
  const [selectedId, setSelectedId] = useState("");
  // const [logo, setLogo] = useState("");
  const { storeNew } = useSelector((store) => store.store);

  const { store_id } = useSelector((store) => store.store);
  const {
    paymentMethods,
    deletedPaymentId,
    rerender,
    isLoading,
    paymentM,
  } = useSelector((store) => store.payment);
  const [RefreshPage, setRefreshPage] = useState(false);
  useEffect(() => {
    dispatch(getStoreId());
  }, []);

  const handleEdit = (id, method) => {
    if (id) {
      dispatch(getPayment(id));
    }
    dispatch(clearPaymentValues);
    setSelectedId(id);
    setPaymentMethod(method);
    setEditModal(true);
  };
  useEffect(() => {
    dispatch(refreshPage(RefreshPage));
  }, [RefreshPage]);

  useEffect(() => {
    if (store_id) {
      dispatch(getStore({ id: store_id }));
    }
  }, [store_id]);

  useEffect(() => {
    if (store_id) {
      dispatch(getPaymentMethods(store_id));
    }
  }, [store_id, RefreshPage, rerender]);

  useEffect(() => {
    setDeleteID(deleteId);
  }, [deletedPaymentId]);

  useEffect(() => {
    if (paymentMethods.data) {
      let paymentMethodsArray = [];
      paymentMethods.data.map((paymentMethod, count) => {
        if (count === 0) {
          dispatch(handleIds(paymentMethod.id));
        }
        // if (paymentMethod.method === "card") {
        //   if (paymentMethod.card_type === "visa") {
        //     setLogo(visa);
        //   }
        //   if (paymentMethod.card_type === "mastercard") {
        //     setLogo(masterCard);
        //   }
        // } else {
        //   setLogo(bank);
        // }
        return paymentMethodsArray.push({
          count: count,
          id: paymentMethod.id,
          method: paymentMethod.method,
          logo:
            paymentMethod.method === "card"
              ? paymentMethod.card_type === "visa"
                ? visa
                : masterCard
              : bank,
          number: `**** **** **** ${paymentMethod.card_last_four}`,
          expireDate:
            paymentMethod.method === "card"
              ? `Expires on ${formatStaxDate(paymentMethod.card_exp_datetime)}`
              : "",
        });
      });
      setAllRows(paymentMethodsArray);
    }
  }, [paymentMethods, RefreshPage]);

  useEffect(() => {
    if (paymentMethods.data) {
      if (paymentMethods.data.length !== 0) {
        dispatch(setPayment(true));
      }
    }
  }, [paymentMethods]);
  useEffect(() => {
    dispatch(setFixed(false));
  });
  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <Card className="card mb-2 pe-5 cardAddresWidth scrollX">
          <Card.Header className="bg-white">
            <CustomToggle
              eventKey="1"
              title="Payment Method"
              subtitle="Add/Choose Payment Method"
            ></CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body className="row ms-5">
              {modalOpen && (
                <AddPaymentModal
                  setModalOpen={setModalOpen}
                  setRefreshPage={setRefreshPage}
                />
              )}
              {editModal && (
                <EditPaymentModal
                  setModalOpen={setEditModal}
                  setRefreshPage={setRefreshPage}
                  paymentMethod={paymentMethod}
                  selectedId={selectedId}
                />
              )}

              {deletModal && (
                <YesNo
                  show={deletModal}
                  setShow={setDeletModal}
                  title="Delete this payment method"
                  msg="Are you sure you want to delete this payment method?"
                  // color='red'
                  icon={error}
                  child={false}
                  handleAction={removePaymentMethod}
                  currentId={deletedPaymentId}
                />
              )}
              <div className="row">
                <div className="col-lg-11 col-md-12">
                  <hr className="paymen-hr" />
                  {storeNew.payment_method_type_id !== "2" ? (
                    <div className="mt-2">
                      {allRows.map((payment) => {
                        const {
                          id,
                          logo,
                          number,
                          expireDate,
                          method,
                        } = payment;
                        return (
                          <div
                            className="row width-subtitle-payment gx-0"
                            key={id}
                          >
                            <div
                              key={id}
                              className="payment-container py-3 mt-2 brown-border d-flex col-lg-9 col-md-12 widthresp"
                            >
                              <input
                                onChange={(e) => {
                                  e.preventDefault();
                                  dispatch(handleIds(e.target.value));
                                }}
                                defaultChecked={paymentM === id ? true : false}
                                className="form-check-input form-check-input2 sizeCheck"
                                type="radio"
                                name="radio-button"
                                id="flexCheckDefault"
                                value={id}
                              />

                              <div className="paymentLogoContainer  me-4 ms-2 ">
                                <img src={logo} alt="logo" className="gap-2" />
                              </div>

                              <div className="gx-0 payment-text  numberStylePay me-3 fontSize">
                                {number}
                              </div>
                              <div className="payment-text  widthExpire me-3 fontSize">
                                {expireDate}
                              </div>

                              <div className="d-flex gap-2">
                                <Button
                                  onClick={() => handleEdit(id, method)}
                                  className="bg-transparent no-border p-0"
                                >
                                  <img className="homepage" src={copy} alt="" />
                                </Button>
                                <Button
                                  onClick={() => {
                                    setDeletModal(true);
                                    dispatch(handleDelete(id));
                                  }}
                                  className="bg-transparent no-border p-0 "
                                >
                                  <img src={close} alt="" />
                                </Button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className=" color-secondary payment-button mt-2 d-flex justify-content-center col-lg-9 col-md-12 widthresp">
                        <Button
                          type="button"
                          onClick={() => {
                            setModalOpen(true);
                          }}
                          className="bg-transparent no-border brownText"
                        >
                          <img className=" py-2 me-2" src={plus} alt="" />
                          Add new payment method
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="payment-container py-3 mt-2 brown-border col-lg-9 col-md-12 widthresp text-center CODText">
                      Credit On Delivery
                    </div>
                  )}
                  {/*  */}
                  <div className="justify-content-between align-item-end d-flex">
                    <div></div>

                    {storeNew.payment_method_type_id !== "2" ? (
                      <div className="me-0 ">
                        <ToggleNext
                          eventKey="2"
                          className="row-buttonpay"
                          // disabled={!paymentChosen}
                        >
                          Next
                        </ToggleNext>
                      </div>
                    ) : (
                      <div
                        className="me-0 "
                        onClick={() => {
                          dispatch(setpaymentCOD(true));
                        }}
                      >
                        <ToggleNext
                          eventKey="2"
                          className="row-buttonpay"
                          // disabled={!paymentChosen}
                        >
                          Next
                        </ToggleNext>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      )}
    </>
  );
}
