import React from "react";
import { Button, Container, Tabs, Tab } from "react-bootstrap";
import BankAccount from "./BankAccount";
import Credit from "./Credit";
import Header from "../../../../shared/HeaderModal";

function AddPaymentModal({ setModalOpen }) {
  return (
    <Container className="py-4 absolute-pos z-index2">
      <div className="center paymentModal">
        <div className=" modal-dialog ms-0 mt-0 form-dialog">
          <div className="modal-content auto-modal">
            <div className="modal-header ms-2">
              <Header
                title="Add Payment Method"
                subtitle="Please provide us with the credit/debit information along with the address"
              />
              <Button
                type="button"
                className="btn-close me-1 btn-hover"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setModalOpen(false);
                }}></Button>
            </div>

            <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3 ms-4 me-4">
              <Tab eventKey="profile" title="CREDIT/DEBIT CARD">
                <Credit />
              </Tab>

              <Tab eventKey="home" title="BANK ACCOUNT">
                <BankAccount />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default AddPaymentModal;
