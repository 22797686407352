import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


import {
  getOrderFactoryThunk,
  getOrderThunk,
  createOrderThunk,
  updateOrderThunk,
  cancelOrderThunk,
  removeOrderThunk,
  exportOrderFactoryThunk,
  downloadOrderThunk,
} from "./orderThunk";
const initialFiltersState = {
  search: "",
  per_page: 10,
  from: "",
  to: "",
  sort: "desc",
  orderby: "id",
};

const initialState = {
  errorsss:'',
  orderId: null,
  isLoading: false,
  orders: null,
  links: null,
  meta: null,
  status: null,
  endsession: false,
  cancelOrderId: null,
  factory_status: null,
  ...initialFiltersState,
  image: null,
  color: "",
  size: "",
  formatted_price: null,
  type: "",
  item_type: "",
description: '',
  colorLabel: "",
  sizeLabel: "",
  purchase_order_number: "",
  address: "",
  sales_order_number: "",
  created_at: "",
  msrp: "",
  estimated_months : '',
  estimated_days : '',
  showAll: false,
  store_status: '',
  PurchaseOrderValues: false,
  notess:null,
  custom_size_details:'',
  bust: '',
  waist: '',
  hollow: '',
  hips: '',
  wholesalePriceDress: '',
};

export const getFactoryOrder = createAsyncThunk(
  "order/getFactoryOrder",
  async (orders, thunkAPI) => {
    if (orders.status != null) {
      return getOrderFactoryThunk(
        `api/stores/${orders.id}/orders?from=${orders.from}&to=${orders.to}&status[]=${orders.status}&order_dir=${orders.sort}&order_by=${orders.orderby}`,
        thunkAPI
      );
    }
    if (orders.search) {
      return getOrderFactoryThunk(
        `api/stores/${orders.id}/orders?order_dir=${orders.sort}&order_by=${orders.orderby}&search=${orders.search}`,
        thunkAPI
      );
    }
    return getOrderFactoryThunk(
      `api/stores/${orders.id}/orders?from=${orders.from}&to=${orders.to}&order_dir=${orders.sort}&order_by=${orders.orderby}`,
      thunkAPI
    );
  }
);

export const getOrder = createAsyncThunk(
  "order/getOrder",
  async (order, thunkAPI) => {
    return getOrderThunk("api/orders/" + order.id, thunkAPI);
  }
);

export const exportFactoryOrders = createAsyncThunk(
  "order/exportFactoryDresses",
  async (orders, thunkAPI) => {
    console.log('ORDERS',orders)
    return exportOrderFactoryThunk(
      `/api/stores/${orders.id}/orders/export?type=${orders.type}`,
      {
        responseType: "blob",
      },
      thunkAPI
    );
  }
);

export const downloadOrder = createAsyncThunk(
  "order/downloadOrder",
  async (orderId, thunkAPI) => {
    return downloadOrderThunk(
      `api/orders/${orderId}/download`,
      {
        responseType: "blob",
      },
      thunkAPI
    );
  }
);

export const createOrder = createAsyncThunk(
  "order/createOrder",
  async (orders, thunkAPI) => {
    return createOrderThunk("api/orders", orders, thunkAPI);
  }
);

export const updateOrder = createAsyncThunk(
  "order/updateOrder",
  async (orders, thunkAPI) => {
    return updateOrderThunk("api/stores" + orders.id, thunkAPI);
  }
);

export const cancelOrder = createAsyncThunk(
  "order/cancelOrder",
  async (orders, thunkAPI) => {
    return cancelOrderThunk(
      `api/stores/${orders.cancelId}/cancel`,
      { _method: "PUT" },
      thunkAPI
    );
  }
);

export const removeOrder = createAsyncThunk(
  "order/removeOrder",
  async (orders, thunkAPI) => {
    return removeOrderThunk("api/stores/" + orders.id, thunkAPI);
  }
);

export const getOrderPaginate = createAsyncThunk(
  "order/getOrderPaginate",
  async (link, thunkAPI) => {
    return getOrderFactoryThunk(link, thunkAPI);
  }
);

const OrderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    handleSort: (state, { payload }) => {
      state.orderby = payload.value;
      if (payload.sort === "desc") {
        state.sort = "asc";
      }
      if (payload.sort === "asc") {
        state.sort = "desc";
      }
    },
    handleCancel: (state, { payload }) => {
      state.cancelOrderId = payload;
    },
    handleFilter: (state, { payload }) => {
      console.log("handlefilter", payload.status);
      if (payload.status) {
        state.status = payload.status;
      }
      if (payload.from) {
        state.from = payload.from;
      }
      if (payload.to) {
        state.to = payload.to;
      }
    },
    SetOrderNumber: (state, { payload }) => {
      const { order, purchase } = payload;
      state.type = order;
      state.purchase_order_number = purchase;
    },
  
    handleSearch: (state, { payload }) => {
      state.search = payload.search;
    },
    setValues: (state, { payload }) => {
      state.PurchaseOrderValues = payload;
    },

    clearOrderValues: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: {
    [getFactoryOrder.pending]: (state) => {
      state.isLoading = true;
    },
    [getFactoryOrder.fulfilled]: (state, { payload }) => {
      const { orders, meta, links } = payload;
      state.isLoading = false;
      state.orders = orders;
      state.links = links;
      state.meta = meta;
    },
    [getFactoryOrder.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },
    [downloadOrder.pending]: (state) => {
      state.isLoading = true;
    },
    [downloadOrder.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      
    },
    [downloadOrder.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [getOrderPaginate.pending]: (state) => {
      state.isLoading = true;
    },
    [getOrderPaginate.fulfilled]: (state, { payload }) => {
      const { order, links, meta } = payload;
      state.isLoading = false;
      state.order = order;
      state.links = links;
      state.meta = meta;
    },
    [getOrderPaginate.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },

    [exportFactoryOrders.pending]: (state) => {
      state.isLoading = true;
    },
    [exportFactoryOrders.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [exportFactoryOrders.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },

    [getOrder.pending]: (state) => {
      state.isLoading = true;
    },
    [getOrder.fulfilled]: (state, { payload }) => {
      const {
        orderable,
        store_status,
        wear_date,
        color,
        size,
        id,
        formatted_wholesale_price,
        orderable_type_id,
        purchase_order_number,
        type,
        address,
        sales_order_number,
        created_at,
        notes,
        custom_size_details,
        estimated_ship_date,
        hollow,
        hips,
        waist,
        bust,
      } = payload;
      const { image, style_number, name, msrp, estimated_months, estimated_days,description } = orderable;
      state.isLoading = false;
      state.order = payload;
      state.image = image;
      state.style_number = style_number;
      state.name = name;
      state.hips = hips;
      state.waist = waist;
      state.hollow = hollow;
      state.bust = bust;
      state.sales_order_number = sales_order_number;
      state.wear_date = wear_date;
      state.color = color ? color.id : '';
      state.size = size ? size.id : '';
      state.colorLabel = color ? color.label : '';
      state.sizeLabel = size ? size.label : '';
      state.created_at = created_at;
      state.orderId = id;
      // state.type = orderable_type_id;
      state.item_type = orderable_type_id;
      state.formatted_price = formatted_wholesale_price;
      state.address = address;
      state.purchase_order_number = purchase_order_number;
      state.type = type;
      state.msrp = msrp;
      state.wholesalePriceDress = payload.formatted_wholesale_price;
      state.estimated_months = estimated_months;
      state.estimated_days = estimated_days;
      state.description = description;
      state.store_status = store_status;
      state.notess =notes==null?'No Notes':notes
      state.custom_size_detailss = custom_size_details==null?'No Custom Sizing':custom_size_details
      state.estimated_ship_date=estimated_ship_date;
    },
    [getOrder.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },

    [createOrder.pending]: (state) => {
      state.isLoading = true;
    },
    [createOrder.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.showAll = !state.showAll;
      console.log('true',payload)
      state.errorsss=''
    },
    [createOrder.rejected]: (state, { payload }) => {
      state.isLoading = false;
      console.log('error',payload)
      state.errorsss=payload.response
      console.log('testing',state.errorsss)
    },

    [updateOrder.pending]: (state) => {
      state.isLoading = true;
    },
    [updateOrder.fulfilled]: (state, { payload }) => {
      const { order } = payload;
      state.isLoading = false;
      state.order = order;
    },
    [updateOrder.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },

    [cancelOrder.pending]: (state) => {
      state.isLoading = true;
    },
    [cancelOrder.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.status = 5;
    },
    [cancelOrder.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [removeOrder.pending]: (state) => {
      state.isLoading = true;
    },
    [removeOrder.fulfilled]: (state, { payload }) => {
      const { order } = payload;
      state.isLoading = false;
      state.order = order;
    },
    [removeOrder.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },
  },
});

export const {
  handleSort,
  clearOrderValues,
  handleCancel,
  handleFilter,
  handleViewImage,
  handleSearch,
  SetOrderNumber,
  setValues
} = OrderSlice.actions;
export default OrderSlice.reducer;
