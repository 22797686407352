import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";

export const getStoreAddressesThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.get(url);
    return resp.data.data;
  } catch (error) {
    checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const getAddressThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.get(url);
    return resp.data.data;
  } catch (error) {
    checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const createAddressThunk = async (address, thunkAPI) => {
  try {
    const resp = await customFetch.post("/api/addresses", address);
    //thunkAPI.dispatch(clearValues());
    console.log(resp.data);
    return resp.data;
  } catch (error) {
    checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const updateAddressThunk = async (
  { addressId, NewAddress },
  thunkAPI
) => {
  try {
    const resp = await customFetch.patch(
      `/api/addresses/${addressId}`,
      NewAddress
    );
    //thunkAPI.dispatch(clearValues());
    console.log(resp);
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const removeAddressThunk = async (url, address, thunkAPI) => {
  try {
    const resp = await customFetch.delete(url, address);
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
