import customFetch from '../../utils/axios';

export const getStoreThunk = async (url, store, thunkAPI) => {
    try {
      const resp = await customFetch.get(url, store);
      return resp.data.data;
    } catch (error) {
      console.log(error.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  };

