import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAddressThunk,
  getStoreAddressesThunk,
  createAddressThunk,
  updateAddressThunk,
  removeAddressThunk,
} from "./addressThunk";
const initialState = {
  isLoading: false,
  isSidebarOpen: false,
  addresses: [],
  selectedAddresses: [],
  links: null,
  link: null,
  meta: null,
  sort: "desc",
  orderby: "id",
  factory_code: 1,
  endsession: false,
  deletedAddressId: "",
  isAddressDeleted: false,
  address: "",
  SelectedAddressId: "",
  full_name: "",
  street: "",
  addresState: "",
  city: "",
  zip: "",
  country: "",
  phone: "",
  SelectedId: "",
  showAll:false,
};

export const getStoreAddresses = createAsyncThunk(
  "address/getStoreAddresses",
  async (store, thunkAPI) => {
    return getStoreAddressesThunk(
      `/api/stores/${store.id}/addresses?orderby=created_at&order_dir=desc`,
      thunkAPI
    );
  }
);

export const getAddress = createAsyncThunk(
  "address/getAddress",
  async (address, thunkAPI) => {
    return getAddressThunk(`/api/addresses/${address.id}`, thunkAPI);
  }
);

export const createAddress = createAsyncThunk(
  "address/createAddress",
  createAddressThunk
);

export const updateAddress = createAsyncThunk(
  "address/updateAddress",
  updateAddressThunk
);

export const removeAddress = createAsyncThunk(
  "address/removeAddress",
  async (id, thunkAPI) => {
    return removeAddressThunk("/api/addresses/" + id, thunkAPI);
  }
);

const addressSlice = createSlice({
  name: "addresses",
  initialState,
  reducers: {
    handleSelectedAddresses: (state, payload) => {
      state.selectedAddresses = payload.payload;
    },
    clearAddressesValues: (state, payload) => {
      return {
        ...initialState,
      };
    },

    handleChange: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    handleSort: (state, { payload }) => {
      state.orderby = payload.value;
      if (payload.sort === "desc") {
        state.sort = "asc";
      }
      if (payload.sort === "asc") {
        state.sort = "desc";
      }
    },
    handleDelete: (state, { payload }) => {
      state.deletedAddressId = payload;
    },
    handleSelectedAddress: (state, { payload }) => {
      state.SelectedAddressId = payload;
    },
    handleCustomAddress: (state, { payload }) => {
      state.SelectedId = payload;
    },
    // SetAddress: (state, { payload }) => {
    //   state.full_name = payload[0].full_name;
    //   state.street = payload[0].street;
    //   state.addresState = payload[0].state;
    //   state.city = payload[0].city;
    //   state.zip = payload[0].zip;
    //   state.country = payload[0].country;
    //   state.phone = payload[0].phone;

    // },
  },
  extraReducers: {
    [getStoreAddresses.pending]: (state) => {
      state.isLoading = true;
    },
    [getStoreAddresses.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.addresses = payload;
    },
    [getStoreAddresses.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [getAddress.pending]: (state) => {
      // state.isLoading = true;
    },
    [getAddress.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.address = payload;
    },
    [getAddress.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [createAddress.pending]: (state) => {
      state.isLoading = true;
    },
    [createAddress.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.showAll= !state.showAll;
    },
    [createAddress.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [updateAddress.pending]: (state) => {
      state.isLoading = true;
    },
    [updateAddress.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.addresses = payload.addresses;
    },
    [updateAddress.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [removeAddress.pending]: (state) => {
      state.isLoading = true;
      state.isAddressDeleted = false;
    },
    [removeAddress.fulfilled]: (state, { payload }) => {
      state.isAddressDeleted = true;
      state.isLoading = false;
    },
    [removeAddress.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
  },
});

export const {
  handleSelectedAddresses,
  clearAddressesValues,
  handleSearch,
  handleSort,
  handleChange,
  handleDelete,
  handleSelectedAddress,
  SetAddress,
  handleCustomAddress,
} = addressSlice.actions;
export default addressSlice.reducer;
