import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addCustomItemToLocalStorage } from "../../utils/localStorage";

import {
  getCustomItemsThunk,
  getCustomItemThunk,
  removeCustomItemThunk,
  exportCustomItemThunk,
} from "./customItemThunk";

const initialState = {
  currentId: null,
  isLoading: false,
  custom_items: null,
  links: null,
  meta: null,
  sort: "desc",
  orderby: "id",
  endsession: false,
  search: "",
  image: "",
  name: "",
  price: "",
  date: "",
  item: "",
  estimated_months: "",
  estimated_days: "",
  allCustomItems:[],
};

export const getCustomItemFilter = createAsyncThunk(
  "dress/getCustomItemFilter",
  async (dress, thunkAPI) => {
    return getCustomItemThunk(
      `/api/custom-items/published?per_page=-1`,
      thunkAPI
    );
  }
);

export const getCustomItems = createAsyncThunk(
  "customItem/getCustomItems",
  async (custom_items, thunkAPI) => {
    return getCustomItemsThunk(
      `/api/custom-items/published?search=${custom_items.search}&order_dir=${custom_items.sort}&order_by=${custom_items.orderby}`,
      thunkAPI
    );
  }
);

export const getCustomItem = createAsyncThunk(
  "customItem/getCustomItem",
  async (Id, thunkAPI) => {
    return getCustomItemThunk("/api/custom-items/" + Id, thunkAPI);
  }
);

export const exportCustomItems = createAsyncThunk(
  "customItem/exportCustomItems",
  async (custom_items, thunkAPI) => {
    return exportCustomItemThunk(
      `/api/custom-items/export?search=${custom_items.search}&order_dir=${custom_items.sort}&order_by=${custom_items.orderby}`,
      {
        responseType: "blob",
      },
      thunkAPI
    );
  }
);

export const removeCustomItem = createAsyncThunk(
  "customItem/removeCustomItem",
  async (custom_items, thunkAPI) => {
    return removeCustomItemThunk(
      "/api/custom-items/" + custom_items.id,
      thunkAPI
    );
  }
);

export const getCustomItemPaginate = createAsyncThunk(
  "customItem/getCustomItemPaginate",
  async (link, thunkAPI) => {
    return getCustomItemsThunk(link, thunkAPI);
  }
);

const CustomItemSlice = createSlice({
  name: "customItem",
  initialState,
  reducers: {
    saveSingleOrder: (state, { payload }) => {
      state.date = payload.date;
      state.order_id = payload.id;
    },
    
    SetOrderNumber: (state, { payload }) => {
      const { order, purchase } = payload;
      state.type = order;
      state.purchase_order_number = purchase;
    },

    handleSearch: (state, { payload }) => {
      //state.search = payload.search;
      console.log("paypload", payload.search);
      console.log("state custom_items",state.custom_items);
    },
    handleSort: (state, { payload }) => {
      if (payload.sort === "desc") {
        state.sort = "asc";
      }
      if (payload.sort === "asc") {
        state.sort = "desc";
      }
    },
    clearCustomItemValues: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: {
    [getCustomItemFilter.pending]: (state) => {
      state.isLoading = true;
      state.custom_items = null;

    },
    [getCustomItemFilter.fulfilled]: (state, { payload }) => {
      const { custom_items, links, meta } = payload;
      state.isLoading = false;
      state.allCustomItems = custom_items;
      state.links = links;
      state.meta = meta;

    },
    [getCustomItemFilter.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },
    [getCustomItems.pending]: (state) => {
      state.isLoading = true;
    },
    [getCustomItems.fulfilled]: (state, { payload }) => {
      console.log('p',payload);
      const { custom_items, links, meta } = payload;
      state.isLoading = false;
      state.custom_items = custom_items;
      state.links = links;
      state.meta = meta;
    },
    [getCustomItems.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [getCustomItemPaginate.pending]: (state) => {
      state.isLoading = true;
    },
    [getCustomItemPaginate.fulfilled]: (state, { payload }) => {
      const { custom_items, links, meta } = payload;
      state.isLoading = false;
      state.custom_items = custom_items;
      state.links = links;
      state.meta = meta;
    },
    [getCustomItemPaginate.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    [exportCustomItems.pending]: (state) => {
      state.isLoading = true;
    },
    [exportCustomItems.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [exportCustomItems.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },

    [getCustomItem.pending]: (state) => {
      state.isLoading = true;
    },
    [getCustomItem.fulfilled]: (state, { payload }) => {
      const { image, name, formatted_wholesale_price } = payload;
      state.item = payload;
      state.isLoading = false;
      state.image = image;
      state.name = name;
      state.price = formatted_wholesale_price;
      state.estimated_days = payload.estimated_days;
      state.estimated_months = payload.estimated_months;
    },
    [getCustomItem.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },

    [removeCustomItem.pending]: (state) => {
      state.isLoading = true;
    },
    [removeCustomItem.fulfilled]: (state, { payload }) => {
      const { custom_items } = payload;
      state.isLoading = false;
      state.custom_items = custom_items;
      addCustomItemToLocalStorage(custom_items);
    },
    [removeCustomItem.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },
  },
});

export const {
  handleEdit,
  handleSave,
  handleSearch,
  handleUploadImage,
  handleViewImage,
  handleSort,
  SetOrderNumber,
  clearCustomItemValues,
  saveSingleOrder,
} = CustomItemSlice.actions;
export default CustomItemSlice.reducer;
