import React, { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { resetPassword } from "../../features/user/userSlice";
import LoginLogo from "../../assets/img/LoginLogo.svg";
import Bridal from "../../assets/img/BridalImg.jpg";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { token } = useParams();

  const { user, isLoading, isResetSuccess } = useSelector(
    (store) => store.user
  );
  useEffect(() => {
    if (user) {
      navigate("/recent-orders");
    }
  }, [user]);
  const validation = useFormik({
    initialValues: {
      password: "",
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("The email you entered is invalid!")
        .required("This Field is required!"),

      password: Yup.string()
        .required("This Field is required")
        .min(8, "Password is too short - should be 8 chars minimum."),
    }),
    onSubmit: (values) => {
      dispatch(
        resetPassword({
          email: values.email,
          password: values.password,
          token: token,
        })
      );
      //  add dispatch clear values of all feature (when they are created) here
    },
  });
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 login-bg">
          <img src={Bridal} alt="bride" className="bride-img" />
        </div>
        <div className="col-6 py-5 login-form">
          <img src={LoginLogo} alt="logo" className="img-size" />

          <p className="desc pb-lg-4">Please reset your password</p>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
            }}
          >
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                className="input-lineBorder mb-lg-4 ps-1"
                type="email"
                name="email"
                placeholder="Username"
                value={validation.values.email}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
              />
              {validation.touched.email && validation.errors.email ? (
                <p className="error d-flex flex-row-reverse ">
                  {validation.errors.email}
                </p>
              ) : null}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control
                className="input-lineBorder mb-lg-5 ps-1"
                type="password"
                placeholder="Password"
                name="password"
                value={validation.values.password}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
              />
              {validation.touched.password && validation.errors.password ? (
                <p className="error d-flex flex-row-reverse">
                  {validation.errors.password}
                </p>
              ) : null}
            </Form.Group>

            <Button
              className="btn-main mt-lg-3"
              variant="primary"
              type="submit"
            >
              {isLoading ? "loading..." : "Reset"}
            </Button>
            {isResetSuccess && navigate("/login")}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
