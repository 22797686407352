import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import copy from "../../assets/img/copy.svg";
import plus from "../../assets/img/brown-plus.svg";
import close from "../../assets/img/close.svg";
import visa from "../../assets/img/visa.png";
import bank from "../../assets/img/building.svg";
import AddStorePaymentModal from "./addStorePaymentMethodModal";
import EditStorePaymentModal from "./addStorePaymentMethodModal";
import error from "../../assets/img/error.svg";
import YesNo from "../shared/modals/YesNo";
import Loading from "../shared/Loading";
import LoginLogo from "../../assets/img/LoginLogo.svg";
import { logoutUser } from "../../features/user/userSlice";
import { NavLink } from "react-router-dom";
import Logout from "../../assets/img/Logout.svg";

import {
  getPaymentMethods,
  removePaymentMethod,
  handleDelete,
  getPayment,
  clearPaymentValues,
  handleIds,
} from "../../features/payment/paymentSlice";
import { useDispatch, useSelector } from "react-redux";
import { getStore, getStoreId } from "../../features/store/storeSlice";
import { formatStaxDate } from "../../helpers/helpers";

export default function StorePayment() {
  const dispatch = useDispatch();
  const [storeIdNew, setStoreIdNew] = useState();

  const [modalOpen, setModalOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [deletModal, setDeletModal] = useState(false);
  const [allRows, setAllRows] = useState([]);
  const [deleteId, setDeleteID] = useState("");
  const [selectedId, setSelectedId] = useState("");

  const { paymentMethods, deletedPaymentId, isLoading } = useSelector(
    (store) => store.payment
  );
  const { store_stax_customer_id, storeId } = useSelector(
    (store) => store.stores
  );

  useEffect(() => {
    setStoreIdNew(JSON.parse(window.localStorage.getItem("storeId")));
  }, []);

  const [RefreshPage, setRefreshPage] = useState(false);
  useEffect(() => {
    dispatch(getStoreId());
  }, []);

  const handleEdit = (id, method) => {
    if (id) {
      dispatch(getPayment(id));
    }
    dispatch(clearPaymentValues);
    setSelectedId(id);
    setPaymentMethod(method);
    setEditModal(true);
  };

  useEffect(() => {
    if (storeId) {
      dispatch(getStore({ id: storeIdNew }));
    }
  }, [storeId]);

  useEffect(() => {
    if (storeIdNew) {
      dispatch(getPaymentMethods(storeIdNew));
    }
  }, [storeIdNew]);

  useEffect(() => {
    console.log(storeIdNew);
  }, [storeIdNew]);

  useEffect(() => {
    setDeleteID(deleteId);
  }, [deletedPaymentId]);

  useEffect(() => {
    console.log("k", paymentMethods.data);
    if (paymentMethods.data) {
      let paymentMethodsArray = [];
      paymentMethods.data.map((paymentMethod, count) => {
        if (count === 0) {
          dispatch(handleIds(paymentMethod.id));
        }
        return paymentMethodsArray.push({
          count: count,
          id: paymentMethod.id,
          method: paymentMethod.method,
          logo: paymentMethod.method === "card" ? visa : bank,
          number: `**** **** **** ${paymentMethod.card_last_four}`,
          expireDate:
            paymentMethod.method === "card"
              ? `Expires on ${formatStaxDate(paymentMethod.card_exp_datetime)}`
              : "",
        });
      });
      setAllRows(paymentMethodsArray);
    }
  }, [paymentMethods, RefreshPage]);

  return (
    <>
      <div className="container px-0">
        <div className="d-flex justify-content-between mt-5">
          <div>
            <img src={LoginLogo} alt="logo" className="img-size" />
          </div>

          <div className=" d-flex">
            <div className="logout-logo">
              <img src={Logout} alt="logo" />
            </div>
            <NavLink to="/login-new" className={"text-decoration-none"}>
              <p
                className="sign-text ms-3 text-decoration-none"
                onClick={(e) => {
                  dispatch(logoutUser());
                }}
              >
                SIGN OUT
              </p>
            </NavLink>
          </div>
        </div>
        <div className="bg-color">
          <p className="title-text text-light pt-3 ps-4">Payments</p>
          <p className="subtitle-text text-light pb-3 ps-4">
            Please Add a Payment Method For The Store{" "}
          </p>
        </div>
      </div>

      {isLoading && <Loading />}
      {!isLoading && (
        <>
          {modalOpen && (
            <AddStorePaymentModal
              setModalOpen={setModalOpen}
              setRefreshPage={setRefreshPage}
              storeId={storeId}
              store_stax_customer_id={store_stax_customer_id}
              beta={1}
            />
          )}
          {editModal && (
            <EditStorePaymentModal
              setModalOpen={setEditModal}
              setRefreshPage={setRefreshPage}
              paymentMethod={paymentMethod}
              selectedId={selectedId}
              storeId={storeId}
              store_stax_customer_id={store_stax_customer_id}
            />
          )}

          {deletModal && (
            <YesNo
              show={deletModal}
              setShow={setDeletModal}
              title="Delete this payment method"
              msg="Are you sure you want to delete this payment method?"
              // color='red'

              icon={error}
              child={false}
              handleAction={removePaymentMethod}
              currentId={deletedPaymentId}
            />
          )}

          <div className="mt-2" style={{ marginLeft: "10%" }}>
            {allRows.map((payment) => {
              const { id, logo, number, expireDate, method } = payment;
              return (
                <div
                  key={id}
                  className="grid-row-border ps-3 py-3 me-5  d-flex "
                >
                  <div className="row align-items-center d-flex width-pay">
                    <div className="form-check col-lg-2 col-md-2">
                      <img src={logo} alt="logo"></img>
                    </div>

                    <div className="col-lg-4 col-md-4 ">{number}</div>
                    <div className="col-lg-3 col-md-3  grid-cell-body me-lg-4">
                      {expireDate}
                    </div>
                    <div className="col-lg-2 col-md-2 py-1 grid-cell-body ms-lg-5 ">
                      <div className="col-lg-2 col-md-2 d-flex gap-2">
                        <Button
                          onClick={() => handleEdit(id, method)}
                          className="bg-transparent no-border p-0"
                        >
                          <img className="homepage" src={copy} alt="" />
                        </Button>
                        <Button
                          onClick={() => {
                            setDeletModal(true);
                            dispatch(handleDelete(id));
                          }}
                          className="bg-transparent no-border p-0 "
                        >
                          <img src={close} alt="" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="row color-secondary payment-button mt-2 button-payment2">
              <Button
                type="button"
                onClick={() => {
                  setModalOpen(true);
                }}
                className=" bg-transparent no-border  brownText "
              >
                <img className="margin-right-2 py-2 " src={plus} alt="" />
                Add new payment method
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
