import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";
import fileDownload from "js-file-download";
export const getDressesThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.get(url);

    return resp.data.data;
  } catch (error) {
    checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const exportDressesThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.get(url);
    fileDownload(resp.data, "dresses.xlsx");
    return "Dresses exported successfully";
  } catch (error) {
    checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const getDressThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.get(url);
    return resp.data.data;
  } catch (error) {
    checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const createDressThunk = async (dress, thunkAPI) => {
  try {
    const resp = await customFetch.post("/api/dresses", dress);
    return resp.data.msg;
  } catch (error) {
    checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const updateDressThunk = async ({ dressId, dress }, thunkAPI) => {
  try {
    const resp = await customFetch.patch(`/api/dresses/${dressId}`, dress);
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const removeDressThunk = async (url, dress, thunkAPI) => {
  try {
    const resp = await customFetch.delete(url, dress);
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
