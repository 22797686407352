import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import close from "../../../assets/img/close.svg";
import copy from "../../../assets/img/copy.svg";

export default function SelectedInvoicesPay({
  data,
  CustomToggle,
  ToggleNext,
}) {
  console.log(data);
  return (
    <>
      <div className="container Content-box ">
        <Card className="card mb-2 pe-5">
          <Card.Header className="bg-white">
            <CustomToggle
              eventKey="0"
              title="Selected Invoices To Pay"
              subtitle="Select or add preferred shipping address"
            ></CustomToggle>
          </Card.Header>

          <Accordion.Collapse eventKey="0">
            <Card.Body className="d-flex row g-0 ms-5">
              <div className="col-lg-11">
                <hr />
              </div>
              <div className="col-lg-11 col-md-4 col-11 address-container pb-4 mt-3">
                <div className="d-flex justify-content-between">
                  <div className="row  col-8 m-4 mb-0">
                    <div className="col">
                      <div className="invoices-titles">invoice #</div>
                      <div className="paragraph">Invoice #{data.id}</div>
                    </div>

                    <div className="col">
                      <div className="invoices-titles">date</div>
                      <div className="paragraph">{data.date}</div>
                    </div>
                    <div className="col">
                      <div className=" invoices-titles">Amount Charged</div>
                      <div className="paragraph">{data.price}</div>
                    </div>
                  </div>

                  <div className="mt-4 me-3">
                    <Button className="bg-transparent no-border p-0 margin-right-2">
                      <img src={copy} alt="" />
                    </Button>
                    <Button className="bg-transparent no-border p-0">
                      <img src={close} alt="" />
                    </Button>
                  </div>
                </div>
              </div>

              <div className="col-11 d-flex flex-row-reverse ">
                <div>
                  <ToggleNext eventKey="1">Next</ToggleNext>
                </div>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </div>
    </>
  );
}
