import React from "react";
import { Container, Form, Button } from "react-bootstrap";
import FormInput from "../../../../shared/FormInput";
import * as Yup from "yup";
import { useFormik } from "formik";

function Credit() {
  const validation = useFormik({
    initialValues: {
      card: "",
      month: "",
      year: "",
      cvv: "",
      name: "",
      street: "",
      city: "",
      state: "",
      country: "",
      code: "",
      number: "",
    },
    validationSchema: Yup.object({
      card: Yup.number()
        .typeError("This field must be a number")
        .required("This Field is required!"),
      month: Yup.number()
        .typeError("This field must be a number")
        .required("Required!"),
      year: Yup.number()
        .typeError("This field must be a number")
        .required("Required!"),
      cvv: Yup.number()
        .typeError("This field must be a number")
        .required("Required!"),
      name: Yup.string().required("This Field is required!"),
      street: Yup.string().required("This Field is required!"),
      city: Yup.string().required("This Field is required!"),
      state: Yup.string().required("This Field is required!"),
      country: Yup.string().required("This Field is required!"),
      code: Yup.number()
        .typeError("This field must be a number")
        .required("This Field is required!"),

      number: Yup.number()
        .typeError("This field must be a number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .required("This Field is required!"),
    }),
    onSubmit: (values) => {
      console.log("values", values);
    },
  });
  return (
    <Container className="py-4">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
        }}
        className="row g-3"
      >
        <div className="col-md-6">
          <FormInput
            type="text"
            name="card"
            labelText="CREDIT CARD NUMBER *"
            placeholder="Enter Card Number"
            value={validation.values.card}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
          {validation.touched.card && validation.errors.card ? (
            <p className="error d-flex flex-row-reverse pt-1">
              {validation.errors.card}
            </p>
          ) : null}
        </div>

        <div className="col-md-3">
          <div className="row">
            <div className="col-md-6">
              <FormInput
                type="text"
                name="month"
                labelText=" EXPIRATION"
                placeholder="MM"
                value={validation.values.month}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
              />
              {validation.touched.month && validation.errors.month ? (
                <p className="error d-flex flex-row-reverse pt-1">
                  {validation.errors.month}
                </p>
              ) : null}
            </div>
            <div className="col-md-6">
              <FormInput
                type="text"
                name="year"
                labelText="DATE *"
                placeholder="YY"
                value={validation.values.year}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
              />
              {validation.touched.year && validation.errors.year ? (
                <p className="error d-flex flex-row-reverse pt-1">
                  {validation.errors.year}
                </p>
              ) : null}
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="row">
            <div className="col-md-6">
              <FormInput
                type="text"
                name="cvv"
                labelText="CVV *"
                placeholder="Code"
                className="col-md-6"
                value={validation.values.cvv}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
              />
              {validation.touched.cvv && validation.errors.cvv ? (
                <p className="error d-flex flex-row-reverse pt-1">
                  {validation.errors.cvv}
                </p>
              ) : null}
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <FormInput
            type="text"
            name="name"
            labelText="NAME ON CARD *"
            placeholder="Enter Name On Card"
            className="col-md-6"
            value={validation.values.name}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
          {validation.touched.name && validation.errors.name ? (
            <p className="error d-flex flex-row-reverse pt-1">
              {validation.errors.name}
            </p>
          ) : null}
        </div>
        <div className="col-md-6">
          <FormInput
            type="text"
            name="street"
            labelText="STREET *"
            placeholder="Enter Street Name"
            className="col-md-6"
            value={validation.values.street}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
          {validation.touched.street && validation.errors.street ? (
            <p className="error d-flex flex-row-reverse pt-1">
              {validation.errors.street}
            </p>
          ) : null}
        </div>
        <div className="col-md-6">
          <FormInput
            type="text"
            name="city"
            labelText="CITY *"
            placeholder="Enter City Name"
            className="col-md-6"
            value={validation.values.city}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
          {validation.touched.city && validation.errors.city ? (
            <p className="error d-flex flex-row-reverse pt-1">
              {validation.errors.city}
            </p>
          ) : null}
        </div>
        <div className="col-md-6">
          <FormInput
            type="text"
            name="state"
            labelText="STATE *"
            placeholder="Enter State Name"
            className="col-md-6"
            value={validation.values.state}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
          {validation.touched.state && validation.errors.state ? (
            <p className="error d-flex flex-row-reverse pt-1">
              {validation.errors.state}
            </p>
          ) : null}
        </div>
        <div className="col-md-6">
          <FormInput
            type="text"
            name="country"
            labelText="COUNTRY *"
            placeholder="Enter Country Name"
            className="col-md-6"
            value={validation.values.country}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
          {validation.touched.country && validation.errors.country ? (
            <p className="error d-flex flex-row-reverse pt-1">
              {validation.errors.country}
            </p>
          ) : null}
        </div>
        <div className="col-md-6">
          <FormInput
            type="text"
            name="code"
            labelText="ZIP CODE *"
            placeholder="Enter ZIP Code"
            className="col-md-6"
            value={validation.values.code}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
          {validation.touched.code && validation.errors.code ? (
            <p className="error d-flex flex-row-reverse pt-1">
              {validation.errors.code}
            </p>
          ) : null}
        </div>

        <div className="col-md-6">
          <FormInput
            type="text"
            name="number"
            labelText="PHONE NUMBER *"
            placeholder="Enter Phone Number"
            className="col-md-6"
            value={validation.values.number}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
          {validation.touched.number && validation.errors.number ? (
            <p className="error d-flex flex-row-reverse pt-1">
              {validation.errors.number}
            </p>
          ) : null}
        </div>

        <div className="d-flex flex-row-reverse mt-5">
          <Button className="btn-secondary me-md-2" type="submit">
            save card information
          </Button>
        </div>
      </Form>
    </Container>
  );
}

export default Credit;
