import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";
import fileDownload from "js-file-download";

export const getCustomItemsThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.get(url);
    return resp.data.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const exportCustomItemThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.get(url);
    fileDownload(resp.data, "customItems.xlsx");
    return "Custom Items exported successfully";
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const getCustomItemThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.get(url);
    return resp.data.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const removeCustomItemThunk = async (url, customItem, thunkAPI) => {
  try {
    const resp = await customFetch.delete(url, customItem);
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
