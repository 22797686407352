import React from "react";
import { Container } from "react-bootstrap";
import Image from "../../../assets/img/invoice-summary.png";
export default function InvoicePDF() {
  return (
    <div className="summary-container">
      <img className="Homeimage" src={Image} alt="home" />

      <div className="row px-4 py-4 header-summary ">
        <div className="col-lg ">
          <div className="logoFont-small d-flex ">
            <div className="circle-small circle-summary  me-3"></div>
            <p className="txt-summary py-3">Elissar Bridal</p>
          </div>
        </div>
        <div className="col-lg py-4">
          <p className="sales-order">Invoice</p>
        </div>
      </div>

      <hr className="hr-summary" />

      <div className="row px-4 py-4 info-summary ">
        <div className="col-lg ">
          <div className="info-summary-name">
            Elissar Bridal
            <br />
            2140 E Winstoh Rd.
            <br />
            Anaheim CA 92896
            <br />
            725-582-5888
            <br />
            www.elissarbridal.com
          </div>
        </div>
        <div className="col-lg pb-4">
          <div className="row ">
            <div className="col-lg-4">
              <div className="info-summary-sales">
                Date
                <br />
                Invoice #
                <br />
                Sales Order #
                <br />
                Store name
                <br />
                PO #
                <br />
                Type of Order
              </div>
            </div>
            <div className="col-lg">
              <div className="info-summary-nbr">
                12/8/2021
                <br />
                488259
                <br />
                12/8/2021
                <br />
                Written Name here
                <br />
                Elissar Bridal
                <br />
                Re-Order
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row px-4 py-4 ship-summary ">
        <div className="col-lg-3 ">
          <span className="bill-summary"> Billed to</span>

          <div className="bill-info">
            Jenna in White 2685 Coolidge Hwy
            <br />
            Berkley MI 127563
          </div>
        </div>

        <div className="col-lg ">
          <span className="bill-summary"> Shipped to</span>

          <div className="bill-info">
            jenna in White 4260 Pemberton
            <br />
            Circle Brighton MI 48118
          </div>
        </div>
        <Container className="   border-summary-margin">
          <div className="border  border-grid-summary  py-4  ">
            <div className="row align-items-center py-3 ps-5 ">
              <div className="col-lg   summary-txt   ">STYLE NUM.</div>
              <div className="col-lg    summary-txt   "> DRESS NAME </div>
              <div className="col-lg  summary-txt   ">COLOR</div>
              <div className="col-lg    summary-txt   ">SIZE </div>

              <div className="col-lg  summary-txt  ">MSRP</div>
              <div className="col-lg  summary-txt  ">WEAR DATE</div>
              <div className="col-lg  summary-txt  ">AMOUNT</div>
            </div>

            <div className="row align-items-center ps-5  py-3">
              <div className="col-lg  summary-sol  ">2430-I/S-SPC</div>
              <div className="col-lg summary-sol   ">NAME WRITTEN</div>
              <div className="col-lg  summary-sol   ">IVORY/SILVER</div>
              <div className="col-lg  summary-sol   ">SPC </div>

              <div className="col-lg  summary-sol  ">$1,925.00</div>
              <div className="col-lg summary-sol  ">12/8/2021</div>
              <div className="col-lg  summary-sol   ">$769.00</div>
            </div>
            <hr className="hr-summary-border " />

            <div className="row align-items-center ps-5  py-3 ">
              <div className="col-lg   summary-sol   ">SHIPPING COST</div>
              <div className="col-lg   summary-sol   ">-</div>
              <div className="col-lg  summary-sol   ">-</div>
              <div className="col-lg   summary-sol   ">- </div>

              <div className="col-lg  summary-sol  ">-</div>
              <div className="col-lg  summary-sol  ">-</div>
              <div className="col-lg summary-sol   ">$40.00</div>
            </div>
            <hr className="hr-summary-border" />

              <div className="modal-total-summary-sales">
              <div className="border-total  ">
              <div className="  pe-5 ps-2 pt-4 d-flex justify-content-between ">
              
                        <div className="  total-txt  ps-2">TOTAL</div>
                        <div className=" total-nbr-list pe-3">$1,084.00</div>
                      </div>
                      <div className=" py-4 pe-5 ps-2 d-flex justify-content-between ">
                        <div className="total-txt  ps-2">AMOUNT PAID</div>
                        <div className=" total-amount-list ">$809.00</div>
                      </div>
            </div>
          </div>
         
              </div>
          
        </Container>
      </div>
    </div>
  )
}
