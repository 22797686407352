import React from "react";
import { useDispatch, useSelector } from "react-redux";
import exportIcon from "../../assets/img/export.svg";
import { Button } from "react-bootstrap";

function Export({ label, rootClass, action, num, exportClass }) {
  const dispatch = useDispatch();
  const { store_id } = useSelector((store) => store.store);
  return (
    <>
      <div className={exportClass}>
        <Button
          className="bg-transparent no-border mx-2 background-hover width-200Exp "
          onClick={() => dispatch(action({ id: store_id, type: num }))}
        >
          <img className="margin-right-2 pe-2" src={exportIcon} alt="" />
          {label}
        </Button>
      </div>
    </>
  );
}

export default Export;
