import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getStatesThunk } from './stateThunk';

const initialState = {
  isLoading: false,
  isSidebarOpen: false,
  states: [],
  selectedStates: [],
  links: null,
  link: null,
  meta: null,
  sort: 'desc',
  orderby: 'id',
  factory_code: 1,
  endsession: false,
};

export const getStates = createAsyncThunk(
  'state/getStates',
  async (state, thunkAPI) => {
    return getStatesThunk(`/api/states`, thunkAPI);
  }
);

export const getStatePaginate = createAsyncThunk(
  'state/getStatePaginate',
  async (link, thunkAPI) => {
    return getStatesThunk(link, thunkAPI);
  }
);

const stateSlice = createSlice({
  name: 'states',
  initialState,
  reducers: {
    handleSelectedStates: (state, payload) => {
      state.selectedStates = payload.payload;
    },
    clearStatesValues: (state, payload) => {
      return {
        ...initialState,
      };
    },


    handleChange: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    handleSort: (state, { payload }) => {
      state.orderby = payload.value;
      if (payload.sort === 'desc') {
        state.sort = 'asc';
      }
      if (payload.sort === 'asc') {
        state.sort = 'desc';
      }
    },
  },
  extraReducers: {
    [getStates.pending]: (state) => {
      state.isLoading = true;
    },
    [getStates.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.states = payload;
    },
    [getStates.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },

    [getStatePaginate.pending]: (state) => {
      state.isLoading = true;
    },
    [getStatePaginate.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.states = payload.states;
    },
    [getStatePaginate.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },
  },
});

export const {
  handleSelectedStates,
  clearStatesValues,
  handleSearch,
  handleSort,
  handleChange,
} = stateSlice.actions;
export default stateSlice.reducer;
