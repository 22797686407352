import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Login from "./components/shared/Login";
import MainModal from "./components/shared/MainModal";
import FullScreenLayout from "./components/layouts/FullScreenLayout";
import SidebarLayout from "./components/layouts/SidebarLayout";
import StartOrder from "./components/startOrder/StartOrder";
import SalesOrdersList from "./components/salesOrders/SalesOrdersList";
import InvoicesList from "./components/invoices/InvoicesList";
import ViewCustomOrder from "./components/customItem/ViewCustomOrder";
import TransactionHistoryList from "./components/transactionHistory/TransactionHistoryList";
import ResetPassword from './components/shared/ResetPassword';
import StorePayment from "./components/listOfRetailers/StorePayment";
import UserAccountsList from "./components/settings/UserAccountsList";
import AddUserAccount from "./components/settings/modals/AddUserAccount";
import Error from "./components/invoices/ErrorModal/Error";
import ViewOrder from "./components/startOrder/ViewOrder";
import PaymentMethod from "./components/startOrder/checkout/PaymentMethod";
import SalesOrderSummary from "./components/salesOrders/SalesOrderSummary";
import UserAccounts from "./components/settings/UserAccounts";
import Accordions from "./components/startOrder/checkout/Accordions";
import InvoiceSummary from "./components/invoices/InvoiceSummary";
import MakePayment from "./components/invoices/payment/MakePayment";
import InvoicePDF from "./components/shared/pdf/InvoicePDF";
import SalesOrderPDF from "./components/shared/pdf/SalesOrderPDF";
import ProtectedRoute from "./ProtectedRoute";
import CustomAccordions from "./components/customItem/checkout/CustomAccordions";
import LogInNew from './components/shared/LogInNew'
import ListOfRetailers from "./components/listOfRetailers/ListOfRetailers";
import AddStore from "./components/listOfRetailers/AddStore";
import Forgot from './components/shared/forgot';
import Payments from './components/payment/payment'
import Address from './components/address/address'
import EditStore from './components/listOfRetailers/EditStore';
function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <SidebarLayout />
            </ProtectedRoute>
          }>
          <Route path="/home" element={<MainModal />} />
          <Route path="/start-order" element={<StartOrder />} />
          <Route path="/sales-order" element={<SalesOrdersList />} />
          <Route path="/invoices" element={<InvoicesList />} />
          <Route
            path="/transaction-history"
            element={<TransactionHistoryList />}
          />
          <Route path="/settings" element={<UserAccountsList />} />
          <Route path="/settings/useraccounts" element={<UserAccounts />} />
          <Route path="/ViewOrder/:Id" element={<ViewOrder />} />
          <Route path="/error" element={<Error />} />
          <Route path="/AddUserAccount" element={<AddUserAccount />} />
          <Route path="/payment-method" element={<PaymentMethod />} />
          <Route
            path="/sales-order-summary/:id"
            element={<SalesOrderSummary />}
          />
          <Route path="/sales-order-pdf/:orderId" element={<SalesOrderPDF />} />
          <Route path="/invoices-order-pdf" element={<InvoicePDF />} />
          <Route path="/checkout/:Id" element={<Accordions />} />
          <Route path="/custom-checkout/:Id" element={<CustomAccordions />} />
          <Route path="/invoice-summary/:Id" element={<InvoiceSummary />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/address" element={<Address />} />
          <Route path='/edit-store/:id' element={<EditStore />} />

          <Route path="/make-a-payment" element={<MakePayment />} />
          <Route path="/view-custom-order/:Id" element={<ViewCustomOrder />} />

        </Route>
       

        <Route element={<FullScreenLayout />}>
          <Route path="/login-new" element={<LogInNew />} />
        </Route>
         <Route path="/store-payment" element={<StorePayment />} />
        <Route path="/list-of-retailers" element={<ListOfRetailers />} />
        <Route path="/add-store" element={<AddStore />} />
        
        <Route element={<FullScreenLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<Forgot />} />
           <Route path='/reset-password' element={<ResetPassword />} />
           <Route path='/edit-new-store/:id' element={<EditStore />} />
        </Route>


      </Routes>
       
    </div>
  );
}

export default App;
