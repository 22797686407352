import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";
import fileDownload from "js-file-download";

export const exportOrderFactoryThunk = async (url, order, thunkAPI) => {
  console.log("url", url);
  try {
    const resp = await customFetch.get(url, order);
    fileDownload(resp.data, "orders.xlsx");
    return "Orders exported successfully";
  } catch (error) {
    checkForUnauthorizedResponse(error, thunkAPI);
  }
};
export const downloadOrderThunk = async (url, order, thunkAPI) => {
  console.log("url", url);
  try {
    const resp = await customFetch.get(url, order);
    fileDownload(resp.data, "orders.pdf");
    return "Orders downloaded successfully";
  } catch (error) {
    checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const getOrderFactoryThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.get(url);
    return resp.data.data;
  } catch (error) {
    checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const getOrderThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.get(url);
    return resp.data.data;
  } catch (error) {
    checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const createOrderThunk = async (url, order, thunkAPI) => {
  try {
    const resp = await customFetch.post(url, order);
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const updateOrderThunk = async (url, order, thunkAPI) => {
  try {
    const resp = await customFetch.post(url, order);
    console.log(resp.data.data);
    return resp.data.data;
  } catch (error) {
    checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const cancelOrderThunk = async (url, order, thunkAPI) => {
  try {
    const resp = await customFetch.post(url, order);
    return resp.data;
  } catch (error) {
    checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const removeOrderThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.delete(url);
    return resp.data;
  } catch (error) {
    checkForUnauthorizedResponse(error, thunkAPI);
  }
};
