import React, { useState, useEffect, useRef } from "react";
import Header from "../shared/Header";
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../shared/Pagination";
import Loading from "../shared/Loading";
import SortButton from "../shared/SortButton";
import Export from "../shared/Export";
import DateFilter from "../shared/DateFilter";
import {
  getStoreId,
  setSalesOrder,
  setFixed,
} from "../../features/store/storeSlice";

import {
  getFactoryOrder,
  handleFilter,
  getOrderPaginate,
  handleSort,
  clearOrderValues,
  exportFactoryOrders,
} from "../../features/order/orderSlice";
import { formatDate } from "../../helpers/helpers";

export default function SalesOrdersList() {
  const [allRows, setAllRows] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStoreId());
  }, []);
  const { store_id, salesOrder } = useSelector((store) => store.store);
  const {
    orders,
    isLoading,
    links,
    meta,
    sort,
    orderby,
    status,
    factory_status,
    showAll,
    from,
    to,
  } = useSelector((store) => store.order);

  useEffect(() => {
    dispatch(clearOrderValues);
    dispatch(
      getFactoryOrder({
        id: store_id,
        from: from,
        to: to,
        sort: sort,
        orderby: orderby,
      })
    );
  }, [sort, orderby, status, factory_status, store_id, showAll, from, to]);

  useEffect(() => {
    if (orders) {
      console.log("orders", orders);
      let orderArray = [];
      orders.map((order) => {
        return orderArray.push({
          wear: formatDate(order.wear_date),
          id: order.id,
          date: formatDate(order.created_at),
          price: order.formatted_wholesale_price,
          poNum: order.purchase_order_number,
          salesOrderNum: order.sales_order_number,
          status: order.store_status,
        });
      });
      setAllRows(orderArray);
    }
  }, [orders, isLoading, links, meta]);

  const checkBoxRef = useRef("");
  useEffect(() => {
    dispatch(setSalesOrder(true));
    dispatch(setFixed(true));
  }, []);

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollPosition(window.pageYOffset);
    });
  }, [window.pageYOffset]);

  useEffect(() => {
    if (scrollPosition > 55) {
      dispatch(setFixed(false));
      dispatch(setSalesOrder(false));
    } else {
      dispatch(setFixed(true));
      dispatch(setSalesOrder(true));
    }
  }, [scrollPosition]);
  return (
    <Container className="p-0 scrollXOnTablet maxwidthSales maxWidthTab scrollX scrolY ">
      <div className={`${salesOrder === true && "mrgnTp"}`}>
        <div className="width-row-header">
          <Header title="Sales Orders" subtitle="Manage all Sales Orders" />
        </div>
        {isLoading && <Loading />}
        {!isLoading && (
          <div>
            <div className="mrgnLeft mrgnTopTrans">
              <DateFilter handleFilter={handleFilter} />
            </div>

            <div className="container ">
              <div className="row grid-header ps-2 width-order">
                <hr className="ms-4 hrSales" />
                <div className="container my-4 pe-4 d-flex  color-secondary mxwidthexp">
                  <div className="marginLeftEx ms-2">
                    <Export
                      num={1}
                      label="Export Sample Sales Orders"
                      action={exportFactoryOrders}
                    />
                  </div>
                  <div className="export-title">
                    <Export
                      num={2}
                      label="Export Repeated Sales Orders"
                      action={exportFactoryOrders}
                    />
                  </div>
                  <div className="padding-export-3">
                    <Export
                      num={""}
                      label="Export All Sales Orders"
                      action={exportFactoryOrders}
                    />
                  </div>
                </div>
                <div className="container pe-4">
                  <div className="row grid-header me-5 ps-3 fit-sales small-width width150">
                    <div className="col-lg-1 col-md-1 ps-0 col-sm-12 width-sales width-selectAll">
                      <Button className="bg-transparent grid-header no-border p-0">
                        Select All
                      </Button>
                    </div>

                    <SortButton
                      sort={sort}
                      label="Sales Order #"
                      value="sales_order_number"
                      click={() =>
                        dispatch(
                          handleSort({
                            sort: sort,
                            value: "sales_order_number",
                          })
                        )
                      }
                      rootClass="col-lg-2 col-md-2 col-sm-12 sales-text width-sales poStyle pe-0"
                    />

                    <SortButton
                      sort={sort}
                      label="PO #"
                      value="purchase_order_number"
                      click={() =>
                        dispatch(
                          handleSort({
                            sort: sort,
                            value: "purchase_order_number",
                          })
                        )
                      }
                      rootClass="col-lg-2 col-md-2 col-sm-12 ps-4 width-sales pe-0 padRgth"
                    />
                    <SortButton
                      sort={sort}
                      label="Wear date"
                      value="wear_date"
                      click={() =>
                        dispatch(handleSort({ sort: sort, value: "wear_date" }))
                      }
                      rootClass="col-lg-2 col-md-2 col-sm-12 ps-4 width-sales widthDate pe-0"
                    />

                    <SortButton
                      sort={sort}
                      label="Date"
                      value="created_at"
                      click={() =>
                        dispatch(
                          handleSort({ sort: sort, value: "created_at" })
                        )
                      }
                      rootClass="col-lg-1 col-md-1 col-sm-12 ps-3 width-sales dateSty pe-0 ps-lg-4"
                    />

                    <SortButton
                      sort={sort}
                      label="Amount Charged"
                      value="price"
                      click={() =>
                        dispatch(handleSort({ sort: sort, value: "price" }))
                      }
                      rootClass="col-lg-3 col-md-3 col-sm-12  ps-5 width-amountCha"
                    />

                    <SortButton
                      sort={sort}
                      label="Status"
                      value="store_status"
                      click={() =>
                        dispatch(
                          handleSort({ sort: sort, value: "store_status" })
                        )
                      }
                      rootClass="col-lg-1 col-md-1  col-sm-12 width-salesStat status-width pe-0 statusPad statusWidthMobileM ps-0"
                    />
                  </div>
                  {allRows.map((row) => {
                    const {
                      id,
                      salesOrderNum,
                      poNum,
                      date,
                      status,
                      price,
                      wear,
                    } = row;
                    return (
                      <div
                        key={id}
                        className="grid-row-border ps-3 me-2 py-3 fit-salesOrder"
                      >
                        <div className="row align-items-center fit-sale">
                          <div className="col-md-1 col-12 width-check">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexCheckDefault"
                                ref={checkBoxRef}
                              />
                            </div>
                          </div>
                          <Link
                            to={`/sales-order-summary/${id}`}
                            className="col-lg-2 col-md-2  grid-cell-invoice  grid-cell-body width-itemSales text-uppercase marginInMiniIpadToLeft"
                          >
                            {salesOrderNum ? salesOrderNum : "Pending"}
                          </Link>
                          <div className="col-lg-2 col-md-2  grid-cell-body width-itemSales ">
                            {poNum}
                          </div>
                          <div className="col-md-2  py-1 grid-cell-body width-itemSales">
                            {wear}
                          </div>
                          <div className="col-md-2  grid-cell-body width-itemSales marginDateLeft">
                            {date}
                          </div>
                          <div className="col-lg-1 col-md-1 col-sm-12  py-1 grid-cell-body width-itemSales marginAmountLeft ">
                            {price}
                          </div>
                          <div className="col-lg-1 col-md-1  py-1 grid-cell-body width-itemSales status-text  text-uppercase ps-4  statusMrgn ">
                            {status}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="width-row-sales">
                  {meta && (
                    <Pagination
                      active={meta.current_page}
                      size={parseInt(meta.last_page)}
                      step={2}
                      previous={links.prev}
                      next={links.next}
                      dispatch={dispatch}
                      handlePage={getOrderPaginate}
                      path={meta.path}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
}
