import React from 'react';
import styled from 'styled-components';
const SortButton = ({ label, sort, value, click, rootClass }) => {
  const className = `grid-chevron ${sort}`;
  return (
    <Wrapper className={rootClass} key={label} onClick={click}>
      {label}

      <svg
        className={className}
        xmlns='http://www.w3.org/2000/svg'
        width='11.9'
        height='7'
        viewBox='0 0 11.9 7'
      >
        <path
          id='chevron'
          d='M7.332,5.949a.66.66,0,0,1-.119.385l-3.5,5.25a.7.7,0,0,1-.581.315h-2.1a.7.7,0,0,1-.7-.7.66.66,0,0,1,.119-.385L3.692,5.949.451,1.084A.66.66,0,0,1,.332.7a.7.7,0,0,1,.7-.7h2.1a.7.7,0,0,1,.581.315l3.5,5.25a.66.66,0,0,1,.119.385Zm0,0'
          transform='translate(11.899 -0.332) rotate(90)'
          fill='#B8998D'
        />
      </svg>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  cursor: pointer;
  /* width:fit-content; */
`;
export default SortButton;
