import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";

export const getStoresThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.get(url);
    return resp.data.data;
  } catch (error) {
    checkForUnauthorizedResponse(error, thunkAPI);
  }
};
  export const updateStoreThunk = async (url, store, thunkAPI) => {
  try {
    const resp = await customFetch.post(url, store);
    return resp.data.data;
  } catch (error) {
    checkForUnauthorizedResponse(error, thunkAPI);
  }
};
export const removeStoreThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.delete(url);
    return resp.data;
  } catch (error) {
    checkForUnauthorizedResponse(error, thunkAPI);
  }
};
export const getStoreThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.get(url);
    return resp.data.data;
  } catch (error) {
    checkForUnauthorizedResponse(error, thunkAPI);
  }
};
export const getStoreOrdersThunk = async (url, thunkAPI) => {
  console.log("url", url);
  try {
    const resp = await customFetch.get(url);
    return resp.data.data;
  } catch (error) {
    checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const createStoreThunk = async (store, thunkAPI) => {
  try {
    const resp = await customFetch.post("/api/stores", store);
    return resp.data;
  } catch (error) {
    checkForUnauthorizedResponse(error, thunkAPI);
  }
};
