import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../shared/Header";
import PurchaseOrder from "./PurchaseOrder";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import OrderSummary from "./OrderSummary";
import ShippingAddress from "./ShippingAddress";
import { Button, Accordion } from "react-bootstrap";
import PaymentMethod from "./PaymentMethod";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getDress } from "../../../features/dress/dressSlice";
import { setFixed } from "../../../features/store/storeSlice";
import { useLocation } from "react-router-dom";
const Accordions = () => {
  const { fixed } = useSelector((store) => store.store);
  const { Id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location.state;
  useEffect(() => {
    dispatch(getDress({ id: Id }));
  }, []);
  useEffect(() => {
    console.log("ss", fixed);
    dispatch(setFixed(false));
  });
  return (
    <Container className="p-0 ">
      <div className="width-row-header">
        <Header
          title="Checkout"
          subtitle="Please add shipping address and payment Method"
        />
      </div>
      <div className="position-relative ">
        <div className="container my-4 absolute-pos">
          <Link to={-1} className="arrow">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19.7"
              height="9.542"
              viewBox="0 0 19.7 9.542"
            >
              <g
                id="Group_439"
                data-name="Group 439"
                transform="translate(-9.045 -14.126)"
              >
                <g id="right-arrow" transform="translate(9.045 14.126)">
                  <g
                    id="Group_257"
                    data-name="Group 257"
                    transform="translate(0 0)"
                  >
                    <path
                      id="Path_84"
                      data-name="Path 84"
                      d="M.226,136.226h0l4.021-4a.77.77,0,0,1,1.086,1.091L2.633,136h16.3a.77.77,0,1,1,0,1.539H2.633l2.7,2.687a.77.77,0,0,1-1.086,1.091l-4.021-4h0A.77.77,0,0,1,.226,136.226Z"
                      transform="translate(0 -132)"
                      fill="#b8998d"
                    />
                  </g>
                </g>
              </g>
            </svg>
            <span className=" back-to-list px-2">back to item details</span>
          </Link>
        </div>
        <div className="container my-4 absolute-pos content-box">
          <Accordion
            className="me-md-5 pe-md-4 styleWidth "
            defaultActiveKey="0"
          >
            <ShippingAddress
              CustomToggle={CustomToggle}
              ToggleNext={ToggleNext}
            />

            <PaymentMethod
              CustomToggle={CustomToggle}
              ToggleNext={ToggleNext}
            />

            <PurchaseOrder
              CustomToggle={CustomToggle}
              ToggleNext={ToggleNext}
            />

            <OrderSummary CustomToggle={CustomToggle} dress={state} />
          </Accordion>
        </div>
      </div>
    </Container>
  );
};
export default Accordions;

function CustomToggle({ title, subtitle, eventKey }) {
  const handleOnClick = useAccordionButton(eventKey, () => {});
  const number = parseInt(eventKey) + 1;
  return (
    <div onClick={handleOnClick} className="d-flex">
      <div className="card-number me-4">
        <p className="mt-1">{number}</p>
      </div>
      <div>
        <div className="accordion-title">{title}</div>
        <div className="accordion-subtitle">{subtitle}</div>
      </div>
    </div>
  );
}

function ToggleNext({ children, eventKey, action, disabled, className }) {
  return (
    <Button
      onClick={useAccordionButton(eventKey, action)}
      className={`btn-secondary px-5 btn-l my-3  ${className}`}
      type="submit"
      disabled={disabled}
    >
      {children}
    </Button>
  );
}
