import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";

export const getStatesThunk = async (url, thunkAPI) => {
  try {
    const resp = await customFetch.get(url);
    return resp.data.data;
  } catch (error) {
    checkForUnauthorizedResponse(error, thunkAPI);
  }
};
