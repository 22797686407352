import React from "react";
const Loading = ({ center }) => {
  return (
    <div className="container">
      <div className="snippet"></div>
      <div className="col-12">
        <div className="stage">
          <div className="dot-pulse"></div>
        </div>
      </div>
    </div>
  );
};
export default Loading;
