import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createOrder } from "../../../features/order/orderSlice";
const YesNo = ({
  title,
  show,
  setShow,
  navNo,
  data,
  dress,
  paymentM,
  store_id,
  SelectedAddressId,
  purchase_order_number,
  type,
  nav,
}) => {
  const [table, setTable] = useState([]);
  const [count, setCount] = useState(0);

  const arr = [];
  const submit = (pon, custom) => {
    setCount(count + 1);
    arr.push(custom.id);
    console.log(arr);
    setTable((oldArray) => [...oldArray, custom.id]);
    dispatch(
      createOrder({
        type: type === "Re-Order" ? 2 : 1,
        purchase_order_number: pon,
        item_type: 2,
        item_id: custom.id,
        size: dress.selected_size,
        color: null,
        address: SelectedAddressId,
        wear_date: dress.date,
        store: store_id,
        payment_method: paymentM,
        notes: dress.note,
      })
    );
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const handleYes = () => {
  //   navigate(nav);
  //   setShow(false);
  // };
  const handleNo = () => {
    setShow(false);
    navigate(navNo);
  };
  const no = (id) => {
    arr.push(id);
    setTable((oldArray) => [...oldArray, id]);
    setCount(count + 1);
  };
  useEffect(() => {
    if (count === data.length) {
      handleNo();
    }
  }, [count]);

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="yesNoModal"
      show={show}
      onHide={handleNo}
    >
      <Modal.Body className="yesNoModal-body">
        <div className="d-flex pb-3 ">
          <div className="ps-3">
            <div className="model-title ctitle">
              <b>{title}</b>
            </div>
            <br />
            {data &&
              data.map((custom) => {
                let pon = purchase_order_number + "-" + custom.name;
                return (
                  <div key={custom.id}>
                    <p>Name: {custom.name}</p>
                    <p>Price: {custom.formatted_wholesale_price}</p>
                    <div className="d-flex cflex">
                      <p>Would you like to order this item?</p>
                      {/* {!table.includes(custom.id)&& */}
                      <div>
                        <button
                          className="btn-secondary height-30 me-2 ms-4"
                          onClick={(e) => {
                            e.preventDefault();
                            submit(pon, custom);
                          }}
                          disabled={table.includes(custom.id)}
                        >
                          Yes
                        </button>
                        <button
                          className="btn-secondary height-30 ms-4 mt-2"
                          onClick={(e) => {
                            e.preventDefault();
                            no(custom.id);
                          }}
                          disabled={table.includes(custom.id)}
                        >
                          No
                        </button>
                      </div>
                      {/* } */}
                    </div>
                    <br />
                  </div>
                );
              })}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default YesNo;
