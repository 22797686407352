import React from "react";
import { Container, Form, Button } from "react-bootstrap";
import StaxInput from "../../../../shared/StaxInput";
import StaxDiv from "../../../../shared/StaxDiv";
import Loading from "../../../../shared/Loading";
import { appendScript } from "../../../../../helpers/helpers";
import DropDown from "../../../../shared/Dropdown";

class Credit extends React.Component {
  staxJs = null;
  state = {
    tokenizedPaymentMethod: null,
    completedTransaction: null,
    isPayButtonDisabled: true,
    formValues: {
      month: "",
      year: "",
      firstname: "",
      lastname: "",
      address_1: "",
      address_city: "",
      address_state: "",
      address_zip: "",
      address_country: "",
    },
    selected_country: "",
    selected_state: "",
    errorMessage: "",
    loading: false,
  };

  componentDidMount() {
    appendScript("https://staxjs.staxpayments.com/stax.js");
    // // the staxJs class is attached to `window` by our script
    // // so is the legacy "window.FattJs"
    const StaxJs = window.StaxJs;

    // tell staxJs to load in the card fields
    const staxJs = new StaxJs("JENNA-IN-WHITE-LLC-ff428ad3038c", {
      number: {
        id: "card-number",
        placeholder: "0000 0000 0000 0000",
        format: "prettyFormat",
        style: "width:100%",
      },
      cvv: {
        id: "card-cvv",
        placeholder: "0000",
        style: "width:100%",
      },
    });

    staxJs.showCardForm().then((handler) => {
      // for testing!
      handler.setTestPan("");
      handler.setTestCvv("");

      // this.setState({
      //   formValues: {
      //     month: "11",
      //     year: "2025",
      //     firstname: "Jon",
      //     lastname: "Doe",
      //     address_1: "123 Main St",
      //     address_city: "New York",
      //     address_state: "NY",
      //     address_zip: "10001",
      //     address_country: "ALB",
      //   },
      // });
    });

    staxJs.on("card_form_complete", (message) => {
      // activate pay button
      this.setState({ isPayButtonDisabled: false });
    });

    staxJs.on("card_form_incomplete", (message) => {
      // deactivate pay button
      this.setState({ isPayButtonDisabled: true });
    });

    this.staxJs = staxJs;
  }

  renderMessage = () => {
    const {
      completedTransaction,
      tokenizedPaymentMethod,
      errorMessage,
    } = this.state;

    if (errorMessage) {
      return <div className="failure">{errorMessage}</div>;
    }

    return (
      <div className="success">
        {completedTransaction
          ? `Successfully paid $1! The ID for this transaction is ${completedTransaction.id}`
          : ""}
        {tokenizedPaymentMethod
          ? `Successfully tokenized the card! The ID for the payment method is ${tokenizedPaymentMethod.id}`
          : ""}
      </div>
    );
  };

  handleFieldChange = (event) => {
    const { formValues } = this.state;
    const { name, value } = event.target;
    this.setState({ formValues: { ...formValues, [name]: value } });
  };

  handleDropdownStateFieldChange = (event) => {
    const { formValues } = this.state;
    this.setState({
      formValues: { ...formValues, address_state: event.value },
      selected_state: event,
    });
  };
  handleDropdownCountryFieldChange = (event) => {
    const { formValues } = this.state;
    this.setState({
      formValues: { ...formValues, address_country: event.value },
      selected_country: event,
    });
  };

  handleTokenize = () => {
    this.setState({
      tokenizedPaymentMethod: null,
      completedTransaction: null,
      isPayButtonDisabled: true,
      loading: true,
      errorMessage: "",
    });

    const { formValues } = this.state;
    const extraDetails = {
      ...formValues,
      total: 1,
      customer_id: this.props.stax_customer_id,
      url: "https://app.staxpayments.com/#/bill/",
      method: "card",
      validate: true,
    };

    this.staxJs
      .tokenize(extraDetails)
      .then((tokenizedPaymentMethod) => {
        this.setState({
          tokenizedPaymentMethod,
          isPayButtonDisabled: false,
          loading: false,
          errorMessage: "",
        });
        this.props.setModalOpen(false);
        window.location.reload();
      })
      .then(() => {
        if (this.props.beta) {
          console.log(this.props);
          // this.props.navigate("/list-of-retailers");
        }
      })
      .catch((e) => {
        this.setState({
          isPayButtonDisabled: false,
          loading: false,
          errorMessage: e.message,
        });
      });
  };

  // if email is supplied, a reciept will be sent to customer
  handlePay = () => {
    this.setState({
      tokenizedPaymentMethod: null,
      completedTransaction: null,
      isPayButtonDisabled: true,
      loading: true,
      errorMessage: "",
    });

    const { formValues } = this.state;
    const extraDetails = {
      ...formValues,
      total: 1,
      url: "https://app.staxpayments.com/#/bill/",
      method: "card",
      validate: true,
      // meta is optional and each field within the POJO is optional also
      meta: {
        reference: "invoice-reference-num", // optional - will show up in emailed receipts
        memo: "notes about this transaction", // optional - will show up in emailed receipts
        otherField1: "other-value-1", // optional - we don't care
        otherField2: "other-value-2", // optional - we don't care
        subtotal: 1, // optional - will show up in emailed receipts
        tax: 0, // optional - will show up in emailed receipts
        lineItems: [
          // optional - will show up in emailed receipts
          {
            id: this.props.dress.style_number,
            item: this.props.dress.name,
            details: "add dress this is a regular, demo item",
            quantity: 1,
            price: this.props.dress.formatted_wholesale_price,
          },
        ],
      },
    };

    this.staxJs
      .pay(extraDetails)
      .then((completedTransaction) => {
        this.setState({
          completedTransaction,
          isPayButtonDisabled: false,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          isPayButtonDisabled: false,
          loading: false,
          errorMessage: e.message,
        });
      });
  };
  render() {
    const {
      month,
      year,
      firstname,
      lastname,
      address_zip,
      address_city,
      address_1,
    } = this.state.formValues;
    const { selected_country, selected_state } = this.state;

    return (
      <Container className="py-4">
        <Form onSubmit={(e) => e.preventDefault()} className="row g-3">
          <div className="col-md-6">
            <StaxDiv id="card-number" labelText="CREDIT CARD NUMBER *" />
          </div>
          <div className="col-md-3">
            <div className="row">
              <div className="col-md-6">
                <StaxInput
                  name="month"
                  maxLength="2"
                  labelText=" EXPIRATION"
                  placeholder="MM"
                  onChange={this.handleFieldChange}
                  value={month || ""}
                />
              </div>
              <div className="col-md-6">
                <StaxInput
                  name="year"
                  maxLength="4"
                  placeholder="YYYY"
                  onChange={this.handleFieldChange}
                  value={year || ""}
                  labelText="DATE *"
                />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="row">
              <div className="col-md-6">
                <StaxDiv labelText="CVV *" id="card-cvv" />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <StaxInput
              name="firstname"
              labelText="First NAME ON CARD *"
              placeholder="Enter First Name On Card"
              onChange={this.handleFieldChange}
              value={firstname || ""}
            />
          </div>
          <div className="col-md-6">
            <StaxInput
              name="lastname"
              labelText="LAST NAME ON CARD *"
              placeholder="Enter Last Name On Card"
              onChange={this.handleFieldChange}
              value={lastname || ""}
            />
          </div>
          <div className="col-md-6">
            <StaxInput
              name="address_1"
              labelText="STREET *"
              placeholder="Enter Street Name"
              onChange={this.handleFieldChange}
              value={address_1 || ""}
            />
          </div>
          <div className="col-md-6">
            <StaxInput
              name="address_city"
              labelText="CITY *"
              placeholder="Enter City Name"
              onChange={this.handleFieldChange}
              value={address_city || ""}
            />
          </div>

          <div className="col-md-6">
            <DropDown
              name="address_state"
              labelText="STATE *"
              placeholder="Enter State Name"
              options={this.props.stateList}
              onChange={this.handleDropdownStateFieldChange}
              value={selected_state || ""}
            />
          </div>
          <div className="col-md-6">
            <DropDown
              name="address_country"
              labelText="COUNTRY *"
              placeholder="Enter State Name"
              options={this.props.countryList}
              onChange={this.handleDropdownCountryFieldChange}
              value={selected_country || ""}
            />
          </div>

          <div className="col-md-6">
            <StaxInput
              name="address_zip"
              labelText="ZIP CODE *"
              placeholder="Enter ZIP Code"
              onChange={this.handleFieldChange}
              value={address_zip || ""}
            />
          </div>

          <div className="d-flex flex-row-reverse mt-5">
            <Button
              onClick={() => {
                this.props.setRefreshPage(true);
                this.props.setRefreshPage(false);
                // , this.
                this.handleTokenize();
              }}
              disabled={this.state.isPayButtonDisabled}
              className="btn-secondary me-md-2"
              type="submit"
            >
              save card information
            </Button>
          </div>
          <div className="outcome">
            {this.renderMessage()}
            {this.loading && <Loading />}
          </div>
        </Form>
      </Container>
    );
  }
}

export default Credit;
