import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addStoreIdToLocalStorage,
  getStoreIdFromLocalStorage,
  addStaxIdStorage
} from "../../utils/localStorage";

import {
  getStoresThunk,
  getStoreOrdersThunk,
  createStoreThunk,
  getStoreThunk,
  removeStoreThunk,
  updateStoreThunk,
} from "./retailersThunk";
const initialFiltersState = {
  search: "",
  from: "",
  to: "",
  sort: "desc",
  orderby: "id",
  per_page: 10,
};

const initialState = {
  orderId: null,
  store_id: getStoreIdFromLocalStorage(),
  stax_customer_id: "",
  isLoading: false,
  stores: null,
  CurrentStore: null,
  CurrentStoreName: null,
  links: null,
  meta: null,
  status: null,
  endsession: false,
  cancelOrderId: null,
  factory_status: null,
  isStoreDeleted: false,
  ...initialFiltersState,
  temppass2: process.env.REACT_APP_KEY,
  showAll: true,
  deleteStoreId: "",
  privateaccess: true,
  storeName:'',
  storeId: '',
  store_stax_customer_id: '',
};

export const getStores = createAsyncThunk(
  "store/getStores",
  async (stores, thunkAPI) => {
    if (stores.search !== '') {
      return getStoresThunk(
        `/api/stores?search=${stores.search}&per_page=${stores.per_page}&order_by=${stores.orderby}&order_dir=${stores.sort}`,
        thunkAPI
      );
    }
    return getStoresThunk(
      `/api/stores?per_page=${stores.per_page}&order_by=${stores.orderby}&order_dir=${stores.sort}`,
      thunkAPI
    );
  }
);
export const getStore = createAsyncThunk(
  "store/getStore",
  async (id, thunkAPI) => {
    return getStoreThunk(`/api/stores/${id}`, thunkAPI);
  }
);

export const getRetailersPaginate = createAsyncThunk(
  "store/getRetailersPaginate",
  async (link, thunkAPI) => {
    return getStoresThunk(link, thunkAPI);
  }
);

export const getStoreOrders = createAsyncThunk(
  "store/getStoreOrders",
  async (order, thunkAPI) => {
    return getStoreOrdersThunk(
      `api/stores/${order.id}/orders?packaged=0&status[]=3`,
      thunkAPI
    );
  }
);
export const updateStore = createAsyncThunk(
  "store/updateStore",
  async ({ store, storeId }, thunkAPI) => {
    return updateStoreThunk(`/api/stores/${storeId}`, store, thunkAPI);
  }
);

export const getStoreOrdersPaginate = createAsyncThunk(
  "stores/getStoreOrdersPaginate",
  async (link, thunkAPI) => {
    return getStoreOrdersThunk(link, thunkAPI);
  }
);
export const createStore = createAsyncThunk(
  "store/createStore",
  createStoreThunk
);
export const removeStore = createAsyncThunk(
  "store/rremoveStore",
  async (store, thunkAPI) => {
    return removeStoreThunk("/api/stores/" + store, thunkAPI);
  }
);

const RetailersSlice = createSlice({
  name: "stores",
  initialState,
  reducers: {
    handlePassword: (state, { payload }) => {
      if (payload.pass === "temppass2") {
        state.privateaccess = payload.type;
      }
    },
    handleDelete: (state, { payload }) => {
      state.deleteStoreId = payload;
      if (state.privateaccess !== true) {
        state.privateaccess = "delete";
      }
    },
    handleSort: (state, { payload }) => {
      state.orderby = payload.value;
      if (payload.sort === "desc") {
        state.sort = "asc";
      }
      if (payload.sort === "asc") {
        state.sort = "desc";
      }
    },
    setStoreName : (state, { payload }) => {
      state.storeName = payload;
    },
    handleCancel: (state, { payload }) => {
      state.cancelOrderId = payload;
    },
    handleFilter: (state, { payload }) => {
      if (payload.status) {
        state.status = payload.status;
      }
      if (payload.from) {
        state.from = payload.from;
      }
      if (payload.to) {
        state.to = payload.to;
      }
      if (payload.per_page) {
        state.per_page = payload.per_page;
        state.orders = null;
      }
    },
    setStoreId: (state, { payload }) => {
      state.store_id = payload;
      addStoreIdToLocalStorage(payload);
    },

    handleSearch: (state, { payload }) => {
      state.search = payload.search;
    },

    clearOrderValues: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: {
    [createStore.pending]: (state) => {
      state.isLoading = true;
    },
    [createStore.fulfilled]: (state, { payload }) => {
      console.log(payload);
      state.isLoading = false;
      state.showAll = !state.showAll;
      state.storeId = payload.data.id;
      state.store_stax_customer_id= payload.data.stax_customer_id;
      addStaxIdStorage(payload.data.stax_customer_id);
    },
    [createStore.rejected]: (state, data) => {
      state.isLoading = false;
    },
    [removeStore.pending]: (state) => {
      state.isLoading = true;
      state.isStoreDeleted = false;
    },
    [removeStore.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isStoreDeleted = true;
    },
    [removeStore.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.showAll = !state.showAll;
    },
    [getStores.pending]: (state) => {
      state.isLoading = true;
    },
    [getStores.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.stores = payload.stores;
      state.meta = payload.meta;
      state.links = payload.links;
    },
    [getStores.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },
    [getStore.pending]: (state) => {
      state.isLoading = true;
    },
    [getStore.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.CurrentStore = payload;
      state.CurrentStoreName = payload.name;
      state.stax_customer_id = payload.stax_customer_id;
    },
    [getStore.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },
    [getStoreOrders.pending]: (state) => {
      state.isLoading = true;
    },
    [getStoreOrders.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.stores = payload.stores;
    },
    [getStoreOrders.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },

    [getRetailersPaginate.pending]: (state) => {
      state.isLoading = true;
    },
    [getRetailersPaginate.fulfilled]: (state, { payload }) => {
      const { links, meta } = payload;
      state.isLoading = false;
      state.links = links;
      state.meta = meta;
    },
    [getRetailersPaginate.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },
    [getStoreOrdersPaginate.pending]: (state) => {
      state.isLoading = true;
    },
    [getStoreOrdersPaginate.fulfilled]: (state, { payload }) => {
      const { links, meta } = payload;
      state.isLoading = false;
      state.links = links;
      state.meta = meta;
    },
    [getStoreOrdersPaginate.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },
  },
});

export const {
  handleSort,
  clearOrderValues,
  handleCancel,
  handleFilter,
  handleViewImage,
  handleSearch,
  setStoreId,
  handleDelete,
  handlePassword,
  setStoreName
} = RetailersSlice.actions;
export default RetailersSlice.reducer;
