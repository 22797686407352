import axios from "axios";
import {  logoutUser } from "../features/user/userSlice";
import {
  getUserFromLocalStorage,
  getTokenFromLocalStorage,
} from "./localStorage";

const customFetch = axios.create({
  baseURL: "https://wholesale.jiwcalendar.com",
});

customFetch.interceptors.request.use((config) => {
  const token = getTokenFromLocalStorage();

  if (token) {
    config.headers.common["Authorization"] = `Bearer ${token}`;
    //config.headers.common['Authorization'] = accessToken;
  }
  return config;
});

const api_key =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJtZXJjaGFudCI6ImE5ZTYzOTkxLWI4NDEtNGZjZC05MTIxLTU0MjYxYjM0OGQ1ZiIsImdvZFVzZXIiOmZhbHNlLCJhc3N1bWluZyI6ZmFsc2UsImJyYW5kIjoiZmF0dG1lcmNoYW50LXNhbmRib3giLCJzdWIiOiI3MGNjOWY2ZS1hMGFlLTRjYWItYTE5NS1hNzVhMmJlZDA2MDciLCJpc3MiOiJodHRwOi8vYXBpcHJvZC5mYXR0bGFicy5jb20vc2FuZGJveCIsImlhdCI6MTY1MDg5NDEyNiwiZXhwIjo0ODA0NDk0MTI2LCJuYmYiOjE2NTA4OTQxMjYsImp0aSI6IjYxMkdaOTBxYlZ0amFpZnQifQ.9uNydqurhEC--nMbbZd8meEZng2qQGw0TS2X8QiqiFA";

export const staxFetch = axios.create({
  baseURL: "https://apiprod.fattlabs.com/",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${api_key}`,
  },
});

export const checkForUnauthorizedResponse = (error, thunkAPI) => {
  if (error.response.status === 401 || error.response.status === 403) {
    thunkAPI.dispatch(logoutUser());
    return thunkAPI.rejectWithValue("Unauthorized! Logging Out...");
  }
  return thunkAPI.rejectWithValue(error);
};

export default customFetch;
