import React, { useEffect, useState } from "react";
import { Button, Accordion, Card, Form } from "react-bootstrap";
import copy from "../../../assets/img/copy.svg";
import plus from "../../../assets/img/brown-plus.svg";
import close from "../../../assets/img/close.svg";
import { useDispatch, useSelector } from "react-redux";
import { formatPhoneNumber } from "../../../helpers/helpers";
import { setFixed } from "../../../features/store/storeSlice";

import {
  getStoreAddresses,
  removeAddress,
  handleDelete,
  getAddress,
  handleSelectedAddress,
} from "../../../features/address/addressSlice";
import { getStoreId } from "../../../features/store/storeSlice";
import error from "../../../assets/img/error.svg";
import YesNo from "../../shared/modals/YesNo";
import Loading from "../../shared/Loading";
import EditAddressModal from "./modals/EditAddressModal";
import AddressModal from "./modals/addNewAddressModal";

function ShippingAddress({ CustomToggle, ToggleNext }) {
  const dispatch = useDispatch();
  const [allRows, setAllRows] = useState([]);
  const [deletModal, setDeletModal] = useState(false);
  const [deleteId, setDeleteID] = useState();
  const [EditModal, setEditModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [addressChosen, setAddressChosen] = useState(false);

  const {
    addresses,
    isLoading,
    deletedAddressId,
    isAddressDeleted,
    showAll,
  } = useSelector((store) => store.address);
  const { store_id } = useSelector((store) => store.store);

  useEffect(() => {
    dispatch(getStoreId());
  }, []);

  useEffect(() => {
    setDeleteID(deletedAddressId);
  }, [deletedAddressId]);

  useEffect(() => {
    if (store_id) {
      dispatch(getStoreAddresses({ id: store_id }));
    }
  }, [isAddressDeleted, store_id, showAll]);

  useEffect(() => {
    if (addresses) {
      let addressesArray = [];
      addresses.map((item, count) => {
        let phoneFormat = formatPhoneNumber(item.phone);
        if (count === 0) {
          dispatch(handleSelectedAddress(item.id));
        }
        return addressesArray.push({
          count: count,
          Id: item.id,
          street: item.street,
          addresState: item.state ? item.state[Object.keys(item.state)[0]] : "",
          city: item.city,
          zip: item.zip,
          country: item.country
            ? item.country[Object.keys(item.country)[0]]
            : "",

          full_name: item.full_name,
          phone: phoneFormat,
        });
      });
      setAllRows(addressesArray);
    }
  }, [addresses, showAll]);

  useEffect(() => {
    if (addresses) {
      if (addresses.length !== 0) {
        setAddressChosen(true);
      } else {
        setAddressChosen(false);
      }
    }
  }, [addresses]);
  useEffect(() => {
    dispatch(setFixed(false));
  });
  return (
    <Card className="card mb-2 pe-5 cardAddresWidth scrollX">
      <Card.Header className="bg-white ">
        <CustomToggle
          eventKey="0"
          title="Shipping Address"
          subtitle="Select or add preferred shipping address"
        ></CustomToggle>
      </Card.Header>
      <Accordion.Collapse eventKey="0">
        <>
          {isLoading && <Loading />}
          {!isLoading && (
            <Card.Body className="d-flex row ms-5 styleWidthAddress">
              {deletModal && (
                <YesNo
                  show={deletModal}
                  setShow={setDeletModal}
                  title="Delete this address"
                  msg="Are you sure you want to delete this address?"
                  // color='red'
                  icon={error}
                  child={false}
                  handleAction={removeAddress}
                  currentId={deleteId}
                />
              )}
              {modal && <AddressModal setModal={setModal} />}

              <div className="row">
                <div className="col-lg-11 col-sm-12 col-xs-12 ">
                  <hr />
                  <div className="row ">
                    {allRows.map((address) => {
                      const {
                        Id,
                        full_name,
                        street,
                        addresState,
                        city,
                        zip,
                        phone,
                        count,
                      } = address;
                      return (
                        <div
                          key={Id}
                          className="col-lg-3 col-md-5 col-12 m-2 address-container pb-4 "
                        >
                          <div className="d-flex justify-content-between pt-2 ">
                            <div className="form-check">
                              <Form.Check
                                type="radio"
                                name="address"
                                defaultChecked={count === 0 ? true : false}
                                value={Id}
                                aria-label={`radio ${Id}`}
                                onChange={(e) => {
                                  dispatch(
                                    handleSelectedAddress(e.target.value)
                                  );
                                }}
                              />
                            </div>
                            <div>
                              <Button
                                className="bg-transparent no-border p-0 "
                                onClick={() => {
                                  setEditModal(true);
                                  dispatch(getAddress({ id: Id }));
                                }}
                              >
                                <img src={copy} alt="" />
                              </Button>
                              {EditModal && (
                                <EditAddressModal setModal={setEditModal} />
                              )}
                              <Button
                                onClick={() => {
                                  setDeletModal(true);
                                  dispatch(handleDelete(parseInt(Id)));
                                }}
                                className="bg-transparent no-border p-0 ms-2"
                              >
                                <img src={close} alt="" />
                              </Button>
                            </div>
                          </div>
                          <div className="column-text my-2">{full_name}</div>
                          <address className="card-info">
                            {street}, {city}, {addresState} {zip}
                            <address>{phone}</address>
                          </address>
                        </div>
                      );
                    })}
                    <div className="col-lg-3  col-md-5 col-12 m-2 color-secondary d-flex align-items-center address-container gx-lg-0">
                      <Button
                        type="button"
                        onClick={() => {
                          setModal(true);
                        }}
                        className="bg-transparent no-border  btn-add-address"
                      >
                        <img
                          className="margin-right-2 pe-2"
                          src={plus}
                          alt=""
                        />
                        Add new address
                      </Button>
                    </div>
                  </div>
                  <div className="justify-content-between align-item-end d-flex">
                    <div></div>
                    <div className="me-0 ">
                      <ToggleNext
                        eventKey="1"
                        disabled={!addressChosen}
                        className="row-styleWidBtn"
                      >
                        Next
                      </ToggleNext>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          )}
        </>
      </Accordion.Collapse>
    </Card>
  );
}

export default ShippingAddress;
