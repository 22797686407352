import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getCountriesThunk } from './countryThunk';

const initialState = {
  isLoading: false,
  isSidebarOpen: false,
  countries: [],
  selectedCountries: [],
  links: null,
  link: null,
  meta: null,
  sort: 'desc',
  orderby: 'id',
  factory_code: 1,
  endsession: false,
};

export const getCountries = createAsyncThunk(
  'country/getCountries',
  async (country, thunkAPI) => {
    return getCountriesThunk(`/api/countries`, thunkAPI);
  }
);

export const getCountryPaginate = createAsyncThunk(
  'country/getCountryPaginate',
  async (link, thunkAPI) => {
    return getCountriesThunk(link, thunkAPI);
  }
);

const countrySlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    handleSelectedCountries: (state, payload) => {
      state.selectedCountries = payload.payload;
    },
    clearCountriesValues: (state, payload) => {
      return {
        ...initialState,
      };
    },

    handleChange: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    handleSort: (state, { payload }) => {
      state.orderby = payload.value;
      if (payload.sort === 'desc') {
        state.sort = 'asc';
      }
      if (payload.sort === 'asc') {
        state.sort = 'desc';
      }
    },
  },
  extraReducers: {
    [getCountries.pending]: (state) => {
      state.isLoading = true;
    },
    [getCountries.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.countries = payload;
    },
    [getCountries.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },

    [getCountryPaginate.pending]: (state) => {
      state.isLoading = true;
    },
    [getCountryPaginate.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.countries = payload.countries;
    },
    [getCountryPaginate.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.endsession = true;
    },
  },
});

export const {
  handleSelectedCountries,
  clearCountriesValues,
  handleSearch,
  handleSort,
  handleChange,
} = countrySlice.actions;
export default countrySlice.reducer;
