import React from "react";
import { Form } from "react-bootstrap";

const DatepickerComponent = ({
  name,
  propsClass,
  placeholder,
  selectedValue,
  handleChange,
  label,
  labelClass,
  onBlur,
  id,
  max,
}) => {
  return (
    <>
      <Form.Label className={labelClass}>{label}</Form.Label>
      <Form.Control
        id={id}
        type="date"
        className={propsClass}
        name={name}
        onChange={handleChange}
        onBlur={onBlur}
        required
        value={selectedValue}
        placeholder={placeholder}
        max={max}
      />
    </>
  );
};
export default DatepickerComponent;
